@use '@angular/material' as mat;
@mixin mc-split-dialogs-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mc-vertical-split-dialog {
    color: mat.get-color-from-palette($foreground, vertical-split-dialog);

    .mat-dialog-title {
      background-color: mat.get-color-from-palette($background, background-invert);
    }
  }
}
