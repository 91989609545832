.text-light-weight {
  font-weight: $font-weight-light;
}

.text-medium-weight {
  font-weight: $font-weight-medium;
}

.text-heavy-weight {
  font-weight: $font-weight-heavy;
}

.text-xx-small {
  font-size: $font-size-xx-small;
}

.text-x-small {
  font-size: $font-size-x-small;
}

.text-small {
  font-size: $font-size-small;
}

.text-normal {
  font-size: $font-size-normal;
}

.text-large {
  font-size: $font-size-large;
}

.text-x-large {
  font-size: $font-size-x-large;
}

.text-xx-large {
  font-size: $font-size-xx-large;
}

.text-xxx-large {
  font-size: $font-size-xxx-large;
}

.text-xxxx-large {
  font-size: $font-size-xxxx-large;
}

.mc-link {
  cursor: pointer;
}

a,
.mc-link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.mc-plain-link,
.mc-plain-link:link,
.mc-plain-link:visited,
.mc-plain-link:hover,
.mc-plain-link:active {
  color: inherit;
  text-decoration: none;
}

.mc-truncate {
  @include mc-truncate;
}

.mc-break-text {
  overflow-wrap: break-word;
}

.mc-text-center {
  text-align: center;
}

.mc-break-text-all {
  word-break: break-all;
}