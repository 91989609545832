.button-dropdown,
.mc-icon-button-dropdown {
  .dropdown-arrow {
    margin-left: $form-control-spacing;

    // Taken from .mat-expansion-indicator
    &:after {
      content: '';
      border-style: solid;
      border-width: $border-width-none $border-width-thick $border-width-thick $border-width-none;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      vertical-align: middle;
      // Additional styles on top of .mat-expansion-indicator
      position: relative;
      bottom: $border-width-thick; // Offset the border width
      transform-origin: top center;
    }
  }
}

.mc-icon-button-dropdown {
  // Improve the alignment of the dropdown arrow icon with the button's icon
  .dropdown-arrow {
    display: inline-flex;
    align-items: center;
  }
}

/*
 * Link Buttons
 */
.mc-link-button {
  border: $border-none;
  padding: $spacing-none $button-link-horizontal-spacing;
  outline: none;
  background: inherit;
}

.mc-link-button,
.mat-button.mat-link {
  &:not(:disabled) {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

/*
 * Inline Buttons
 */
.mc-inline-button {
  display: inline;
  border: $border-none;
  padding: $spacing-none;
}

/*
 * Wrapping Buttons
 */
.mc-wrapping-button,
.mc-wrapping-button.mat-button-base {
  min-height: $mat-button-line-height-px;
  padding-bottom: $button-vertical-spacing;
  padding-top: $button-vertical-spacing;
  line-height: $line-height-normal;
  white-space: normal;
  overflow-wrap: break-word;
}

/*
 * Button Sizes
 */
.mc-material {
  .mc-button-small {
    @include mc-icon-button-size($font-size-small, $icon-button-size-small);
    @include mc-button-size($font-size-small, $button-size-small);
    @include mc-mini-fab-size($font-size-small, $icon-button-size-small);
    @include mc-stroked-button-size($font-size-small, $button-size-small);
    @include mc-flat-button-size($font-size-small, $button-size-small);
  }

  .mc-button-normal {
    @include mc-button-size($font-size-normal, $button-size-normal);
    @include mc-stroked-button-size($font-size-normal, $button-size-normal);
    @include mc-flat-button-size($font-size-normal, $button-size-normal);
  }

  .mc-button-large {
    @include mc-icon-button-size($font-size-x-large, $icon-button-size-large);
  }
}

/*
 * mc-expansion-button
 */
.mc-expansion-button-indicator.mat-icon {
  transition: transform $mc-expansion-indicator-animation-timing;
}

.mc-expansion-button-expanded {
  .mc-expansion-button-indicator.mat-icon {
    transform-origin: center;
    transform: rotateZ(180deg);
  }
}
