$icon-button-size-x-small: 2rem;
$icon-button-size-small: 3rem;
$icon-button-size-normal: 4rem;
$icon-button-size-large: 5rem;

$button-size-small: 3rem;
$button-size-normal: 3rem;

$button-horizontal-spacing: $spacing-small;
$icon-button-horizontal-spacing: $spacing-x-small;
$button-between-spacing: $spacing-xx-small;

$button-link-horizontal-spacing: .7rem;

// For buttons with icon and text
$button-icon-text-spacing: .8rem;
$button-icon-width: 1.6rem;

$button-vertical-spacing: (($mat-button-line-height-px - ($font-size-large-px * $line-height-normal)) * 0.5);
