@use '@angular/material' as mat;
@mixin mc-grids-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // Adds striped rows to the grid
  .mc-grid-striped {
    .mc-grid-row-striped {
      background-color: mat.get-color-from-palette($background, grid-stripe);
    }
  }

  .mc-grid-help {
    background: mat.get-color-from-palette($background, component);
  }
}
