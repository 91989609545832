@use '@angular/material' as mat;
@mixin mc-markdown-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mc-markdown {
    // Do not override the bg color given by highlight.js
    code:not(.hljs) {
      background-color: mat.get-color-from-palette($background, app-bar);
    }

    pre > code {
      border-color: mat.get-color-from-palette($background, list-item-selected-light);
    }

    td,
    th {
      border-color: mat.get-color-from-palette($foreground, divider);
    }

    blockquote {
      background-color: mat.get-color-from-palette($background, app-bar);
      border-left-color: mat.get-color-from-palette($foreground, divider);
    }
  }
}
