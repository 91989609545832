.mc-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z-index(loader); // Gets it above most things by default
}

.mc-loader-flex-child {
  position: static;
}
