.mc-material {
  /*
   * Better size and alignment when next to other form field elements
   */
  .mat-select {
    line-height: $input-line-height-normal;
    margin-top: $mat-input-element-margin-top;
  }

  /*
   * Better icon alignment with the flexbox
   */
  .mat-select-panel {
    .mat-option-text {
      display: flex;
      align-items: center;
    }

    .mat-option .mat-icon {
      display: flex;
      align-items: center;
      width: auto;
    }
  }

  /*
   * Keep the arrow in the same place when there is a selection or not
   */
  .mat-form-field-appearance-standard.mat-form-field-has-label .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper  {
    transform: translateY(0);
  }
}
