@use '@angular/material' as mat;
@mixin mc-mat-lists-theme ($theme, $typography) {
  @include _mc-mat-list($theme, 'primary');
}

@mixin _mc-mat-list ($theme, $palette-name) {
  $foreground: map-get($theme, foreground);
  $palette: map-get($theme, $palette-name);

  .mat-list-base {
    .mat-list-item-disabled {
      color: mat.get-color-from-palette($foreground, disabled-text);
      background-color: transparent;

      .mat-pseudo-checkbox {
        color: mat.get-color-from-palette($foreground, disabled-text);
      }
    }
  }
}
