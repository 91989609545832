.mc-material {
  .navbar {
    // Use a combination of navbar padding and button padding to make the spacing equal to $navbar-heading-spacing by splitting the space between the navbar and the button at the far right of the navbar.
    // This ensures the button has space between itself and the edge of the page when hovering over it (since hovering over it adds a bg color)
    $padding-right-navbar: $navbar-heading-spacing * 0.5;
    $padding-navbar-button: $navbar-button-spacing;

    &.mat-toolbar,
    .mat-toolbar {
      height: $navbar-height;
    }

    &.mat-toolbar {
      border: $border-none;
      border-radius: $border-radius-none;
      padding-left: $spacing-none;
      padding-right: $padding-right-navbar;
    }

    .mat-toolbar {
      padding-right: $spacing-none;
    }

    .navbar-heading-logo {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: ($navbar-heading-spacing - $navbar-tab-spacing);

      .madcap-central-link-logo {
        width: 147px;
      }

      .madcap-madmin-link-logo {
        width: 104px;
      }
    }

    .navbar-heading {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      height: 100%;
      width: $navbar-heading-width;

      .mc-navbar-drawer-toggle {
        width: 100%;
        height: 100%;
        padding: $spacing-none $navbar-heading-spacing;
        text-align: left;

        .mat-button-wrapper {
          display: flex;
          align-items: center;
          // line-height: $line-height-normal;

          // For centering the navbar title text with the navbar title icon
          > * {
            display: flex;
            align-items: center;
            min-width: 0;
          }

          .mc-navbar-title-icon.icon-close {
            display: none;
          }
        }
      }
    }

    .button-dropdown {
      margin-left: $navbar-item-spacing;
      margin-right: $navbar-item-spacing;
    }

    .navbar-item.mat-button {
      min-width: 0;
      padding-left: $padding-navbar-button;
      padding-right: $padding-navbar-button;
    }

    .mc-navbar-title-icon.mat-icon {
      @extend .mc-image-icon;
      flex-shrink: 0;
      justify-content: center;
      margin-right: $navbar-heading-spacing;
      // font-size: $list-item-icon-font-size;
    }
  }

  .mat-drawer-opened .navbar-heading {
    .mc-navbar-title-icon.icon-burger {
      display: none;
    }

    .mc-navbar-drawer-toggle .mat-button-wrapper .mc-navbar-title-icon.icon-close {
      display: flex;
    }
  }

  .navbar-title {
    display: inline-block;
    width: $navbar-heading-title-width;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mc-navbar-menu.mat-menu-panel {
    border-top-left-radius: $border-radius-none;
    border-top-right-radius: $border-radius-none;
  }

  .mc-navbar-button-dropdown-menu.mat-menu-panel {
    margin-top: $navbar-button-dropdown-menu-offset;
  }

  .mc-navbar-popup > .mc-popup {
    border-top-left-radius: $border-radius-none;
    border-top-right-radius: $border-radius-none;
  }

  .mc-navbar-button-dropdown-popup > .mc-popup {
    margin-top: $navbar-button-dropdown-menu-offset;
  }
}
