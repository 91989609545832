@use '@angular/material' as mat;

@mixin mc-form-fields-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  /**
   * .mc-form-field .mat-selection-list
   * Styles a mat-selection-list inside of an mc-form-field including styles for mat-error
   */
  .mc-form-field {

    .mat-selection-list+.mat-error,
    .mc-sticky-mat-error,
    .mc-absolute-mat-error {
      // Default to the card background color as this is the most common use case
      background-color: mat.get-color-from-palette($background, card);
    }
  }

  /**
   * .mc-form-field-type-button
   * Styles a mat-form-field that uses a button for its control (eg mc-file-picker)
   */
  .mc-form-field-type-button {
    @include _mc-form-field-type-button-palette-theme($theme, 'primary');
    @include _mc-form-field-type-button-palette-theme($theme, 'accent');
    @include _mc-form-field-type-button-palette-theme($theme, 'warn');
  }

  /**
   * .mat-form-field-disabled mc-dropdown-arrow
   * Styles an mc-dropdown-arrow inside a disabled mat-form-field
   */
  .mat-form-field-disabled mc-dropdown-arrow {
    .mat-select-arrow {
      color: mat.get-color-from-palette($foreground, disabled-text);
    }
  }
}

@mixin _mc-form-field-type-button-palette-theme ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  &.mat-#{$palette-name} {
    @include _mc-form-field-type-button-theme($palette, default-contrast);
  }

  &.mat-#{$palette-name}-muted {
    @include _mc-form-field-type-button-theme($palette, lighter-contrast);
  }

  &.mat-#{$palette-name}-vivid {
    @include _mc-form-field-type-button-theme($palette, darker-contrast);
  }
}

@mixin _mc-form-field-type-button-theme ($palette, $contrast-name) {
  &.mat-form-field-appearance-legacy {
    .mc-form-control-empty+.mat-form-field-label-wrapper {

      .mat-form-field-label,
      .mat-form-field-required-marker {
        color: mat.get-color-from-palette($palette, $contrast-name);
      }
    }
  }

  &.mat-form-field-invalid {
    .mc-form-control-empty+.mat-form-field-label-wrapper {

      .mat-form-field-label,
      .mat-form-field-required-marker {
        color: mat.get-color-from-palette($palette, $contrast-name);
      }
    }
  }
}