@use '@angular/material' as mat;
@mixin mc-page-cards-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);

  .page-card,
  .page-card-column {
    background: mat.get-color-from-palette($background, page-card);
  }
}
