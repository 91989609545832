@use '@angular/material' as mat;
@mixin mc-buttons-theme ($theme, $typography) {
  $link: map-get($theme, link);
  $white: map-get($theme, secondary-invert);

  .mc-link-button:not(:disabled) {
    color: mat.get-color-from-palette($link);
  }

  .mc-inline-button:not(.mc-inline-button-disabled) {
    @include _mc-inline-button($theme, 'link');
  }

  .mc-inline-button.mc-inline-button-disabled {
    color: inherit;
  }

  .mc-icon-link-button-highlight-focus.mc-link-button:not(:disabled):focus {
    background: mat.get-color-from-palette($link);
    color: mat.get-color-from-palette($link, default-contrast);
    border-radius: 50%;
  }
}

@mixin _mc-inline-button($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  &.mat-#{$palette-name} {
    color: mat.get-color-from-palette($palette, default);
  }

  &.mat-#{$palette-name}-muted {
    color: mat.get-color-from-palette($palette, lighter);
  }

  &.mat-#{$palette-name}-vivid {
    color: mat.get-color-from-palette($palette, darker);
  }
}
