.mc-material {
  /*
   * Header
   */
  .mat-table {
    .mat-sort-header-content {
      text-align: left; // Make all header titles align left. This makes multiline headers align with the content in the cells
    }
  }

  /*
   * Cell Padding
   */
  .mat-table {
    .mat-cell,
    .mat-header-cell {
      padding-left: $grid-cell-horizontal-padding;
    }

    .mat-cell,
    .mat-footer-cell,
    .mat-header-cell {
      &:first-child {
        padding-left: $grid-item-horizontal-spacing;
      }

      &:last-child {
        padding-right: $grid-item-horizontal-spacing;
      }
    }
  }

  /*
   * Cell Content
   */
  .mat-table {
    .mat-row:not(.expanded-detail-row) {
      .mat-cell {
        > .mc-truncate {
          display: block;
        }

        > .mat-button.mc-truncate {
          @include mc-truncate; // Re-apply mc-truncate because mat-button overrides some of the styles
          max-width: 100%;
        }

        .mat-button {
          min-width: 0; // Let the button be its natural width
          margin-left: -$grid-cell-horizontal-padding; // Shift the button to the left so that the text lines up with the column header's text
          // Make the padding be the same as the cell's padding so that shifting the button above lines up the text
          padding-left: $grid-cell-horizontal-padding;
          padding-right: $grid-cell-horizontal-padding;
        }
      }
    }
  }
}
