.mc-material {
  .mat-button,
  .mat-raised-button {
    padding-left: $button-horizontal-spacing;
    padding-right: $button-horizontal-spacing;
  }

  .mat-stroked-button {
    padding-left: calc(#{$button-horizontal-spacing} - 1px);
    padding-right: calc(#{$button-horizontal-spacing} - 1px);
  }

  .mat-button,
  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button {
    .mat-icon {
      display: inline-flex; // For centering the icon
      height: auto; // For centering the icon
      width: $button-icon-width;
      margin-right: $button-icon-text-spacing;
    }
  }

  /**
   * Improves the centering of icons in mat-icon-button
   * This is a workaround for this issue:
   * https://github.com/angular/components/issues/8842#issuecomment-595915442
   */
  .mat-icon-button {
    // Center the content of the button element. This is required because not all mat-icon-buttons are a <button>. Some are links and do not automatically center content.
    display: inline-flex;
    align-items: center;
    justify-content: center;

    // Center the content of the icon element. This is required to center the font-icon glyph.
    .mat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // Making the mat-icon use flexbox causes text decorations to show on anchors. This is required to remove them.
    &:focus,
    &:hover,
    &:visited {
      text-decoration: none;
    }
  }
}
