@use '@angular/material' as mat;

@mixin mc-gradients-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $brand: map-get($theme, brand);
  $primary: map-get($theme, primary);

  .mc-brand-gradient {
    background-image: linear-gradient(to right, mat.get-color-from-palette($brand, 900), mat.get-color-from-palette($primary, 900));
  }

  .mc-component-gradient {
    background-image: linear-gradient(rgba(255, 255, 255, 0%) -.9rem, mat.get-color-from-palette($background, component));
  }
}
