.mc-tooltip-snug {
  &.mat-tooltip {
    margin-top: $spacing-none;
    margin-bottom: $spacing-none;
  }
}

.mc-tooltip-multiline {
  &.mat-tooltip {
    white-space: pre-line;
    /* FF & Edge */
    word-break: break-all;
    /* Chrome */
    word-break: break-word;
  }
}

.mc-tooltip-centered {
  &.mat-tooltip {
    text-align: center;
  }
}

.mc-tooltip-border {
  &.mat-tooltip {
    border: solid $border-width-thick;
  }
}

.mc-tooltip-full-width {
  &.mat-tooltip {
    max-width: 100%;
  }
}