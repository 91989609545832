/**
 * .mc-menu CSS component
 * Adds support for forms, expansion panels, headers, and close buttons to mat-menu
 * Use in conjunction with the mcStopPropagation="click" directive on the mat-menu's children to prevent the menu from closing from clicks inside it
 */
.mc-menu {
  // Keeps some space between the menu and the viewport. Primarily for menus that change height while open like menus with expansion panels.
  &.mat-menu-above {
    margin-top: $spacing-small;
  }

  &.mat-menu-below {
    margin-bottom: $spacing-small;
  }

  // Remove the padding from mat-menu-content so that it cannot be clicked
  .mat-menu-content:not(:empty) {
    position: relative;
    padding-top: $spacing-none;
    padding-bottom: $spacing-none;
  }

  .mc-menu-item {
    display: flex;
    align-items: center;
    padding: $spacing-none $menu-spacing;
    line-height: $mat-menu-item-height;
    height: $mat-menu-item-height;
  }

  // Padding for menu items
  .mat-subheader,
  .mat-form-field,
  > .mat-error, // Top level mat-error only so that this doesn't affect a mat-error inside a mat-form-field
  .mat-selection-list .mat-list-item .mat-list-item-content,
  .mc-menu-button-group,
  .mc-menu-header,
  .mc-form-text,
  .mc-form-title,
  .mc-form-heading,
  .mc-form-note {
    padding-left: $menu-spacing;
    padding-right: $menu-spacing;
  }

  form {
    padding: $menu-spacing $spacing-none;
  }

  // Headers
  .mc-menu-header {
    margin: $spacing-xx-small $spacing-none;
  }

  .mat-subheader {
    font-size: $font-size-small;
  }

  // Form controls
  .mat-form-field {
    width: 100%;
  }

  // mat-radio
  .mat-radio-group {
    display: flex;
    flex-direction: column;
  }

  .mat-radio-label {
    width: 100%;
    padding: $spacing-xx-small $spacing-normal;
  }

  .mat-radio-label-content {
    min-width: 0;
    white-space: normal;
  }

  // Button group
  .mc-menu-button-group {
    text-align: right;
    margin-top: $spacing-small;

    button {
      margin-left: $spacing-form-controls;

      &:first-child {
        margin-left: $spacing-none;
      }
    }
  }

  .mc-menu-button-group-centered {
    text-align: center;
  }

  .mc-menu-button-group-justified {
    display: flex;
    justify-content: space-between;
  }

  // Close button
  .mc-menu-close-button {
    position: absolute;
    top: .2rem;
    right: .2rem;
  }

  // mc-expansion-panel
  .mc-expansion-panel-list,
  .mc-expansion-panel-form {
    .mat-subheader {
      padding: $spacing-none;
    }

    .mc-expansion-panel-content {
      padding-left: $spacing-none;
      padding-right: $spacing-none;
    }

    .mat-list-option .mat-list-item-content {
      padding-left: $mc-expansion-panel-spacing;
      padding-right: $mc-expansion-panel-spacing;
    }
  }

  // Font for list items
  .mat-selection-list,
  .mat-radio-group {
    font-weight: $font-weight-light;
  }
}
