$dialog-container-padding: $spacing-normal;
$dialog-horizontal-stepper-header-spacing: 2rem;
$dialog-border-radius: $border-radius-x-large;
$dialog-header-bar-height: $toolbar-height-header;
$dialog-horizontal-stepper-header-bar-height: $dialog-horizontal-stepper-header-spacing + $computed-line-height-normal;
$dialog-close-button-offset: ($dialog-header-bar-height - $icon-button-size-normal) * 0.5;
$dialog-horizontal-stepper-close-button-offset: $dialog-horizontal-stepper-header-spacing + $computed-line-height-normal * 0.5 - $icon-button-size-normal * 0.5;
$dialog-horizontal-stepper-header-offset: (
  $dialog-horizontal-stepper-close-button-offset + $icon-button-size-normal + $dialog-horizontal-stepper-close-button-offset - $mat-stepper-horizontal-header-horizontal-padding
);
$dialog-card-sidebar-width: 25rem;
$dialog-header-font-size: 1.8rem;
