/**
 * mc-stepper-arrow
 * Adds arrow indicators under the selected step in a mat-stepper-horizontal.
 */
.mc-stepper-arrow {
  &.mat-stepper-horizontal {
    .mat-step-header[aria-selected='true']:after {
      content: '';
      display: block;
      position: absolute;
      left: ($mat-stepper-horizontal-header-horizontal-padding + $mat-icon-width * 0.5); // Centers the arrow under the step's icon
      transform: translateX(-50%) rotate(45deg);
      bottom: (-$spacing-x-small * 0.5);
      height: $spacing-x-small;
      width: $spacing-x-small;
    }
  }
}

// Displays arrows stepper with a bottom border
.mc-stepper-arrow-border {
  &.mat-stepper-horizontal {

    // The border under the step labels
    .mat-horizontal-stepper-header-container {
      position: relative; // For the absolutely positioned bottom border

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    // The border on the arrow indicator
    .mat-step-header[aria-selected='true']:after {
      border-top: solid 1px;
      border-left: solid 1px;
      z-index: 1; // To place the arrow over the divider line along the bottom of the steps
    }
  }
}

/**
 * Spring board steppers
 */
.mc-spring-board-body.mat-stepper-horizontal,
.mc-spring-board-form-body.mat-stepper-horizontal {
  display: flex;
  flex-direction: column;
  height: 100%;

  .mat-horizontal-stepper-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0; // To keep it from growing larger than its parent
  }

  .mat-horizontal-stepper-header-container {
    flex-shrink: 0; // The header should take up all the space it needs
    // padding-right: $dialog-horizontal-stepper-header-offset; // Make room for the dialog close button
  }

  .mat-horizontal-content-container {
    flex-grow: 1;
    display: flex; // Makes its content fill all the height available
    flex-direction: column; // Makes its content fill all the height available
    padding: $spacing-normal;

    >.mat-horizontal-stepper-content {
      @extend .mc-spring-board;
      flex-grow: 1; // Makes this fill all the height available

      // Collapsed steps have a height of zero so reapply it here since .mc-spring-board gives the step a height of 100%
      &.mat-horizontal-stepper-content-inactive {
        height: 0;
        flex-grow: 0; // Makes this fill no height when collapsed
      }
    }
  }
}