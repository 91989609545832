@use '@angular/material' as mat;

@mixin mc-popups-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mc-popup {
    @include mat.elevation(8);
    background-color: mat.get-color-from-palette($background, card);

    // Match the theming for a mat-subheader within a mat-list
    .mat-subheader {
      color: mat.get-color-from-palette($foreground, list-subheader);
    }
  }

  .mc-popup-content {
    @include mat.elevation(8);
    background-color: mat.get-color-from-palette($background, card);
  }

  .mc-popup-toolbar {
    @include _mc-popup-toolbar($theme, 'basic', 200, '200-contrast');
  }
}

@mixin _mc-popup-toolbar ($theme, $palette-name, $hue: default, $contrast-hue: default-contrast) {
  $palette: map-get($theme, $palette-name);

  &.mat-#{$palette-name} {
    .mc-popup {
      background-color: mat.get-color-from-palette($palette, $hue);
      color: mat.get-color-from-palette($palette, $contrast-hue);
    }

    .mc-popup:after,
    .mc-popup-content {
      border-color: darken(mat.get-color-from-palette($palette, $hue), 10%);
    }
  }
}
