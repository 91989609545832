.accordion-flush-body {
  .mat-expansion-panel-body {
    padding-left: $spacing-none;
    padding-right: $spacing-none;
  }
}

.accordion-centered-headers {
  .mat-expansion-panel-header {
    justify-content: center;

    .mat-content {
      flex-grow: 0;
      white-space: nowrap;
      overflow: visible;
    }

    .mat-expansion-indicator {
      display: flex;
    }
  }
}

/**
 * mc-accordion-list
 * Styles an accordion for displaying lists as its content with the panel header looking like a list section header.
 */
.mc-accordion-list {
  .mat-expansion-panel {
    .mat-expansion-panel-body {
      padding: $spacing-none;
    }
  }
}
