@use '@angular/material' as mat;
@mixin mc-loaders-theme ($theme, $typography) {
  $background: map-get($theme, background);

  .mc-loader,
  .mc-loader-component {
    background: mat.get-color-from-palette($background, component);
  }

  .mc-loader-body {
    background: mat.get-color-from-palette($background, body);
  }

  .mc-loader-transparent {
    background: transparent;
  }

  .mc-loader-semi-transparent {
    opacity: .5;
  }
}
