@font-face {
  font-family: 'project-titan';
  src: url('#{$font-icon-path}/project-titan.eot');
  src: url('#{$font-icon-path}/project-titan.eot') format('embedded-opentype'),
  url('#{$font-icon-path}/project-titan.woff2') format('woff2'),
  url('#{$font-icon-path}/project-titan.woff') format('woff'),
  url('#{$font-icon-path}/project-titan.ttf') format('truetype'),
  url('#{$font-icon-path}/project-titan.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  display: inline-block;
  font: normal normal normal 1em/1 'project-titan';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
