@use '@angular/material' as mat;
@mixin mc-errors-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $error: map-get($theme, warn);

  .error-pane {
    background: mat.get-color-from-palette($background, component);
  }

  .error-pane-transparent {
    background: transparent;
  }

  .mc-error-message {
    color: mat.get-color-from-palette($error);
  }
}
