/**
 * mc-tree
 */
.mc-tree.mat-tree {
  display: inline-block; // Allows the tree to grow wider than its parent
  min-height: 100%; // Fill the parent
  min-width: 100%; // Fill the parent

  .mat-tree-node {
    min-height: $list-item-height;
    white-space: nowrap; // Disable text wrapping by default
  }

  .mc-tree-node-item {
    padding: $list-item-vertical-spacing $list-item-horizontal-spacing;
  }

  &.mc-tree-compact {
    .mat-tree-node {
      min-height: $list-item-height-compact;
    }

    .mc-tree-node-item {
      padding: $list-item-vertical-spacing-compact $list-item-horizontal-spacing-compact;
    }
  }

  &.mc-tree-avatars {
    .mc-tree-node-item {
      min-height: $list-item-avatars-content-min-height;
      padding: $list-item-avatars-vertical-spacing $list-item-horizontal-spacing-compact;
    }
  }
}

/**
 * mc-tree-text-icon
 */
.mat-tree {
  .mc-tree-text-icon.mat-icon {
    height: auto; // To center the icon vertically with the text
    line-height: 1; // To center the icon vertically with the text
    vertical-align: middle; // To center the icon vertically with the text
  }
}

/**
 * mc-tree-wrap
 */
.mc-tree-wrap.mat-tree {
  .mat-tree-node {
    white-space: normal;
    // Not using overflow-wrap: break-word; because word-break gives better results
    // FF
    word-break: break-all;
    // Chrome
    word-break: break-word;
  }
}

/**
 * mc-tree-node-item
 */
.mc-tree.mat-tree {
  @include nested-tree-node-indentation($tree-node-item-indentation, 4);

  &.mc-flat-tree {
    @include flat-tree-node-indentation($tree-node-item-indentation);
  }

  .mc-tree-node-item {
    position: relative;
    flex-grow: 1; // Fill the mat-tree-node
    box-sizing: content-box; // Use content-box sizing to give the item's content a min-width
    min-height: $list-item-content-height;
    min-width: $tree-node-item-min-width;
    display: flex;
    align-items: center;

    > .mc-tree-text-icon {
      flex-shrink: 0;
    }

    // Give a little space to the right of the checkbox if there is an element there
    // This is for cases where a mat-checkbox is used but the label is outside of the checkbox
    .mat-checkbox + *,
    .mat-pseudo-checkbox + * {
      margin-left: $tree-node-item-checkbox-spacing;
    }

    // Give the checkbox the same spacing that is between the icon and text of a tree item's content
    .mat-checkbox {
      .mat-checkbox-inner-container {
        margin-right: $tree-node-item-checkbox-spacing;
      }
    }

    // Change the spacing between the avatar icon and the avatar name to match the spacing in the tree node item
    .mc-avatar-base {
      .mc-avatar-container + .mc-avatar-name {
        margin-left: $tree-node-item-avatar-name-spacing;
      }
    }
  }

  // Remove the button look
  button.mc-tree-node-item {
    background: none;
    border: $border-none;
    outline: $outline-none;
    text-align: left;

    &:disabled {
      pointer-events: none;
    }
  }
}

/**
 * mc-tree-node-rail
 */
.mc-tree.mat-tree {
  .mc-tree-node-rail {
    @include flat-tree-rail-indentation($tree-node-item-indentation, $tree-node-expander-font-size);

    position: absolute;
    top: 0;
    bottom: 0;
    border-left: solid $tree-rail-width;
  }

  &.mc-selection-tree {
    .mc-tree-node-rail {
      @include flat-tree-rail-indentation($tree-node-item-indentation, $mat-checkbox-width);
    }
  }
}
