/* icons */
$icons: (
  'add': 'ea01',
  'add-condition': 'ea02',
  'add-definition': 'ea03',
  'add-multiple-widgets': 'ea04',
  'add-variable': 'ea05',
  'add-variable-in-definition-or-text': 'ea06',
  'add-widget': 'ea07',
  'admin-site-logo': 'ea08',
  'alert': 'ea09',
  'angle': 'ea0a',
  'archive': 'ea0b',
  'assign-task': 'ea0c',
  'attach': 'ea0d',
  'bar-chart': 'ea0e',
  'bell': 'ea0f',
  'bill': 'ea10',
  'block': 'ea11',
  'bold': 'ea12',
  'bookmark': 'ea13',
  'branching': 'ea14',
  'broken-link': 'ea15',
  'build': 'ea16',
  'burger': 'ea17',
  'calendar': 'ea18',
  'central': 'ea19',
  'chat-gpt': 'ea1a',
  'check': 'ea1b',
  'checkmark': 'ea1c',
  'circle-help': 'ea1d',
  'cleanxhtmltarget': 'ea1e',
  'cleartargetcommand': 'ea1f',
  'clock': 'ea20',
  'close': 'ea21',
  'closedfolder': 'ea22',
  'collab': 'ea23',
  'collapse': 'ea24',
  'collapse-all': 'ea25',
  'command': 'ea26',
  'commit': 'ea27',
  'commit-horizontal': 'ea28',
  'completed': 'ea29',
  'condition-tree-view': 'ea2a',
  'conditions-editor': 'ea2b',
  'conditions-show-hide': 'ea2c',
  'copy': 'ea2d',
  'credit-card': 'ea2e',
  'cross-reference': 'ea2f',
  'crown': 'ea30',
  'css': 'ea31',
  'cylon': 'ea32',
  'default-site': 'ea33',
  'definition-list': 'ea34',
  'delete-cross-reference': 'ea35',
  'deletebuild': 'ea36',
  'discussion': 'ea37',
  'ditaicon': 'ea38',
  'document-solid': 'ea39',
  'documenticon': 'ea3a',
  'dot': 'ea3b',
  'dot-circle': 'ea3c',
  'dotnethelpicon': 'ea3d',
  'down-arrow-90': 'ea3e',
  'download': 'ea3f',
  'drag-and-drop': 'ea40',
  'drop-down-hotspot': 'ea41',
  'drop-down-text': 'ea42',
  'eclipseicon': 'ea43',
  'edit-curves': 'ea44',
  'epubicon': 'ea45',
  'expand': 'ea46',
  'expand-all': 'ea47',
  'eye-open': 'ea48',
  'files-o': 'ea49',
  'filter': 'ea4a',
  'flare': 'ea4b',
  'folder': 'ea4c',
  'folder-solid': 'ea4d',
  'font-styles': 'ea4e',
  'format-code': 'ea4f',
  'gear': 'ea50',
  'gear-solid': 'ea51',
  'gift': 'ea52',
  'grid': 'ea53',
  'guide-site-logo': 'ea54',
  'help': 'ea55',
  'home': 'ea56',
  'html5icon': 'ea57',
  'icon-adobe-air': 'ea58',
  'icon-batch-target': 'ea59',
  'icon-frame-maker': 'ea5a',
  'info': 'ea5b',
  'info-outline': 'ea5c',
  'inprogress': 'ea5d',
  'insert-child': 'ea5e',
  'insert-proxy': 'ea5f',
  'insert-sibling': 'ea60',
  'invite': 'ea61',
  'italics': 'ea62',
  'kanban': 'ea63',
  'kebab': 'ea64',
  'kebab-solid': 'ea65',
  'languages': 'ea66',
  'launch': 'ea67',
  'left-arrow': 'ea68',
  'left-arrow-90': 'ea69',
  'licenses': 'ea6a',
  'line-wrap': 'ea6b',
  'link': 'ea6c',
  'linked-to-live-site': 'ea6d',
  'list': 'ea6e',
  'live': 'ea6f',
  'lock': 'ea70',
  'lock-solid': 'ea71',
  'madtranslations-logo': 'ea72',
  'mail': 'ea73',
  'margin-left': 'ea74',
  'margin-right': 'ea75',
  'maximize': 'ea76',
  'meatballs': 'ea77',
  'meatballs-solid': 'ea78',
  'minimize': 'ea79',
  'minus': 'ea7a',
  'missing-link': 'ea7b',
  'mobileicon': 'ea7c',
  'more': 'ea7d',
  'multimedia': 'ea7e',
  'multiselect': 'ea7f',
  'na': 'ea80',
  'openbuildlogcommand': 'ea81',
  'openfolder': 'ea82',
  'ordered': 'ea83',
  'overview': 'ea84',
  'paragraph': 'ea85',
  'pdficon': 'ea86',
  'pen': 'ea87',
  'pennant': 'ea88',
  'picture': 'ea89',
  'pin': 'ea8a',
  'play': 'ea8b',
  'plug': 'ea8c',
  'ppt': 'ea8d',
  'privacy': 'ea8e',
  'projectdashicon': 'ea8f',
  'pw': 'ea90',
  'rebuild': 'ea91',
  'rebuildcommand': 'ea92',
  'redo': 'ea93',
  'refresh': 'ea94',
  'reticle': 'ea95',
  'review': 'ea96',
  'review-package': 'ea97',
  'right-arrow': 'ea98',
  'right-arrow-90': 'ea99',
  'ruler': 'ea9a',
  'saved-items': 'ea9b',
  'schedulebuild': 'ea9c',
  'search': 'ea9d',
  'seat': 'ea9e',
  'setlivecommand': 'ea9f',
  'show-deleted': 'eaa0',
  'sidebysidecompare': 'eaa1',
  'simon': 'eaa2',
  'simon-shrug': 'eaa3',
  'snippet': 'eaa4',
  'sso': 'eaa5',
  'star': 'eaa6',
  'status': 'eaa7',
  'stopbuildcommand': 'eaa8',
  'submit': 'eaa9',
  'table': 'eaaa',
  'team': 'eaab',
  'template': 'eaac',
  'templates': 'eaad',
  'toc': 'eaae',
  'todo': 'eaaf',
  'track-changes': 'eab0',
  'translation': 'eab1',
  'translation-branch': 'eab2',
  'trash': 'eab3',
  'underline': 'eab4',
  'undo': 'eab5',
  'unlink': 'eab6',
  'up-arrow-90': 'eab7',
  'upload': 'eab8',
  'user': 'eab9',
  'user-project': 'eaba',
  'variable': 'eabb',
  'variable-tree-view': 'eabc',
  'verticalcompare': 'eabd',
  'view': 'eabe',
  'viewrenameddeletedtarget': 'eabf',
  'webhelpicon': 'eac0',
  'webhelpplusicon': 'eac1',
  'wordicon': 'eac2',
  'world-2': 'eac3',
  'xhtmlicon': 'eac4',
  'xpsicon': 'eac5',
);

@function unicode($str) {
  @return unquote('\"\\#{$str}\"');
}

@each $name, $code in $icons {
  .icon-#{""+$name}::before {
    content: unicode($code);
  }
}
