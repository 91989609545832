.popup-card {
  width: $popup-card-width;

  .popup-card-header {
    display: flex;
    padding: $popup-card-spacing;
    height: $popup-card-header-height;
  }

  .popup-card-header-button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: $popup-card-header-button-horizontal-offset;
    margin-top: $popup-card-header-button-vertical-offset;
  }

  .popup-card-option-icon::before {
    // Combination of the font size and vertical align center the icon correctly.
    font-size: $font-size-x-large;
    vertical-align: top;
  }
}
