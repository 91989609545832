@use '@angular/material' as mat;
@mixin mc-cards-theme ($theme, $typography) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .split-card-header {
    background: mat.get-color-from-palette($background, card-invert);
    color: mat.get-color-from-palette($primary);
  }
}
