.mc-data-grid-base {
  display: flex;
  flex-direction: column;
  height: 100%;

  > mc-data-grid {
    flex-grow: 1;
    height: 100%;
  }
}

.mc-grid-padded-cells {
  .mat-cell,
  .mat-header-cell {
    padding-left: $spacing-normal;
  }
}

/**
 * mc-grid-card
 */
.mc-grid-card {
  min-width: 0; // Keep the card from growing wider than the cell
}
