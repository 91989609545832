@use '@angular/material' as mat;

@mixin mc-calendars-theme ($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $secondary: map-get($theme, secondary);
  $warn: map-get($theme, warn);
  $notice: map-get($theme, notice);

  .cal-month-view .cal-days .cal-cell-row,
  .cal-month-view .cal-day-cell:not(:last-child) {
    border-color: mat.get-color-from-palette($foreground, calendar-divider);
  }

  .mc-month-calendar-small {
    .cal-month-view {
      border-color: mat.get-color-from-palette($foreground, calendar-divider);
    }

    .cal-month-view .cal-day-number {
      opacity: 1;
    }

    // Weekend Cell
    .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
      color: mat.get-color-from-palette($foreground, text-muted);
    }

    // Today Cell
    .cal-month-view .cal-day-cell.cal-today {
      background-color: transparent;
    }

    // .cal-month-view .cal-day-cell.cal-today .cal-day-number {
    //   color: mat-color($warn);
    // }

    .cal-month-view .cal-day-cell.cal-today .cal-day-number {
      background: mat.get-color-from-palette($secondary, default);
      color: mat.get-color-from-palette($secondary, default-contrast);
    }

    // Selected Cell
    // .cal-month-view .cal-day-cell.cal-day-selected .cal-day-number {
    //   background: mat-color($secondary, default);
    //   color: mat-color($secondary, default-contrast);
    // }

    // .cal-month-view .cal-day-cell.cal-today.cal-day-selected .cal-day-number {
    //   background: mat-color($warn, default);
    //   color: mat-color($warn, default-contrast);
    // }
    .cal-month-view .cal-day-cell.cal-day-selected {
      background: mat.get-color-from-palette($notice, lighter);
    }
  }
}
