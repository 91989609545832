@import 'variables/index';

.mc-system-message-card {
  width: 23.2rem;
  height: $system-message-card-height;
  display: flex;
  padding: $spacing-xx-small;
  overflow: hidden;
  border-radius: $page-card-border-radius;
  box-shadow: $page-card-box-shadow;
  position: relative;
  cursor: pointer;

  .mc-system-message-card-icon-container {
    width: 6rem;
    padding: $spacing-xx-small;
    margin-right: $spacing-xx-small;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mc-system-message-card-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;

    .mc-system-message-card-content {
      padding-top: $spacing-xxx-small;
      line-height: $line-height-normal;
      text-align: left;
      font-size: $font-size-small;
      font-weight: $font-weight-light;
    }
  }

  .mc-system-message-card-close-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}
