@use '@angular/material' as mat;

@mixin mc-editors-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $caution: map-get($theme, caution);
  $foreground: map-get($theme, foreground);
  $info: map-get($theme, info);
  $warn: map-get($theme, warn);
  $notice: map-get($theme, notice);

  // Make the text color blue in node views when they are inside of a link (eg snippets)
  .mc-pm-link {
    .mc-node-view {
      &.mc-node-view-pane,
      &.mc-node-view-inline-pane {
        color: mat.get-color-from-palette($foreground, link);
      }
    }
  }

  /*
   * Editor NodeViews
   */
  .mc-node-view {
    .mc-node-view-change-remove-line {
      stroke: mat.get-color-from-palette($warn);
    }

    .mc-node-view-pane,
    &.mc-node-view-pane {
      border: solid $border-width mat.get-color-from-palette($foreground, divider);
      color: mat.get-color-from-palette($foreground, divider);
    }

    .mc-node-view-inline-pane,
    &.mc-node-view-inline-pane {
      outline: solid $border-width mat.get-color-from-palette($foreground, divider);
      color: mat.get-color-from-palette($foreground, divider);
    }

    &.mc-node-view-change {
      .mc-node-view-text,
      &.mc-node-view-text {
        color: mat.get-color-from-palette($warn);
      }

      .mc-node-view-pane,
      &.mc-node-view-pane {
        border: solid $border-width mat.get-color-from-palette($warn);
        color: mat.get-color-from-palette($warn);
      }

      .mc-node-view-inline-pane,
      &.mc-node-view-inline-pane {
        outline: solid $border-width mat.get-color-from-palette($warn);
        color: mat.get-color-from-palette($warn);
      }

      .mc-node-view-change-remove .mc-node-view-inline-pane,
      &.mc-node-view-change-remove.mc-node-view-inline-pane {
        * {
          text-decoration: line-through;
        }
      }
    }
  }

  // Include the Firefox overrides for inline node views
  @include _mc-editor-inline-node-view-firefox($theme, $typography);

  .mc-pm-document .ProseMirror,
  .mc-rich-text {
    /*
     * prosemirror gap cursor
     */
    .ProseMirror-gapcursor:after {
      border-top-color: currentColor;
    }

    /*
     * Selected Node Views
     */
    .ProseMirror-selectednode {
      outline-color: mat.get-color-from-palette($info, lighter);
    }

    /*
     * image upload plugin
     */
    .mc-editor-image-upload-placeholder, .mc-editor-image-upload-error-placeholder {
      border-color: mat.get-color-from-palette($foreground, divider);
      color: mat.get-color-from-palette($foreground, divider);
    }

    .mc-editor-image-upload-error-icon-close {
      color: mat.get-color-from-palette($warn);
    }

    hr {
      border-top-color: mat.get-color-from-palette($foreground, divider);
    }

    /*
     * tables
     */
    table .selectedCell {
      background: mat.get-color-from-palette($info, 50);
    }

    td,
    th {
      min-width: 1em;
      border: 1px solid mat.get-color-from-palette($foreground, divider);
    }

    table.mc-madcap-change-add,
    table.mc-madcap-change-remove {
      tbody tr {

        td,
        th {
          border: 1px solid mat.get-color-from-palette($warn);
        }
      }
    }

    /*
     * code/pre
     */
    code,
    pre {
      background: mat.get-color-from-palette($background, component-board);
      border-color: mat.get-color-from-palette($foreground, divider);
    }

    /*
     * links
     */
    .mc-pm-link,
    .mc-madcap-xref {
      color: mat.get-color-from-palette($foreground, link);
    }

    /*
     * annotations
     */
    .mc-madcap-annotation {
      background-color: mat.get-color-from-palette($caution);
    }

    /*
     * changes
     */
    .mc-madcap-change {
      color: mat.get-color-from-palette($warn);

      &.mc-madcap-change-untracked {
        display: inline-block; // This removes the inherited text-decoration from .mc-madcap-change-remove
        color: mat.get-color-from-palette($foreground, editor-text);
      }
    }

    /*
     * page-breaks
     */
    .mc-madcap-page-break {
      background: mat.get-color-from-palette($background, editor-page-break);
    }

    /*
     * dropdowns
     */
    .mc-madcap-dropdown-head {
      &:before {
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: mat.get-color-from-palette($foreground, divider);
      }
    }

    .mc-madcap-dropdown-hotspot {
      color: mat.get-color-from-palette($info);
    }

    .mc-madcap-dropdown-body {
      border-left-color: mat.get-color-from-palette($foreground, divider);
    }

    /*
     * multiple choice
     */
    .mc-madcap-submit-question-button {
      border-color: mat.get-color-from-palette($foreground, divider);
    }

    .mc-madcap-correct-feedback,
    .mc-madcap-incorrect-feedback {
      border-color: mat.get-color-from-palette($foreground, divider);
    }

    .mc-madcap-correct-feedback:before {
      color: mat.get-color-from-palette($notice, darker);
    }

    .mc-madcap-incorrect-feedback:before {
      color: mat.get-color-from-palette($warn, darker);
    }

    .mc-dynamic-view-type-multiple-choice-item-node-view {
      .mat-checkbox.cdk-focused:not(.mat-checkbox-disabled) {
        .mat-checkbox-frame {
          box-shadow: 0 0 0 $border-width-thick mat.get-color-from-palette($info, lighter);
        }
      }

      .mat-radio-button.cdk-focused:not(.mat-radio-disabled) {
        .mat-radio-outer-circle {
          box-shadow: 0 0 0 $border-width-thick mat.get-color-from-palette($info, lighter);
        }
      }
    }

    /*
     * conditions
     */
    .mc-madcap-conditional-text,
    [madcap\:conditions] {
      border-color: mat.get-color-from-palette($foreground, divider);
    }

    .mc-madcap-condition-bg-decoration-marker:not(mc-dynamic-view):before,
    .mc-madcap-condition-bg-decoration-marker>.mc-node-view>.mc-madcap-variable:before,
    .mc-madcap-condition-bg-decoration-marker>mc-image-node-view:before,
    .mc-madcap-condition-bg-decoration-marker-a:after {
      border-color: mat.get-color-from-palette($foreground, divider);
    }

    /*
     * bracketed elements
     */
    // inline elements
    .mc-pm-abbr,
    .mc-pm-acronym,
    .mc-pm-bdi,
    .mc-pm-bdo,
    .mc-pm-big,
    .mc-pm-cite,
    .mc-pm-del,
    .mc-pm-dfn,
    .mc-pm-ins,
    .mc-pm-kbd,
    .mc-pm-label,
    .mc-pm-mark,
    .mc-pm-q,
    .mc-pm-rp,
    .mc-pm-ruby,
    .mc-pm-small,
    .mc-pm-span,
    .mc-pm-strike,
    .mc-pm-sub,
    .mc-pm-sup,
    .mc-pm-time,
    .mc-madcap-conditional-text,
    .mc-madcap-expanding-body,
    .mc-madcap-expanding-head,
    .mc-madcap-glossary-term,
    .mc-madcap-popup-body,
    .mc-madcap-popup-head,
    .mc-madcap-toggler,
    // block elements
    .mc-pm-address,
    .mc-pm-article,
    .mc-pm-aside,
    .mc-pm-base,
    .mc-pm-center,
    .mc-pm-comment,
    .mc-pm-details,
    .mc-pm-div,
    .mc-pm-dl,
    .mc-pm-fieldset,
    .mc-pm-footer,
    .mc-pm-header,
    .mc-pm-main,
    .mc-pm-map,
    .mc-pm-marquee,
    .mc-pm-menu,
    .mc-pm-meter,
    .mc-pm-nav,
    .mc-pm-progress,
    .mc-pm-samp,
    .mc-pm-section,
    .mc-pm-select,
    .mc-pm-textarea,
    .mc-pm-var,
    .mc-madcap-micro-content,
    .mc-madcap-code-snippet-caption,
    .mc-madcap-code-snippet-body {
      @include _mc-editor-brackets-theme(mat.get-color-from-palette($notice));
    }

    /*
     * read-only bracketed elements
     */
    .mc-madcap-footnote,
    .mc-madcap-footnote-block,
    .mc-madcap-footnotes-block,
    .mc-madcap-variable {
      @include _mc-editor-brackets-theme(mat.get-color-from-palette($info));
    }

    /*
     * name box elements
     */
    .mc-pm-form,
    .mc-pm-iframe,
    .mc-pm-input,
    .mc-pm-button,
    .mc-pm-script,
    .mc-pm-svg,
    .mc-pm-template,
    .mc-madcap-multimedia,
    .mc-madcap-shortcut,
    .mc-madcap-qr-code,
    .mc-madcap-equation,
    .mc-madcap-body-proxy,
    .mc-madcap-breadcrumbs-proxy,
    .mc-madcap-central-account-proxy,
    .mc-madcap-topic-toolbar-proxy,
    .mc-madcap-mini-toc-proxy,
    .mc-madcap-toc-proxy,
    .mc-madcap-index-proxy,
    .mc-madcap-list-of-proxy,
    .mc-madcap-concepts-proxy,
    .mc-madcap-glossary-proxy,
    .mc-madcap-relationships-proxy,
    .mc-madcap-endnotes-proxy,
    .mc-madcap-search-bar-proxy,
    .mc-madcap-menu-proxy,
    .mc-madcap-search-results-proxy,
    .mc-madcap-e-learning-toolbar-proxy,
    .mc-madcap-test-results-proxy,
    .mc-madcap-faq-proxy,
    .mc-madcap-knowledge-proxy,
    .mc-madcap-promotion-proxy,
    .mc-madcap-slideshow,
    .mc-madcap-model-3d,
    .mc-madcap-footnotes-block,
    .mc-madcap-footnote-block,
    .mc-madcap-endnotes-block,
    .mc-madcap-endnote-block,
    .mc-madcap-concept-link,
    .mc-madcap-keyword-link,
    .mc-madcap-related-topics,
    // .mc-madcap-form-fields,
    .mc-madcap-page-header,
    .mc-madcap-page-footer,
    .mc-mml-math {
      @include _mc-editor-name-box-theme(mat.get-color-from-palette($background, component-board));
    }

    /*
     * star elements
     */
    .icon-star {
      @include _mc-editor-star-theme(mat.get-color-from-palette($info));
    }

    /*
     * bookmark elements
     */
    .icon-bookmark {
      @include _mc-editor-bookmark-theme(mat.get-color-from-palette($info));
    }

    /*
     * gutter decorations
     */
    .mc-editor-gutter-target {
      background-color: mat.get-color-from-palette($info);
      color: mat.get-color-from-palette($foreground, editor-gutter-active);
    }
  }

  /*
   * editor gutter
   */
  .mc-editor-gutter-canvas {
    .mc-editor-gutter-line {
      stroke: mat.get-color-from-palette($info);
    }
  }
}

/*
 * Themes the editor elements
 */
@mixin _mc-editor-brackets-theme($color) {
  border-left-color: $color;
  border-right-color: $color;

  background-position: top left, bottom left, top right, bottom right;
  background-image: linear-gradient($color, $color), linear-gradient($color, $color), linear-gradient($color, $color), linear-gradient($color, $color);
  background-size: $editor-bracket-width $editor-bracket-width;
  background-repeat: no-repeat;
}

@mixin _mc-editor-name-box-theme($bg-color) {
  background-color: $bg-color;
}

@mixin _mc-editor-star-theme($color) {
  &:before {
    color: $color;
  }
}

@mixin _mc-editor-bookmark-theme($color) {
  &:before {
    color: $color;
  }
}

/**
 * Firefox specific styles for inline node views (eg inline snippets).
 * Chrome draws outlines around multiline text but Firefox draws the outlines around each line of text which looks bad.
 * Change Firefox to use a background color instead of an outline.
 */
@mixin _mc-editor-inline-node-view-firefox($theme, $typography) {
  $basic: map-get($theme, basic);
  $foreground: map-get($theme, foreground);
  $info: map-get($theme, info);
  $warn: map-get($theme, warn);

  @-moz-document url-prefix() {
    .mc-rich-text,
    .mc-pm-document .ProseMirror {
      /*
       * Inline Node Views
       */
      .mc-node-view {
        .mc-node-view-inline-pane,
        &.mc-node-view-inline-pane {
          outline-width: 0 !important; // Remove the outline
          background-color: mat.get-color-from-palette($basic, 200); // Use a background color instead
          color: mat.get-color-from-palette($foreground, text-muted); // Use a text color that has better contrast on the background color
        }

        /*
         * Tracked Changes
         */
        &.mc-node-view-change {
          .mc-node-view-pane,
          &.mc-node-view-pane,
          .mc-node-view-inline-pane,
          &.mc-node-view-inline-pane {
            color: mat.get-color-from-palette($warn);
          }

          .mc-node-view-change-remove .mc-node-view-inline-pane,
          &.mc-node-view-change-remove.mc-node-view-inline-pane {
            * {
              text-decoration: line-through;
            }
          }
        }
      }

      /*
       * Inline Snippets
       */
      .mc-dynamic-view-type-snippet-node-view-inline-outer {
        margin-left: 1px; // Shift left by the cursor width
        margin-right: 0; // Remove the margin for inline snippets
      }

      /*
       * Selected Inline Snippets
       */
      .ProseMirror-selectednode.mc-dynamic-view-type-snippet-node-view-inline,
      .ProseMirror-selectednode.mc-dynamic-view-type-snippet-node-view-inline-outer {
        outline-width: 0; // Remove the outline for selected snippets as well

        .mc-node-view {
          .mc-node-view-inline-pane,
          &.mc-node-view-inline-pane {
            background-color: mat.get-color-from-palette($info, lighter); // Use the selection color for the background color
            color: mat.get-color-from-palette($info, lighter-contrast); // Use a text color that has better contrast on the background color
          }
        }
      }
    }
  }
}
