.mc-material {
  /*
   * Lists with wrapping items
   */
  .mc-list-wrap {
    &.mat-list-base {
      .mat-list-item {
        // Let the item grow to fit its content
        min-height: 0;
        height: auto;

        // Since there are multiple lines of text change the text alignment to the start
        text-align: start;

        // When a list item has a mat-list-icon its height is set so set it to auto here
        &.mat-list-item-with-avatar {
          height: auto;
        }

        .mat-list-item-content {
          // Let the item's content grow and give it some padding so it has space between other items
          min-height: inherit;
          padding-top: $list-item-vertical-spacing;
          padding-bottom: $list-item-vertical-spacing;
          // Make sure the text always wraps
          /* FF & Edge */
          word-break: break-all;
          /* Chrome */
          word-break: break-word;
        }

        // Let the item grow to fis when it has lines
        &.mat-2-line {
          height: auto;
        }

        // Lines need to have their white-space overridden
        .mat-line {
          white-space: normal;
        }
      }

      // A dense list has less space between the items
      &.mc-list-dense {
        .mat-list-item {
          .mat-list-item-content {
            padding-top: $list-item-dense-vertical-spacing;
            padding-bottom: $list-item-dense-vertical-spacing;
          }
        }
      }
    }
  }

  /**
   * List Background
   * Applies a background to the list items based on if an item is selected or hovered
   */
  .mc-list-bg-light,
  .mc-list-bg-dark {
    @include mc-list-bg;
  }

  /**
   * List Text Size
   * Applies different font-sizes to the list items based on if an item is selected
   */
  .mc-list-text-size-small {
    @include mc-list-font-size($font-size-small, $font-size-normal);
  }

  .mc-list-text-size-normal {
    @include mc-list-font-size($font-size-normal, $font-size-large);
  }

  /**
   * List Text Weight
   * Applies different font-weights to the list items based on if an item is selected
   */
  .mc-list-text-weight-normal,
  .mc-list-selectable {
    @include mc-list-font-weight($font-weight-normal, $font-weight-heavy);
  }

  .mc-list-text-weight-light {
    @include mc-list-font-weight($font-weight-light, $font-weight-normal);
  }

  /**
   * Disabled List Item
   * Applies the default cursor to the item
   */
  .mat-list-base {
    .mat-list-item {
      &.mc-list-item-disabled {
        cursor: default;
      }
    }
  }

  /**
   * Compact List
   * A list that takes up less space.
   */
  .mc-list-compact {
    &.mat-list-base {
      .mat-list-item,
      .mat-list-item.mat-list-item-with-avatar {
        height: 3.6rem;
      }

      .mat-list-item {
        .mat-list-icon {
          font-size: $font-size-large;
          margin-top: $spacing-xxx-small;
          padding-right: $spacing-none;
        }
      }
    }
  }

  /*
   * mc-list-no-outer-spacing
   */
  .mc-list-no-outer-spacing {
    &.mat-list-base {
      .mat-list-item .mat-list-item-content,
      .mat-list-option .mat-list-item-content {
        padding-left: $spacing-none;
        padding-right: $spacing-none;
      }
    }
  }

  /*
   * mat-list-icon
   * Different mat-list-icon sizes
   */
  .mat-list-base .mat-list-item .mat-list-icon,
  .mat-list-base .mat-list-option .mat-list-icon {
    &.mc-list-icon-xxx-small,
    &.mc-list-icon-xx-small,
    &.mc-list-icon-x-small {
      width: $font-size-x-small;
      height: $font-size-x-small;
      font-size: $font-size-x-small;
    }

    &.mc-list-icon-small {
      width: $font-size-small;
      height: $font-size-small;
      font-size: $font-size-small;
    }
  }

  /**
   * mc-gutter-list-icons
   * Moves the mat-list-icons into the padding of the mat-list-items
   */
  .mc-gutter-list-icons {
    &.mat-list-base .mat-list-item {
      // Shift the mat-list-icons into the padding of the mat-list-item-content (the gutter). Make it centered in the gutter.
      .mat-list-icon {
        margin-left: -(($list-item-horizontal-spacing * 0.5) + (($mat-list-icon-width + $mat-list-icon-padding * 2) * 0.5));

        &.mc-list-icon-xxx-small,
        &.mc-list-icon-xx-small,
        &.mc-list-icon-x-small {
          margin-left: -(($list-item-horizontal-spacing * 0.5) + (($font-size-x-small + $mat-list-icon-padding * 2) * 0.5));
        }

        &.mc-list-icon-xxx-small {
          transform: scale(.5); // The mat-icons can't be smaller than 10px so scale them at this point
        }

        &.mc-list-icon-xx-small {
          transform: scale(.7); // The mat-icons can't be smaller than 10px so scale them at this point
        }

        &.mc-list-icon-small {
          margin-left: -(($list-item-horizontal-spacing * 0.5) + (($font-size-small + $mat-list-icon-padding * 2) * 0.5));
        }
      }

      .mat-list-item-content {
        // Remove the padding from mat-list-text when there is no mat-list-icon
        .mat-list-text {
          padding-left: $spacing-none;
        }

        // Add some padding to the mat-list-text so that it lines up with the edge of mat-list-item-content. This is necessary because of the negative margin on the mat-list-icon
        .mat-list-icon {
          + .mat-list-text {
            padding-left: (($list-item-horizontal-spacing - ($mat-list-icon-width + $mat-list-icon-padding * 2)) * 0.5);
          }

          &.mc-list-icon-xxx-small + .mat-list-text,
          &.mc-list-icon-xx-small + .mat-list-text,
          &.mc-list-icon-x-small + .mat-list-text {
            padding-left: (($list-item-horizontal-spacing - ($font-size-x-small + $mat-list-icon-padding * 2)) * 0.5);
          }

          &.mc-list-icon-small + .mat-list-text {
            padding-left: (($list-item-horizontal-spacing - ($font-size-small + $mat-list-icon-padding * 2)) * 0.5);
          }
        }
      }
    }
  }

  // Makes the items in a mat-selection-list grow to fit their content
  .mc-selection-list-multi-line.mat-selection-list {
    .mat-list-option {
      height: auto;

      .mat-list-item-content {
        padding: $spacing-small;
      }
    }
  }

  /**
   * mc-list-item
   */
  .mat-list-base .mat-list-item.mat-2-line.mc-list-item { // Extra specificity to override Material
    height: $list-item-2-lines-height;
  }

  /**
   * mc-list-item-details
   */
  .mat-list-base .mat-list-item .mat-list-text .mat-line.mc-list-item-details { // Extra specificity to override Material
    margin-top: $spacing-xxx-small;
    font-size: $list-item-details-font-size;
  }
}

/*
 * Lists Load More
 */
.mc-list-container {
  position: relative;

  .mc-list-header,
  .mc-list-footer {
    position: relative;
    padding: $spacing-large;
    text-align: center;
  }

  .mc-list-loading-more-error {
    position: absolute;
    bottom: ($computed-line-height-normal * 0.5);
    left: 0;
    right: 0;
    text-align: center;
  }

  .mc-list-loading-more-progress-bar-top {
    position: sticky;
    z-index: z-index(loader);
    top: 0;
    left: 0;
    right: 0;
  }

  .mc-list-loading-more-progress-bar-bottom {
    position: sticky;
    z-index: z-index(loader);
    bottom: 0;
    left: 0;
    right: 0;
  }
}

/*
 * Property List
 */
.mc-property-list {
  list-style: none;
  padding-left: $spacing-none;

  > li,
  > .mc-property-list-item {
    margin-bottom: $spacing-xxx-small;
  }

  .mc-property-list-item-truncate {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    gap: $text-space-width;

    .mc-property-name {
      flex-shrink: 0;
    }

    .mc-property-value {
      flex-grow: 1;
      min-width: 0;
    }
  }

  .mc-sub-property {
    padding-left: $spacing-x-small;
  }

  .mc-property-name {
    // Empty (for now)
  }

  .mc-property-value {
    font-weight: $font-weight-light;
  }

  .mc-property-value-wrap,
  a.mc-property-value {
    word-break: break-all;
  }

  &.mc-property-list-heavy-weight {
    .mc-property-name {
      font-weight: $font-weight-heavy;
    }

    .mc-property-value {
      font-weight: $font-weight-normal;
    }
  }
}

/*
 * Avatar List
 */
.mc-avatar-list {
  list-style: none;
  padding-left: $spacing-none;
  display: flex;
  flex-wrap: wrap;

  .mc-avatar-list-item {
    margin: $spacing-none $avatar-list-item-spacing $avatar-list-item-spacing $spacing-none;
  }

  &.mc-avatar-list-horizontal,
  &.mc-avatar-list-truncate {
    flex-wrap: nowrap;

    .mc-avatar-list-item {
      margin: $spacing-none $avatar-list-item-spacing $spacing-none $spacing-none;

      &:last-child {
        margin-right: $spacing-none;
      }
    }
  }

  &.mc-avatar-list-truncate {
    overflow: hidden;
  }

  &.mc-avatar-list-fade {
    position: relative;

    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 3rem;
    }
  }
}
