// This mixins is taken directly from angular-material's src/lib/badge/_badge-theme.scss
// Mixin for building offset given different sizes
@mixin mc-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    // @include cdk-high-contrast {
    //   outline: solid 1px;
    //   border-radius: 0;
    // }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}
