.page-card {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: $page-card-border-radius;
  box-shadow: $page-card-box-shadow;
  overflow: hidden; // Keeps the content from overflowing the border-radius

  > *,
  .page-card-body,
  .page-card-body > * {
    flex-grow: 1;
    min-height: 0; // To keep it from growing larger than its parent
  }

  > router-outlet,
  > .page-card-header {
    flex-grow: 0;
  }
}

.page-card-body {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
}

.page-card-column {
  border-radius: $page-card-border-radius;
  box-shadow: $page-card-box-shadow;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}
