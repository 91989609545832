.mc-border-normal {
  border-style: solid;
  border-width: $border-width;
}

.mc-border-radius-normal {
  border-radius: $border-radius-normal;
}

.mc-padding-xxx-small {
  padding: $spacing-xxx-small;
}

.mc-padding-normal {
  padding: $spacing-normal;
}
