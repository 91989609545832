.grid-header {
  display: flex;
  align-items: center;

  .mat-sort-header-button {
    min-width: 0; // Keep the title from growing wider than its flex parent
    overflow-x: hidden; // Keep the title from going under the sort arrow
  }

  .grid-header-title.mat-sort-header-disabled {
    .mat-sort-header-arrow {
      display: none;
    }
  }
}

.grid-header-title {
  overflow: hidden;
  padding: $spacing-xxx-small $spacing-none; // Prevents text descenders from getting cutoff by overflow: hidden
}

.mc-header-title-fill-remaining-space {
  flex-grow: 1;
}

.grid-header-filter-menu {
  width: $filter-menu-width;
}

.mc-grid-header-filter-menu-header.mat-subheader {
  padding-bottom: $spacing-none;
}
