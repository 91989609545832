.mat-checkbox {
  $mc-checkbox-top-aligned-offset: ($computed-line-height-normal - $mat-checkbox-height) * 0.5;

  &.mc-checkbox-wrap {
    // Allow wrapping in the label
    .mat-checkbox-layout {
      white-space: normal;
    }

    // Top align the checkbox's box with the checkbox's label
    .mat-checkbox-inner-container {
      margin-top: $mc-checkbox-top-aligned-offset;
    }
  }
}
