.mc-panel {
  display: block;
  border-radius: $border-radius-normal;
  padding: $spacing-small;
}

.mc-panel-large {
  border-radius: $border-radius-x-large;
  padding: $spacing-normal;
}
