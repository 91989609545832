/**
 * mc-image-icon
 * Gives an icon a width and height equal to its font-size.
 */
.mc-image-icon.mat-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
}

.mc-image-icon-xxxx-small {
  font-size: $font-size-image-icon-xxxx-small;
}

.mc-image-icon-xxx-small {
  font-size: $font-size-image-icon-xxx-small;
}

.mc-image-icon-xx-small {
  font-size: $font-size-image-icon-xx-small;
}

.mc-image-icon-x-small {
  font-size: $font-size-image-icon-x-small;
}

.mc-image-icon-normal {
  font-size: $font-size-image-icon-normal;
}

.mc-image-icon-large {
  font-size: $font-size-image-icon-large;
}

.mc-image-icon-x-large {
  font-size: $font-size-image-icon-x-large;
}

.mc-image-icon-xx-large {
  font-size: $font-size-image-icon-xx-large;
}

/**
 * mc-baseline-text-icon
 * Vertically aligns an icon with the baseline of text while letting the icon be its natural width and height.
 */
.mc-baseline-text-icon.mat-icon {
  display: inline;
}

/**
 * mc-centered-text-icon
 * Vertically centers an icon with text while letting the icon be its natural width and height.
 */
.mc-centered-text-icon.mat-icon {
  display: inline-block;
  width: auto;
  height: auto;
  vertical-align: middle;

  &:before {
    line-height: inherit;
  }
}

/**
 * mc-self-centered-icon
 * Vertically and horizontally centers the icon within itself.
 */
.mc-self-centered-icon.mat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
}
