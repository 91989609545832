$border-width-avatar: $border-width-thick !default;
$border-width-avatar-rem: $border-width-thick-rem !default;
$border-radius-avatar-square-outer: 8px !default;
$border-radius-avatar-square-inner: ($border-radius-avatar-square-outer - $border-width-avatar);

$size-avatar-x-small: 22px !default;
$size-avatar-small: 34px !default;
$size-avatar-normal: 50px !default;
$size-avatar-large: 66px !default;
$size-avatar-x-small-rem: 2.2rem !default;
$size-avatar-small-rem: 3.4rem !default;
$size-avatar-normal-rem: 5rem !default;
$size-avatar-large-rem: 6.6rem !default;

$font-size-avatar-icon-x-small: ($size-avatar-x-small - $border-width-avatar - $border-width-avatar) !default;
$font-size-avatar-icon-small: ($size-avatar-small - $border-width-avatar - $border-width-avatar) !default;
$font-size-avatar-icon-normal: ($size-avatar-normal - $border-width-avatar - $border-width-avatar) !default;
$font-size-avatar-icon-large: ($size-avatar-large - $border-width-avatar - $border-width-avatar) !default;

$font-size-avatar-icon-bordered-x-small: 8px !default;
$font-size-avatar-icon-bordered-small: 20px !default;
$font-size-avatar-icon-bordered-normal: 36px !default;
$font-size-avatar-icon-bordered-large: 52px !default;

$size-avatar-image-x-small: ($size-avatar-x-small - $border-width-avatar - $border-width-avatar) !default;
$size-avatar-image-small: ($size-avatar-small - $border-width-avatar - $border-width-avatar) !default;
$size-avatar-image-normal: ($size-avatar-normal - $border-width-avatar - $border-width-avatar) !default;
$size-avatar-image-large: ($size-avatar-large - $border-width-avatar - $border-width-avatar) !default;

$spacing-avatar-name: $spacing-small !default;
$spacing-avatar-name-small: $spacing-xx-small !default;

$size-avatar-icon-x-small-content-offset: 0.5rem;
$size-avatar-icon-small-content-offset: 0.85rem;
$size-avatar-icon-normal-content-offset: 1.1rem;
$size-avatar-icon-large-content-offset: 1.45rem;

/**
 * Styles the avatar's initials top position so as to be visually centered in the main part of the icon.
 * Calculates the position based on the avatar size.
 */
@mixin avatar-initials($offset, $font-size, $avatar-height) {
  top: ($offset + (($avatar-height - $offset) / 2) - ($font-size / 2));
  font-size: $font-size;
}
