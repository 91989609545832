.split-card.mat-card {
  padding: $spacing-none;

  .split-card-header,
  .split-card-header.mat-card-image,
  .mat-card-content {
    width: 100%;
    margin: $spacing-none;
    padding: $split-card-header-spacing;
  }

  > .mat-card-actions {
    &,
    &:last-child {
      margin: $spacing-none;
      padding: $spacing-none $split-card-header-spacing $split-card-header-spacing;
    }
  }
}

.mat-card {
  > .mc-loader,
  > .error-pane {
    border-radius: $mat-card-border-radius;
  }
}

/**
 * mat-card-actions
 */
.mat-card {
  .mat-card-actions {
    &.mc-card-actions-stretch {
      display: flex;
      width: 100%;

      > button {
        flex-grow: 1;
        margin-left: $button-between-spacing;

        &:first-child {
          margin-left: $spacing-none;
        }
      }
    }
  }
}
