@use '@angular/material' as mat;

@mixin mc-tasks-theme($theme, $typography) {
  /*
   * Task Calendars
   */
  .mc-task-calendar-low-priority.cal-event {
    background-color: mat.get-color-from-palette(map-get($theme, task-low-priority));
  }

  .mc-task-calendar-medium-priority.cal-event {
    background-color: mat.get-color-from-palette(map-get($theme, task-medium-priority));
  }

  .mc-task-calendar-high-priority.cal-event {
    background-color: mat.get-color-from-palette(map-get($theme, task-high-priority));
  }
}
