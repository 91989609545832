.mc-form-field {
  width: 100%;
}

/**
 * .mc-form-field-fill-height
 * Make a mat-form-field take up all the vertical space available to it.
 * This has only been tested with a textarea.
 */
.mc-form-field-fill-height {
  .mat-form-field-wrapper,
  .mat-form-field-flex,
  .mat-form-field-infix,
  .mat-input-element {
    height: 100%;
  }
}

/** Primarily for displaying errors under a field when not in a mat-form-field element */
.mc-form-field-subscript {
  font-size: $mat-form-field-subscript-font-size;
  margin-top: $mat-form-field-subscript-margin-top;
}

/**
 * .mc-form-field .mc-image-picker
 * Styles an mc-image-picker inside of an mc-form-field.
 */
.mc-form-field {
  .mc-image-picker {
    width: 100%;
  }

  .mc-image-picker + .mc-form-field-subscript {
    text-align: center;
  }
}

/**
 * .mc-form-field .mc-file-picker
 * Styles an mc-file-picker inside of an mc-form-field.
 */
.mc-form-field {
  .mc-file-picker {
    max-width: 100%;  // For truncating
  }
}

/**
 * .mc-form-field .mat-checkbox
 * Styles a mat-checkbox inside of an mc-form-field
 */
.mc-form-field {
  > .mat-checkbox {
    display: inline-block; // Needed to add a bottom margin
    margin-bottom: $spacing-form-controls;
    vertical-align: middle; // To align with mat-icon-button
  }

  > .mat-checkbox + .mat-icon-button {
    // Make the button the size of a line so that its the same size as the checkbox
    width: $computed-line-height-normal;
    height: $computed-line-height-normal;
    font-size: $computed-line-height-normal;
    line-height: $computed-line-height-normal;
    margin-bottom: $spacing-form-controls; // Give the button the same bottom margin as the checkbox so that they line up together
    margin-left: $text-space-width; // Put some space between the checkbox and the button
    vertical-align: middle; // To align with mat-checkbox
  }
}

/**
 * .mc-form-field .mat-selection-list
 * Styles a mat-selection-list inside of an mc-form-field including styles for mat-error
 */
.mc-form-field {
  .mat-selection-list + .mat-error,
  .mc-selection-list-empty + .mat-error,
  .mc-sticky-mat-error,
  .mc-absolute-mat-error {
    position: sticky;
    bottom: 0;
    // Swap the margin for padding so that the background covers the spacing
    margin-top: $spacing-none;
    padding-top: $mat-form-field-subscript-margin-top;
  }

  // Requires the parent form-field to have position: relative
  .mc-absolute-mat-error {
    position: absolute;
    width: 100%;
  }

  .mc-selection-list-empty {
    // Add some space between the top of the control and the subscript
    padding: $spacing-small $spacing-none;
  }
}

/**
 * .mc-form-field-type-button
 * Styles a mat-form-field that uses a button for its control (eg mc-file-picker)
 */
.mc-form-field-type-button {
  // The underline is not needed so hide it.
  .mat-form-field-underline {
    display: none;
  }

  .mat-button-base {
    max-width: 100%;  // For truncating
  }

  .mat-button-wrapper {
    display: block; // For truncating
    overflow-x: hidden; // For truncating
    text-overflow: ellipsis; // For truncating
  }

  // Fun with math
  $mc-form-field-type-button-label-top: calc-relative-length($font-size-normal-px, $mat-form-field-infix-margin-top) + calc-relative-length($font-size-normal-px, $mat-form-field-infix-padding) + ($mat-button-line-height-px * 0.5) - ($font-size-large-px * 1.125 * 0.5);
  $mc-form-field-type-button-label-left: $button-horizontal-spacing + $button-icon-width + $button-icon-text-spacing;

  &.mat-form-field-appearance-legacy .mat-form-field-label {
    top: $mc-form-field-type-button-label-top;
    left: $mc-form-field-type-button-label-left;
    font-size: $font-size-large;
  }

  &.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform-origin: 0 0;
    // Translate the x/y to adjust for the top/left above
    transform: translateX(-$mc-form-field-type-button-label-left) translateY(-$mc-form-field-type-button-label-top) scale(.75) perspective(100px) translateZ(.00101px);
  }
}

/**
 * .mc-form-field-appearance-outline-without-label
 * Styles a mat-form-field using the outline appearance to look better when it has no label.
 */
.mc-form-field-appearance-outline-without-label {
  &.mat-form-field-appearance-outline {
    $mat-form-field-outline-height: 4.5rem;

    // Remove the offset that was here for the label
    .mat-form-field-outline {
      top: 0;
    }

    // Remove the padding and center the input element using flexbox
    .mat-form-field-infix {
      display: inline-flex;
      align-items: center;
      height: $mat-form-field-outline-height;
      padding: $spacing-none;
      border-top: $border-none; // Remove the spacing for the label
    }
  }
}

.mc-form-field-inline {
  vertical-align: middle;
}

/* wrap a div around your form-fields that are in a row and it will cover the whole available width. evenly . */
.mc-form-fields-row {
  display: flex;

  .mat-form-field {
    flex-grow: 1;
    min-width: 0; // Keep the element from growing wider than its parent

    &:not(:last-child) {
      margin-right: $spacing-form-controls;
    }
  }
}

/**
 * .mc-form-field-container is used to help position elements around a form-field
 */
.mc-form-field-container {
  position: relative;

  .mc-form-field-progress-bar {
    // Position the progress-bar right over the form-field's underline
    position: absolute;
    top: 50px;
  }

  &.mc-form-field-avatar-container {
    .mc-form-field-progress-bar {
      // Position the progress-bar right over the form-field's underline when there is a small avatar in form field value
      top: 67px;
    }
  }
}
