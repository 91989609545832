.mc-dot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: $mc-dot-size;
  height: $mc-dot-size;
  border-radius: $mc-dot-size;
  padding: $spacing-none $mc-dot-horizontal-spacing;
}

.mc-dot-large {
  min-width: $mc-dot-size-large;
  height: $mc-dot-size-large;
}

.mc-dot-small {
  min-width: $mc-dot-size-small;
  height: $mc-dot-size-small;
}
