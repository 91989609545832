.mc-alert-card {
  width: $full-page-card-width;

  .mat-card-actions {
    text-align: center;

    button,
    .mat-button,
    .mat-raised-button {
      margin-left: $button-between-spacing;
    }
  }

  .alert-body {
    text-align: center;
  }
}

.alert-title,
.alert-title-x-large {
  font-size: $font-size-large;
  text-align: center;
  margin-bottom: $spacing-paragraph;
}

.alert-title-x-large {
  font-size: $font-size-x-large;
}

.alert-body {
  font-weight: $font-weight-light;
}

.mc-alert-text {
  margin-bottom: $spacing-paragraph;
}

.mc-alert-error {
  margin-top: $spacing-paragraph;
}

.mc-alert-button-wide {
  width: 100%;
}
