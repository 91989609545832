@use '@angular/material' as mat;
@mixin mc-panels-theme ($theme, $typography) {
  $foreground: map-get($theme, foreground);

  .mc-panel {
    @include _mc-panel($theme, 'primary');
    @include _mc-panel($theme, 'accent');
    @include _mc-panel($theme, 'warn');
    @include _mc-panel($theme, 'brand');
    @include _mc-panel($theme, 'secondary');
    @include _mc-panel($theme, 'notice');
    @include _mc-panel($theme, 'caution');
    @include _mc-panel($theme, 'info');
    @include _mc-panel($theme, 'info-2');
    @include _mc-panel($theme, 'link');
    @include _mc-panel($theme, 'build-cancel');
    @include _mc-panel($theme, 'build-fail');
    @include _mc-panel($theme, 'build-success');
    @include _mc-panel($theme, 'build-unknown');
    @include _mc-panel($theme, 'checklist-completed');
    @include _mc-panel($theme, 'checklist-in-progress');
    @include _mc-panel($theme, 'checklist-todo');
    @include _mc-panel($theme, 'report-broken-bookmarks');
    @include _mc-panel($theme, 'report-broken-links');
    @include _mc-panel($theme, 'report-broken-snippet-links');
    @include _mc-panel($theme, 'report-undefined-condition-tags');
    @include _mc-panel($theme, 'report-undefined-file-tags');
    @include _mc-panel($theme, 'report-undefined-glossary-terms');
    @include _mc-panel($theme, 'report-undefined-variables');
    @include _mc-panel($theme, 'report-images');
    @include _mc-panel($theme, 'report-segments');
    @include _mc-panel($theme, 'report-snippets');
    @include _mc-panel($theme, 'report-topics');
    @include _mc-panel($theme, 'report-words');
    @include _mc-panel($theme, 'task-low-priority');
    @include _mc-panel($theme, 'task-medium-priority');
    @include _mc-panel($theme, 'task-high-priority');
    @include _mc-panel($theme, 'task-analytics-todo');
    @include _mc-panel($theme, 'task-analytics-in-progress');
    @include _mc-panel($theme, 'task-analytics-complete');
    @include _mc-panel($theme, 'task-analytics-backlog');
    @include _mc-panel($theme, 'task-analytics-archive');
    @include _mc-panel($theme, 'usage-storage');
    @include _mc-panel($theme, 'usage-source-files');
    @include _mc-panel($theme, 'usage-builds');
    @include _mc-panel($theme, 'usage-tasks');
    @include _mc-panel($theme, 'usage-misc');
    @include _mc-panel($theme, 'usage-authors');
    @include _mc-panel($theme, 'usage-smes');
  }
}

@mixin _mc-panel ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  &.mc-#{$palette-name} {
    background-color: mat.get-color-from-palette($palette, default);
    color: mat.get-color-from-palette($palette, default-contrast);
  }

  &.mc-#{$palette-name}-muted {
    background-color: mat.get-color-from-palette($palette, lighter);
    color: mat.get-color-from-palette($palette, lighter-contrast);
  }

  &.mc-#{$palette-name}-vivid {
    background-color: mat.get-color-from-palette($palette, darker);
    color: mat.get-color-from-palette($palette, darker-contrast);
  }
}
