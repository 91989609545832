/*
 * widget
 */
.mc-widget {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mc-widget-header {
  flex-shrink: 0;

  .mat-toolbar {
    padding-left: $spacing-x-small;
    padding-right: $spacing-none;
  }
}

.mc-widget-body {
  flex-grow: 1;
  min-height: 0; // Keep it from growing beyond its parent
}

.mc-widget-body-unconfigured {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-none $spacing-small $spacing-small;
}
