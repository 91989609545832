.mc-task-button-no-style {
  position: relative;
  background-color: transparent;
  border: $spacing-none;
  padding: $spacing-none;

  &:focus {
    outline: none;
  }
}
