@use '@angular/material' as mat;
@mixin mc-trees-theme($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mc-tree.mat-tree {
    button.mc-tree-node-item {
      &:disabled {
        color: mat.get-color-from-palette($foreground, disabled);
      }

      &:focus,
      &:hover {
        background: mat.get-color-from-palette($background, list-item-hover-light);
      }
    }
  }
}
