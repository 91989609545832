/*
 * Creates the styles for bracketed elements in a text-editor
 */
@mixin mc-editor-brackets {
  padding-left: $editor-bracket-spacing;
  padding-right: $editor-bracket-spacing;
  border-left-width: $editor-bracket-width;
  border-left-style: solid;
  border-right-width: $editor-bracket-width;
  border-right-style: solid;
}

@mixin mc-editor-inline-brackets {
  margin-left: $editor-inline-bracket-outer-spacing;
  margin-right: $editor-inline-bracket-outer-spacing;
}

@mixin mc-editor-name-box {
  display: inline-block;
  margin: $editor-name-box-margin;
  padding: $spacing-xxx-small;
  border-radius: $border-radius-normal;
  word-break: break-all;
  word-break: break-word;
  font-size: $editor-name-box-font-size;
  white-space: normal;
  font-weight: $default-font-weight;
  font-style: normal;
  font-family: unquote($default-font-family);
}

@mixin mc-editor-block-name-box {
  display: block;
  margin: $spacing-none $spacing-none $editor-block-name-box-margin-bottom;
}

@mixin mc-editor-star {
  &:before {
    font-size: $editor-star-font-size;
    vertical-align: super;
    margin-right: $editor-star-spacing;
  }
}

@mixin mc-editor-bookmark {
  &:before {
    font-size: $editor-bookmark-font-size;
    vertical-align: middle;
  }
}