/*
 * The Box Model
 * This file defines an element's spacing and border.
 *   ..............................................
 *   :                   Margin                   :
 *   :    ---------------Border---------------    :
 *   :    |              Padding             |    :
 *   :    |                                  |    :
 *   :    |                                  |    :
 *   :    |                                  |    :
 *   :    |                                  |    :
 *   :    ------------------------------------    :
 *   :                                            :
 *   :.............................................
 */

// Spacing (margin and padding)
$spacing-none: 0 !default;
$spacing-auto: auto !default;
$spacing-xxxx-small: .2rem !default;
$spacing-xxx-small: .5rem !default;
$spacing-xx-small: 1rem !default;
$spacing-x-small: 1.2rem !default;
$spacing-small: 1.6rem !default;
$spacing-normal: 2.2rem !default;
$spacing-large: 3.6rem !default;
$spacing-x-large: 5.2rem !default;
$spacing-xx-large: 7.4rem !default;

$spacing-paragraph: $spacing-x-small !default;
$spacing-form-controls: $spacing-x-small !default;

// Border Widths
$border-none: 0 !default;
$border-width-none: $border-none !default;
$border-width-thin: 1px !default;
$border-width-thin-rem: .1rem !default;
$border-width: 1px !default;
$border-width-rem: .1rem !default;
$border-width-thick: 2px !default;
$border-width-thick-rem: .2rem !default;

// Border Radius
$border-radius-none: 0 !default;
$border-radius-normal: 2px !default;
$border-radius-large: 3px !default;
$border-radius-x-large: 4px !default;
$border-radius-circle: 50% !default;

// Outline
$outline-none: 0 !default;
