@use '@angular/material' as mat;
@mixin mc-expansion-panels-theme ($theme, $typography) {
  $background: map-get($theme, background);

  .mc-expansion-panel-header {
    &:hover,
    &:focus {
      background: mat.get-color-from-palette($background, hover);
      outline: none;
    }
  }
}
