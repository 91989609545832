@use '@angular/material' as mat;
@mixin mc-mat-steppers-theme($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-stepper-horizontal {
    @include _mc-mat-stepper($theme, 'secondary', 'primary', 'default', 'primary', 'default', 'default-contrast', false);
    @include _mc-mat-stepper($theme, 'secondary-invert', 'secondary', 'default', 'primary', 'darker', 'lighter-contrast', true);
  }

  /**
   * mc-stepper-arrow
   * Adds arrow indicators under the selected step in a mat-stepper-horizontal.
   */
  .mc-stepper-arrow {
    &.mat-stepper-horizontal {
      .mat-step-header[aria-selected='true']:after {
        // Default to the card background color as this is the most common use case
        background-color: mat.get-color-from-palette($background, card);
      }
    }
  }

  // Displays arrows tabs with a bottom border
  .mc-stepper-arrow-border {
    &.mat-stepper-horizontal {
      // The border under the step labels
      .mat-horizontal-stepper-header-container:after {
        border-bottom: solid 1px map-get($foreground, divider);
      }

      // The border on the arrow indicator
      .mat-step-header[aria-selected='true']:after {
        border-top-color: map-get($foreground, divider);
        border-left-color: map-get($foreground, divider);
      }
    }
  }
}

@mixin _mc-mat-stepper($theme, $stepper-bg-palette-name, $stepper-fg-palette-name, $stepper-fg-hue, $stepper-fg-active-palette-name, $stepper-fg-active-hue, $stepper-optional-label-hue, $show-arrow-border) {
  $bg-palette: map-get($theme, $stepper-bg-palette-name);
  $fg-palette: map-get($theme, $stepper-fg-palette-name);
  $fg-active-palette: map-get($theme, $stepper-fg-active-palette-name);

  &.mat-#{$stepper-bg-palette-name} {
    &.mc-stepper-arrow {
      @if $show-arrow-border {
        @extend .mc-stepper-arrow-border;
      }
    }

    .mat-horizontal-stepper-header-container {
      background: mat.get-color-from-palette($bg-palette, default);
    }

    .mat-step-optional {
      color: mat.get-color-from-palette($bg-palette, $stepper-optional-label-hue);
    }

    .mat-step-label {
      color: mat.get-color-from-palette($fg-palette, $stepper-fg-hue);
    }

    .mat-step-label.mat-step-label-selected {
      color: mat.get-color-from-palette($fg-active-palette, $stepper-fg-active-hue);
    }

    .mat-step-header .mat-step-icon {
      background-color: mat.get-color-from-palette($fg-palette, $stepper-fg-hue);
      color: mat.get-color-from-palette($bg-palette, $stepper-fg-hue);
    }

    .mat-step-header .mat-step-icon.mat-step-icon-selected {
      background-color: mat.get-color-from-palette($fg-active-palette, $stepper-fg-active-hue);
      color: mat.get-color-from-palette($bg-palette, $stepper-fg-active-hue);
    }

    .mat-step-header.cdk-keyboard-focused,
    .mat-step-header:hover {
      background-color: lighten(mat.get-color-from-palette($bg-palette, default), 8%);
    }

    .mat-step-header.cdk-program-focused {
      background-color: transparent;
    }
  }
}
