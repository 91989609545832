@import 'mixins/index';

/**
 * Vertically split dialog
 */
.mc-vertical-split-dialog {
  position: relative;

  .mat-dialog-title {
    position: relative;
    margin-bottom: $dialog-container-padding;
    padding: $dialog-container-padding;
    text-align: center;
  }

  .mat-dialog-content {
    margin: $spacing-none;
    padding: $spacing-none $dialog-container-padding;
  }

  .mat-dialog-actions {
    margin: $spacing-none;
    padding: $dialog-container-padding;

    button:not(:first-child) {
      margin-left: $button-between-spacing;
    }
  }

  .mc-vertical-split-dialog-close-button {
    position: absolute;
    top: $dialog-close-button-offset;
    right: $dialog-close-button-offset;
    z-index: 2;
  }

  .mc-vertical-split-dialog-title {
    margin-bottom: $spacing-normal;
    font-size: $font-size-large;
    text-align: center;
  }

  .mc-vertical-split-dialog-body {
    margin-bottom: $spacing-large;
    font-weight: $font-weight-light;
  }

  .mc-vertical-split-dialog-body-centered {
    text-align: center;
  }
}
