@import 'pages';

@media (max-width: $full-page-card-width-px) {
  .mc-admin-login-card,
  .mc-complete-invite-card,
  .mc-expired-password-card,
  .mc-forgot-password-card,
  .mc-license-list-card,
  .mc-license-url-card,
  .mc-login-card,
  .mc-reset-password-card,
  .mc-sso-login-card {
    .mat-card {
      max-width: 100vw;
      border-radius: $border-radius-none;
    }
  }
}

// The min-width breakpoint for the editor pages is basically the sum of all the page parts plus the max-width of the editor card
// This should equal 213.5rem or 2135px with a 130rem wide editor card
$min-width-breakpoint-rem: $app-nav-width + $page-spacing + $page-minor-card-width + $page-spacing + $page-fill-editor-major-card-max-width + $page-gutter-margin + $page-gutter-width + $page-spacing;
$min-width-breakpoint: rem-to-px($min-width-breakpoint-rem);

/**
 * Reviews Page and Project Files Page Layout
 * Once the page is wide enough to fit the editor card at its max-width, let the explorer grow to fill the space.
 */
@media (min-width: $min-width-breakpoint) {
  mc-project-files-page,
  mc-reviews-page {
    // The reviews explorer and project file explorer should have a max-width because they look ridiculous when they get too wide.
    .page-card.page-card-minor {
      flex-grow: 1;
      max-width: $page-fill-editor-minor-card-max-width;
    }

    // The editor card gets a fixed width so that it doesn't get too big. If it gets too wide it becomes harder to read from line to line.
    .page-card.page-card-major {
      width: $page-fill-editor-major-card-max-width;
      max-width: $page-fill-editor-major-card-max-width;
    }
  }
}
