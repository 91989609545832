.mc-expansion-panel-header {
  display: flex;
  align-items: center;
  height: 48px;
  padding: $spacing-none $mc-expansion-panel-spacing;
  cursor: pointer;

  .icon-expand,
  .icon-collapse {
    margin-left: $mc-expansion-panel-spacing;
  }
}

.mc-expansion-panel-content {
  visibility: hidden;
  height: 0;
  overflow: hidden;
  padding: $spacing-none $mc-expansion-panel-spacing;
}

.mc-expansion-panel-indicator.mat-icon {
  transition: transform $mc-expansion-indicator-animation-timing;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.mc-expansion-panel.mc-expansion-panel-expanded {
  .mc-expansion-panel-content {
    visibility: visible;
    height: auto;
    overflow: visible;
  }

  .mc-expansion-panel-indicator.mat-icon {
    transform-origin: center;
    transform: rotateZ(180deg);
  }
}
