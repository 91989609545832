@use '@angular/material' as mat;

@mixin mc-color-picker-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $warn: map-get($theme, warn);

  .mc-color-collection-picker-transparent {
    border-bottom: $border-width solid mat.get-color-from-palette($warn);
  }

  .mc-color-toggle-picker-transparent {
    &::after {
      border-bottom: $border-width solid mat.get-color-from-palette($warn);
    }
  }

  .mc-color-toggle-button {
    border: $border-width solid mat.get-color-from-palette($foreground, divider);
  }

  .mc-color-toggle-button:not(:disabled) {

    &:hover,
    &:focus {
      outline: $border-width-thick solid mat.get-color-from-palette($foreground, divider);
      border: none;
    }
  }
}