@use '@angular/material' as mat;

@mixin mc-typography-theme ($theme, $typography) {
  $link: map-get($theme, link);
  $foreground: map-get($theme, foreground);

  a,
  .mc-link {
    color: mat.get-color-from-palette($link);
  }

  /*
   * Utility classes to help color text. !important is used to ensure they always override the color.
   */
  .link-muted {
    color: mat.get-color-from-palette($foreground, link-muted) !important;
  }

  .mc-text-disabled {
    color: mat.get-color-from-palette($foreground, disabled) !important;
  }

  .mc-text-divider {
    color: mat.get-color-from-palette($foreground, divider) !important;
  }

  .mc-text-invert {
    color: mat.get-color-from-palette($foreground, text-invert) !important;
  }

  .mc-text-list-subheader {
    color: mat.get-color-from-palette($foreground, list-subheader) !important;
  }

  .mc-text-muted {
    color: mat.get-color-from-palette($foreground, text-muted) !important;
  }

  .mc-text-normal {
    color: mat.get-color-from-palette($foreground, text) !important;
  }

  .mc-text-inherit {
    color: inherit !important;
  }

  @include _mc-text($theme, 'primary');
  @include _mc-text($theme, 'accent');
  @include _mc-text($theme, 'warn');
  @include _mc-text($theme, 'brand');
  @include _mc-text($theme, 'secondary');
  @include _mc-text($theme, 'secondary-invert');
  @include _mc-text($theme, 'notice');
  @include _mc-text($theme, 'caution');
  @include _mc-text($theme, 'checklist-completed');
  @include _mc-text($theme, 'checklist-in-progress');
  @include _mc-text($theme, 'checklist-todo');
  @include _mc-text($theme, 'checklist-not-started');
  @include _mc-text($theme, 'info');
  @include _mc-text($theme, 'info-2');
  @include _mc-text($theme, 'link');
  @include _mc-text($theme, 'basic');
  @include _mc-text($theme, 'build-cancel');
  @include _mc-text($theme, 'build-fail');
  @include _mc-text($theme, 'build-success');
  @include _mc-text($theme, 'build-unknown');
  @include _mc-text($theme, 'mad-translations');
  @include _mc-text($theme, 'report-broken-bookmarks');
  @include _mc-text($theme, 'report-broken-links');
  @include _mc-text($theme, 'report-broken-snippet-links');
  @include _mc-text($theme, 'report-undefined-condition-tags');
  @include _mc-text($theme, 'report-undefined-file-tags');
  @include _mc-text($theme, 'report-undefined-glossary-terms');
  @include _mc-text($theme, 'report-undefined-variables');
  @include _mc-text($theme, 'report-images');
  @include _mc-text($theme, 'report-segments');
  @include _mc-text($theme, 'report-snippets');
  @include _mc-text($theme, 'report-topics');
  @include _mc-text($theme, 'report-words');
  @include _mc-text($theme, 'task-analytics-complete');
  @include _mc-text($theme, 'task-low-priority');
  @include _mc-text($theme, 'task-medium-priority');
  @include _mc-text($theme, 'task-high-priority');
  @include _mc-text($theme, 'usage-storage');
  @include _mc-text($theme, 'usage-source-files');
  @include _mc-text($theme, 'usage-builds');
  @include _mc-text($theme, 'usage-tasks');
  @include _mc-text($theme, 'usage-misc');
  @include _mc-text($theme, 'usage-authors');
  @include _mc-text($theme, 'usage-smes');
}

@mixin _mc-text ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  .mc-text-#{$palette-name} {
    color: mat.get-color-from-palette($palette, default) !important;
  }

  .mc-text-#{$palette-name}-muted {
    color: mat.get-color-from-palette($palette, lighter) !important;
  }

  .mc-text-#{$palette-name}-vivid {
    color: mat.get-color-from-palette($palette, darker) !important;
  }
}
