.mc-material {
  .mat-tree {
    ul,
    li {
      margin-top: $spacing-none;
      margin-bottom: $spacing-none;
      list-style-type: none;
    }

    .invisible {
      display: none;
    }

    .mat-tree-node {
      a.mat-menu-item {
        text-decoration: none;
        width: 100%;
      }
    }
  }
}
