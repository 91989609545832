/*
 * This file does not define the values used by Angular Material
 * But instead enumerates the values used by Angular Material
 * So that we can use them for our own SCSS
 */
@use "sass:math";

$mat-checkbox-height: 1.6rem; // 16px
$mat-checkbox-width: 1.6rem; // 16px
$mat-radio-button-height: 2rem; // 20px

$mat-list-icon-width: 2.4rem; // 24px
$mat-list-icon-padding: .4rem; // 4px
$mat-list-icon-padding-px: 4px;
$mat-list-text-left-padding: 1.6rem; // 16px

$mat-snackbar-bg-color: #323232; // Material has this hard-coded
$mat-snackbar-color: rgba(#fff, .7); // Material only defines this variable within _theming.scss

$mat-form-field-subscript-font-size: 75%;

$mat-menu-item-height: 48px;

$mat-option-horizontal-padding: 1.6rem; // 16px

$mat-paginator-min-height: 56px;
$mat-paginator-padding: 0 8px;
$mat-paginator-page-size-margin-right: 8px;
$mat-paginator-page-size-label-margin: 0 4px;
$mat-paginator-page-size-select-form-field-margin: 6px 4px 0 4px;
$mat-paginator-page-size-select-form-field-width: 56px;
$mat-paginator-range-label-margin: 0 32px 0 24px;

$mat-card-border-radius: 4px;

$mat-icon-height: 2.4rem; // 24px
$mat-icon-width: 2.4rem; // 24px

$mat-stepper-horizontal-header-height: 7.2rem; // 72px
$mat-stepper-horizontal-header-horizontal-padding: 2.4rem; // 24px

/*
 * mat-button
 */
$mat-button-line-height: 3.6rem; // 36px
$mat-button-line-height-px: 36px;

/*
 * mat-icon-button
 */
$mat-icon-button-height: 40px;
$mat-icon-button-width: 40px;
$mat-icon-button-line-height: 40px;
$mat-icon-button-icon-line-height: 24px;

/*
 * mat-chip
 */
$mat-chip-list-chip-margin: .4rem;
$mat-chip-list-chip-margin-px: 4px;
$mat-standard-chip-height: 32px;

/*
 * mat-expansion-panel-body
 */
$mat-arrow-icon-right-padding: 1.7rem;

/*
 * Taken from Material's core/style/_variables.scss
 */
$ease-in-out-curve-function: cubic-bezier(.35, 0, .25, 1) !default;

$swift-ease-out-duration: 400ms !default;
$swift-ease-out-timing-function: cubic-bezier(.25, .8, .25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 300ms !default;
$swift-ease-in-timing-function: cubic-bezier(.55, 0, .55, .2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 500ms !default;
$swift-ease-in-out-timing-function: $ease-in-out-curve-function !default;
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function !default;

$swift-linear-duration: 80ms !default;
$swift-linear-timing-function: linear !default;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function !default;

/*
 * Taken from mat-form-field-legacy-typography() in Material's _theming.scss
 */
// The unit-less line-height from the font config.
// TODO: this variable's value should come from the theme's typography config
$mat-input-line-height: 1.125; // mat-line-height($config, input);
// The amount to scale the font for the floating label and subscript.
$mat-form-field-subscript-scale: .75;
// The amount of space between the top of the line and the top of the actual text
// (as a fraction of the font-size).
$mat-form-field-line-spacing: (
  $mat-input-line-height - 1) * 0.5;
// The padding on the infix. Mocks show half of the text size, but seem to measure from the edge
// of the text itself, not the edge of the line; therefore we subtract off the line spacing.
$mat-form-field-infix-padding: .5em - $mat-form-field-line-spacing;
// The margin applied to the form-field-infix to reserve space for the floating label.
$mat-form-field-infix-margin-top: 1em * $mat-input-line-height * $mat-form-field-subscript-scale;
// The space between the bottom of the .mat-form-field-flex area and the subscript wrapper.
// Mocks show half of the text size, but this margin is applied to an element with the subscript
// text font size, so we need to divide by the scale factor to make it half of the original text
// size. We again need to subtract off the line spacing since the mocks measure to the edge of the
// text, not the  edge of the line.
$mat-form-field-subscript-margin-top: math.div(.5em, $mat-form-field-subscript-scale) - ($mat-form-field-line-spacing * 2
);
// The padding applied to the form-field-wrapper to reserve space for the subscript, since it's
// absolutely positioned. This is a combination of the subscript's margin and line-height, but we
// need to multiply by the subscript font scale factor since the wrapper has a larger font size.
$mat-form-field-wrapper-padding-bottom: (
  $mat-form-field-subscript-margin-top + $mat-input-line-height) * $mat-form-field-subscript-scale;

// <input> elements seem to have their height set slightly too large on Safari causing the text to
// be misaligned w.r.t. the placeholder. Adding this margin corrects it.
$mat-input-element-margin-top: -$mat-form-field-line-spacing * 1em;

// Used to make instances of the _mat-form-field-label-floating mixin negligibly different,
// and prevent Google's CSS Optimizer from collapsing the declarations. This is needed because some
// of the selectors contain pseudo-classes not recognized in all browsers. If a browser encounters
// an unknown pseudo-class it will discard the entire rule set.
$mat-form-field-legacy-dedupe: .00001;

// We use perspective to fix the text blurriness as described here:
// http://www.useragentman.com/blog/2014/05/04/fixing-typography-inside-of-2-d-css-transforms/
// This results in a small jitter after the label floats on Firefox, which the
// translateZ fixes.
$mat-form-field-label-floating-transform: translateY(-$mat-form-field-infix-margin-top - $mat-form-field-infix-padding + $mat-form-field-legacy-dedupe) scale($mat-form-field-subscript-scale) perspective(100px) translateZ(.001px
);
$mat-form-field-label-transition: transform $swift-ease-out-duration $swift-ease-out-timing-function, color $swift-ease-out-duration $swift-ease-out-timing-function, width $swift-ease-out-duration $swift-ease-out-timing-function;