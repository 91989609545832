@use '@angular/material' as mat;
@mixin mc-accordions-theme ($theme, $typography) {
  $background: map-get($theme, background);

  .accordion-flat {
    .mat-expansion-panel {
      &:not([class*='mat-elevation-z']) {
        box-shadow: none;
      }
    }
  }

  .accordion-transparent {
    .mat-expansion-panel {
      background: transparent;
    }
  }

  /**
   * mc-accordion-list
   * Styles an accordion for displaying lists as its content with the panel header looking like a list section header.
   */
  .mc-accordion-list {
    .mat-expansion-panel {
      .mat-expansion-panel-header,
      .mat-expansion-panel-header:focus
      .mat-expansion-panel-header:hover,
      &:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
        background-color: mat.get-color-from-palette($background, toolbar);
      }
    }
  }
}
