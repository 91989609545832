@use '@angular/material' as mat;
@mixin mc-mat-buttons-theme($theme, $typography) {
  // Theme material's buttons that have no background with our custom palettes
  .mat-button,
  .mat-icon-button,
  .mat-stroked-button {
    @include _mc-mat-button-no-bg($theme, 'secondary');
    @include _mc-mat-button-no-bg($theme, 'secondary-invert');
    @include _mc-mat-button-no-bg($theme, 'notice');
    @include _mc-mat-button-no-bg($theme, 'caution');
    @include _mc-mat-button-no-bg($theme, 'info');
    @include _mc-mat-button-no-bg($theme, 'info-2');
    @include _mc-mat-button-no-bg($theme, 'link');
    @include _mc-mat-button-no-bg-varieties($theme, 'primary');
  }

  // Theme material's buttons that have a background with our custom palettes
  .mat-flat-button,
  .mat-raised-button,
  .mat-fab,
  .mat-mini-fab {
    @include _mc-mat-button($theme, 'secondary');
    @include _mc-mat-button($theme, 'secondary-invert');
    @include _mc-mat-button($theme, 'notice');
    @include _mc-mat-button($theme, 'caution');
    @include _mc-mat-button($theme, 'info');
    @include _mc-mat-button($theme, 'info-2');
    @include _mc-mat-button($theme, 'link');
    @include _mc-mat-button($theme, 'basic');
    @include _mc-mat-button-varieties($theme, 'primary');
    @include _mc-mat-button-varieties($theme, 'basic');
  }
}

/*
 * A mixin to theme material buttons with our own palettes. Themes buttons that have no background.
 */
@mixin _mc-mat-button-no-bg($theme, $button-name) {
  $foreground: map-get($theme, foreground);
  $palette: map-get($theme, $button-name);

  // Taken from _mat-button-theme-property mixin
  &.mat-#{$button-name} {
    color: mat.get-color-from-palette($palette, default);
  }

  &.mat-#{$button-name},
  &[disabled] {
    &[disabled] {
      color: mat.get-color-from-palette($foreground, disabled-button);
    }
  }

  // Taken from _mat-button-focus-overlay-color mixin
  &.mat-#{$button-name} .mat-button-focus-overlay {
    background-color: mat.get-color-from-palette($palette, default);
  }

  &[disabled] .mat-button-focus-overlay {
    background-color: transparent;
  }
}

/*
 * A mixin to theme material buttons with muted and vivid palettes. Themes buttons that have no background.
 */
 @mixin _mc-mat-button-no-bg-varieties($theme, $palette-name) {
  $foreground: map-get($theme, foreground);
  $palette: map-get($theme, $palette-name);

  /*
   * muted
   */
  // Taken from _mat-button-theme-property mixin
  &.mat-#{$palette-name}-muted {
    color: mat.get-color-from-palette($palette, lighter);
  }

  &.mat-#{$palette-name}-muted,
  &[disabled] {
    &[disabled] {
      color: mat.get-color-from-palette($foreground, disabled-button);
    }
  }

  // Taken from _mat-button-focus-overlay-color mixin
  &.mat-#{$palette-name}-muted .mat-button-focus-overlay {
    background-color: mat.get-color-from-palette($palette, lighter);
  }

  &[disabled] .mat-button-focus-overlay {
    background-color: transparent;
  }

  /*
   * vivid
   */
  // Taken from _mat-button-theme-property mixin
  &.mat-#{$palette-name}-vivid {
    color: mat.get-color-from-palette($palette, darker);
  }

  &.mat-#{$palette-name}-vivid,
  &[disabled] {
    &[disabled] {
      color: mat.get-color-from-palette($foreground, disabled-button);
    }
  }

  // Taken from _mat-button-focus-overlay-color mixin
  &.mat-#{$palette-name}-vivid .mat-button-focus-overlay {
    background-color: mat.get-color-from-palette($palette, darker);
  }

  &[disabled] .mat-button-focus-overlay {
    background-color: transparent;
  }
}

/*
 * A mixin to theme material buttons with our own palettes.
 */
@mixin _mc-mat-button($theme, $palette-name) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $palette: map-get($theme, $palette-name);

  // Taken from _mat-button-theme-property mixin
  &.mat-#{$palette-name} {
    background-color: mat.get-color-from-palette($palette, default);
    color: mat.get-color-from-palette($palette, default-contrast);
  }

  &.mat-#{$palette-name},
  &[disabled] {
    &[disabled] {
      background-color: mat.get-color-from-palette($background, disabled-button);
      color: mat.get-color-from-palette($foreground, disabled-button);
    }
  }

  // Taken from _mat-button-ripple-color mixin
  &.mat-#{$palette-name} .mat-ripple-element {
    background-color: mat.get-color-from-palette($palette, default-contrast, .1);
  }
}

/*
 * A mixin to theme material buttons with muted and vivid palettes
 */
@mixin _mc-mat-button-varieties($theme, $palette-name) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $palette: map-get($theme, $palette-name);

  /*
   * muted
   */
  // Taken from _mat-button-theme-property mixin
  &.mat-#{$palette-name}-muted {
    background-color: mat.get-color-from-palette($palette, lighter);
    color: mat.get-color-from-palette($palette, lighter-contrast);
  }

  &.mat-#{$palette-name}-muted,
  &[disabled] {
    &[disabled] {
      background-color: mat.get-color-from-palette($background, disabled-button);
      color: mat.get-color-from-palette($foreground, disabled-button);
    }
  }

  // Taken from _mat-button-ripple-color mixin
  &.mat-#{$palette-name}-muted .mat-ripple-element {
    background-color: mat.get-color-from-palette($palette, lighter-contrast, .1);
  }

  /*
   * vivid
   */
  // Taken from _mat-button-theme-property mixin
  &.mat-#{$palette-name}-vivid {
    background-color: mat.get-color-from-palette($palette, darker);
    color: mat.get-color-from-palette($palette, darker-contrast);
  }

  &.mat-#{$palette-name}-vivid,
  &[disabled] {
    &[disabled] {
      background-color: mat.get-color-from-palette($background, disabled-button);
      color: mat.get-color-from-palette($foreground, disabled-button);
    }
  }

  // Taken from _mat-button-ripple-color mixin
  &.mat-#{$palette-name}-vivid .mat-ripple-element {
    background-color: mat.get-color-from-palette($palette, darker-contrast, .1);
  }
}
