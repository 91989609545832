@use '@angular/material' as mat;
@mixin mc-system-messages-theme($theme, $typography) {
  $foreground: mat.get-color-from-palette(map-get($theme, secondary-invert));
  $whats-new-color: mat.get-color-from-palette(map-get($theme, warn));
  $system-message-color: mat.get-color-from-palette(map-get($theme, caution));

  mc-system-message-card {
    color: $foreground;
  }

  .mc-system-message-card {
    background: $system-message-color;
  }

  .mc-whats-new-card {
    background: $whats-new-color;
  }

  .mc-system-message-link {
    color: $system-message-color;
  }

  .mc-whats-new-link {
    color: $whats-new-color;
  }
}
