.mc-avatar-base {
  display: inline-flex;
  overflow: hidden;
}

.mc-avatar-container {
  flex-shrink: 0;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-none;
  border-style: solid;
  border-width: $border-width-avatar;
  border-radius: $border-radius-circle;

  &:disabled {
    cursor: unset; // Use unset so that the cursor can match the ancestor for cases like selectable list items
  }
}

.mc-avatar-square {
  .mc-avatar-container {
    border-radius: $border-radius-avatar-square-outer;
  }
}

.mc-avatar-container + .mc-avatar-name {
  margin-left: $spacing-avatar-name;
}

.mc-avatar-base[compact]:not(.mc-avatar-inline) {
  .mc-avatar-container + .mc-avatar-name {
    margin-left: $spacing-avatar-name-small;
  }
}

.mc-avatar-tooltip-container {
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  max-width: 100%;
}

/**
 * mc-avatar-icon
 * For avatars that are rendered with an icon.
 */
.mc-avatar-icon {
  &.mat-icon {
    width: 1em;
    height: 1em;
  }
}

/**
 * mc-avatar-image
 * For avatars that are rendered with an image.
 */
.mc-avatar-image {
  border-radius: $border-radius-circle;
}

.mc-avatar-square {
  .mc-avatar-image {
    border-radius: $border-radius-avatar-square-inner;
  }
}

.mc-avatar-name {
  // Wrap the name
  word-break: break-all;
}

.mc-avatar-truncate-name {
  .mc-avatar-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/**
 * mc-avatar-has-svg
 */
.mc-avatar-has-svg {
  // Empty avatar
  &.mc-avatar-empty {
    // Hide the icon while there is no entity so that the colors don't change causing a flash of wrong content
    .mc-avatar-svg {
      visibility: hidden;
    }

    // Apply a border-radius to the empty avatar
    .mc-avatar-container {
      border-radius: $border-radius-normal;
    }
  }

  // Remove the border and border-radius from mc-avatar-container since this avatar isn't round
  .mc-avatar-container {
    border-radius: $border-radius-none;
    border-width: $border-width-none;
  }

  // Make the svg fill all the space available to it
  .mc-avatar-svg {
    width: 100%;
    stroke-width: 2%;
  }

  // Center the initials horizontally
  .mc-avatar-initials {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1;
  }
}

/**
 * mc-avatar-inline
 */
.mc-avatar-inline {
  display: inline;

  &.mc-avatar-truncate-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Align the content with other text
  .mc-avatar-tooltip-container {
    display: inline;
  }

  // Align the image with text
  .mc-avatar-image {
    vertical-align: top;
  }

  // Remove the margin from the name
  .mc-avatar-name {
    margin-left: $spacing-none;
  }
}

/**
 * mc-avatar-empty
 * The styles applied to an avatar when it has no entity value.
 */
.mc-avatar-empty {
  .mc-avatar-initials {
    display: none;
  }
}

/**
 * mc-avatar-name-disabled
 * The styles applied to an avatar when the name is disabled (not clickable.)
 */
.mc-avatar-name-disabled {
  .mc-avatar-name {
    cursor: default;
  }
}

/**
 * mc-avatar-indicator
 * The icons that orbit around the outer edge of the mc-avatar-container
 */
.mc-avatar-base {
  .mc-avatar-indicator-icon {
    // Add a circular border
    border: solid $border-width;
    border-radius: 50%;
    // Because the border is added to the icon, the icon size needs to apply to the content-box
    box-sizing: content-box;
  }

  .mc-avatar-indicator-top-left,
  .mc-avatar-indicator-top-right,
  .mc-avatar-indicator-bottom-right,
  .mc-avatar-indicator-bottom-left {
    &.mat-icon {
      position: absolute;
      width: 1em;
      height: 1em;
      margin: $spacing-none; // To override the styles applied by mat-button when an avatar is inside a mat-button
      line-height: 1; // To override the styles applied by mat-button when an avatar is inside a mat-button
    }
  }

  .mc-avatar-indicator-top-left {
    top: (-$border-width-avatar);
    left: (-$border-width-avatar);
  }

  .mc-avatar-indicator-top-right {
    top: (-$border-width-avatar);
    right: (-$border-width-avatar);
  }

  .mc-avatar-indicator-bottom-right {
    bottom: (-$border-width-avatar);
    right: (-$border-width-avatar);
  }

  .mc-avatar-indicator-bottom-left {
    bottom: (-$border-width-avatar);
    left: (-$border-width-avatar);
  }
}

/**
 * mc-avatar-base size classes
 */
.mc-avatar-x-small {
  .mc-avatar-container {
    width: $size-avatar-x-small;
    height: $size-avatar-x-small;
  }

  .mc-avatar-image {
    width: $size-avatar-image-x-small;
    height: $size-avatar-image-x-small;
  }

  .mc-avatar-icon {
    font-size: $font-size-avatar-icon-x-small;

    &.mc-avatar-icon-bordered {
      font-size: $font-size-avatar-icon-bordered-x-small;
    }
  }

  .mc-avatar-initials {
    font-size: $font-size-xx-small;
  }

  .mc-avatar-indicator-top-left,
  .mc-avatar-indicator-top-right,
  .mc-avatar-indicator-bottom-right,
  .mc-avatar-indicator-bottom-left {
    font-size: $font-size-x-small;
  }

  .mc-avatar-indicator-icon {
    font-size: $font-size-small;
  }
}

.mc-avatar-small {
  .mc-avatar-container {
    width: $size-avatar-small;
    height: $size-avatar-small;
  }

  .mc-avatar-image {
    width: $size-avatar-image-small;
    height: $size-avatar-image-small;
  }

  .mc-avatar-icon {
    font-size: $font-size-avatar-icon-small;

    &.mc-avatar-icon-bordered {
      font-size: $font-size-avatar-icon-bordered-small;
    }
  }

  .mc-avatar-initials {
    font-size: $font-size-small;
  }

  .mc-avatar-indicator-top-left,
  .mc-avatar-indicator-top-right,
  .mc-avatar-indicator-bottom-right,
  .mc-avatar-indicator-bottom-left {
    font-size: $font-size-small;
  }

  .mc-avatar-indicator-icon {
    font-size: $font-size-normal;
  }
}

.mc-avatar-normal {
  .mc-avatar-container {
    width: $size-avatar-normal;
    height: $size-avatar-normal;
  }

  .mc-avatar-image {
    width: $size-avatar-image-normal;
    height: $size-avatar-image-normal;
  }

  .mc-avatar-icon {
    font-size: $font-size-avatar-icon-normal;

    &.mc-avatar-icon-bordered {
      font-size: $font-size-avatar-icon-bordered-normal;
    }
  }

  .mc-avatar-initials {
    font-size: $font-size-x-large;
  }

  .mc-avatar-indicator-top-left,
  .mc-avatar-indicator-top-right,
  .mc-avatar-indicator-bottom-right,
  .mc-avatar-indicator-bottom-left {
    font-size: $font-size-normal;
  }
}

.mc-avatar-large {
  .mc-avatar-container {
    width: $size-avatar-large;
    height: $size-avatar-large;
  }

  .mc-avatar-image {
    width: $size-avatar-image-large;
    height: $size-avatar-image-large;
  }

  .mc-avatar-icon {
    font-size: $font-size-avatar-icon-large;

    &.mc-avatar-icon-bordered {
      font-size: $font-size-avatar-icon-bordered-large;
    }
  }

  .mc-avatar-initials {
    font-size: $font-size-xxx-large;
  }

  .mc-avatar-indicator-top-left,
  .mc-avatar-indicator-top-right,
  .mc-avatar-indicator-bottom-right,
  .mc-avatar-indicator-bottom-left {
    font-size: $font-size-large;
  }
}
