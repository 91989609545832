/**
 * .mc-dialog
 * Provides layout styles for different types of dialogs.
 *
 * .mc-dialog-proxy
 * The proxy dialog class can be used when the markup of the dialog is another component. It effectively treats the contents of the element like direct children of the dialog.
 * eg
 * ```html
 *  <mc-license-host-map-settings-form class="mc-dialog-proxy" color="secondary"></mc-license-host-map-settings-form>
 *
 *  <button type="button" mat-icon-button class="mc-dialog-floating-close-button mc-dialog-horizontal-stepper-floating-close-button" [color]="closeButtonColor$ | async" tabindex="-1" (click)="closeDialog()">
 *    <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
 *  </button>
 * ```
 */
.mc-dialog,
.mc-dialog-proxy {
  display: block;
  position: relative; // To make the dialog a positioned element
  height: 100%; // To fill the dialog's configured height
  min-height: inherit; // To match the dialog's configured min-height
  max-height: inherit; // To match the dialog's configured max-height

  /**
   * Spring board layout
   */
  >.mc-spring-board:not(.mc-switch-board) {
    // Do not apply this layout to an mc-switch-board
    min-height: inherit; // To match the dialog's configured min-height
    max-height: inherit; // To match the dialog's configured max-height

    >.mc-spring-board-header.mat-toolbar-multiple-rows {
      min-height: 0;
    }

    >.mc-spring-board-header.mat-toolbar-single-row,
    >.mc-spring-board-header.mat-toolbar .mat-toolbar-row {
      height: $dialog-header-bar-height; // Make the mat-toolbar dialog header the dialog header height
      padding-right: $dialog-close-button-offset; // Improves the spacing for the dialog close button
    }

    >.mc-spring-board-body:not(.mat-stepper-horizontal),
    >.mc-spring-board-form-body:not(.mat-stepper-horizontal) {
      padding: $spacing-normal $spacing-normal $spacing-none;

      // If there is no footer then give the body padding at the bottom
      &:last-child {
        padding-bottom: $spacing-normal;
      }
    }

    >.mc-spring-board-footer,
    >.mc-spring-board-form-footer {
      margin-top: $spacing-none; // Remove the top margin and replace it with top padding
      padding: $spacing-normal;
    }
  }

  /**
   * Spring board tab layout
   */
  >.mc-spring-board {

    >.mc-spring-board-body.mat-tab-group,
    >.mc-spring-board-form-body.mat-tab-group {

      &,
      &:not(.mat-stepper-horizontal) {
        // Needed to override the selector above in the default spring-board layout
        padding: $spacing-none; // Remove the spacing so that the tab group extends to the edge of the dialog
      }

      >.mat-tab-body-wrapper {
        // Add the spacing to the mat-tab-body-wrapper that was removed from the mc-spring-board-body.mat-tab-group
        // Use margin because the mat-tab-body children are positioned absolutely when not active. Using padding causes the mat-tab-body children to jump around when sliding
        margin: $spacing-normal $spacing-normal $spacing-none;
      }

      >.mat-tab-header .mat-tab-label {
        height: $dialog-header-bar-height; // Make the mat-tab-group dialog header the dialog header height
      }
    }
  }

  /** 
    * Dialog wizard layout
    */
  .mc-spring-board.mc-dialog-wizard {

    >.mc-spring-board-header.mat-toolbar-single-row,
    >.mc-spring-board-header.mat-toolbar .mat-toolbar-row {
      height: $dialog-horizontal-stepper-header-bar-height;
      align-items: flex-end;
    }

    .mc-spring-board-stepper-header {
      margin-left: $spacing-xx-small;
    }
  }

  /**
   * Spring board horizontal stepper layout
   */
  >.mc-spring-board {

    >.mc-spring-board-body.mat-stepper-horizontal,
    >.mc-spring-board-form-body.mat-stepper-horizontal {
      .mat-horizontal-stepper-wrapper {
        display: flex;
        flex-direction: column;
      }

      .mat-horizontal-stepper-header {
        height: ($dialog-horizontal-stepper-header-spacing * 2) + $computed-line-height-normal;
      }

      .mat-horizontal-stepper-header-container {
        flex-shrink: 0; // The header should take up all the space it needs
      }

      .mat-horizontal-content-container {
        flex-grow: 1;
        display: flex; // Makes its content fill all the height available
        flex-direction: column; // Makes its content fill all the height available
        padding: $spacing-normal;

        >.mat-horizontal-stepper-content {
          @extend .mc-spring-board;
          flex-grow: 1; // Makes this fill all the height available

          // Collapsed steps have a height of zero so reapply it here since .mc-spring-board gives the step a height of 100%
          &.mat-horizontal-stepper-content-inactive {
            height: 0;
            flex-grow: 0; // Makes this fill no height when collapsed
          }
        }
      }

      >.mc-spring-board-header.mat-toolbar-single-row,
      >.mc-spring-board-header.mat-toolbar .mat-toolbar-row {
        height: $dialog-horizontal-stepper-header-bar-height;
        align-items: flex-end;
      }

      +.mc-dialog-floating-close-button {
        top: $dialog-horizontal-stepper-close-button-offset;
        right: $dialog-horizontal-stepper-close-button-offset;
      }
    }
  }

  /**
   * The top level loader for a dialog. Gets offset if there is a dialog header.
   */
  >.mc-spring-board {

    >.mc-spring-board-header~.mc-loader,
    >.mc-spring-board-body.mat-tab-group~.mc-loader,
    >.mc-spring-board-form-body.mat-tab-group~.mc-loader {
      top: $dialog-header-bar-height; // Offset the loader below the dialog header
      bottom: 0; // Clamp the loader to the bottom of the dialog
      height: auto; // Let the height fill the space instead of being 100%
    }
  }

  /**
   * The top level error for a dialog. Gets offset if there is a dialog header.
   */
  >.mc-spring-board {

    >.mc-spring-board-header~.error-pane,
    >.mc-spring-board-body.mat-tab-group~.error-pane,
    >.mc-spring-board-form-body.mat-tab-group~.error-pane {
      top: $dialog-header-bar-height; // Offset the error pane below the dialog header
      bottom: 0; // Clamp the error pane to the bottom of the dialog
      height: auto; // Let the height fill the space instead of being 100%
    }
  }

  /**
   * Floating close button. Usually a close button is simply a button in the .mc-spring-board-header.mat-toolbar
   * But if there is no .mat-toolbar then an absolutely positioned button is needed that is floated in the top right of the dialog.
   * This can be used with a tab layout, split dialog layout, etc.
   */
  .mc-dialog-floating-close-button {
    position: absolute;
    top: $dialog-close-button-offset;
    right: $dialog-close-button-offset;
    z-index: z-index(dialog-close-button); // Make it cover the tabs
  }

  /**
   * Floating close button to be used with horizontal steppers. Positions the button to be centered in the stepper's header.
   */
  .mc-dialog-horizontal-stepper-floating-close-button {
    top: $dialog-horizontal-stepper-close-button-offset;
    right: $dialog-horizontal-stepper-close-button-offset;
  }
}


/**
 * Everything below here is deprecated
 * @deprecated
 */
.mc-dialog-actions-align-end {
  justify-content: flex-end;
}

.mc-dialog-actions-align-center {
  justify-content: center;
}

.mc-dialog-card-container .mat-dialog-container {
  padding: $spacing-none; // reset the automatic padding applied to .mat-dialog-container
}

.mc-dialog-card-content-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.mc-dialog-header-bar {
  flex-shrink: 0;

  &.mat-toolbar-row,
  &.mat-toolbar-single-row {
    height: $dialog-header-bar-height;
    padding-right: $dialog-close-button-offset;
  }
}

.mc-dialog-header-bar-icon-title {
  display: flex;
  align-items: center;

  .mat-icon {
    height: 1em;
  }
}

.mc-dialog-card-sidebar {
  flex-shrink: 0;
  width: $dialog-card-sidebar-width;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mc-dialog-card-main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;

  .mat-tab-group {
    flex-grow: 1;
    min-height: 0;
  }

  .mat-tab-body-content {
    -webkit-overflow-scrolling: touch;
  }
}

.mc-dialog-card-tab-content,
.mc-dialog-card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.mc-dialog-card-tab-content {
  padding: $spacing-small $spacing-normal $spacing-none;
}

.mc-dialog-card-content {
  padding: $spacing-normal $spacing-normal $spacing-none;
}

.mc-dialog-card-actions {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: $spacing-normal;

  .mc-dialog-card-action-button {
    margin-left: $button-between-spacing;
  }

  .mc-dialog-card-content-left {
    display: flex;
    flex-grow: 1;
  }
}

.mc-dialog-tab-header {
  position: relative;

  .mc-dialog-tab-header-close-button {
    position: absolute;
    top: $spacing-xxx-small;
    right: $spacing-xxx-small;
    z-index: z-index(dialog-close-button); // Make it cover the tabs
  }
}