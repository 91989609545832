$toolbar-btn-radius: $border-radius-normal !default;
$toolbar-radius: $border-radius-normal !default;

$toolbar-height-small: 3.2rem !default;
$toolbar-height-normal: 4rem !default;
$toolbar-height-large: $spacing-small + $spacing-small + ($font-size-normal * $line-height-normal) !default; // padding + padding + line-height
$toolbar-height-header: $toolbar-height-large !default;

$toolbar-spacing-header: $spacing-normal !default;

$toolbar-text-height: 4rem !default;
$toolbar-text-height-small: 3rem !default;
$toolbar-text-height-normal: 3.6rem !default;

$toolbar-divider-height: 3rem !default;
$toolbar-divider-spacing: $spacing-x-small !default;

$toolbar-tab-spacing: ($spacing-large * 0.5) !default;
