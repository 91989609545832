@use '@angular/material' as mat;
@mixin mc-menu-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-menu-item.mc-list-item-selected {
    background: mat.get-color-from-palette($background, list-item-selected-light);
    color: mat.get-color-from-palette($foreground, text);
  }

  .mc-menu {
    // Match the theming for a mat-subheader within a mat-list
    .mat-subheader {
      color: mat.get-color-from-palette($foreground, list-subheader);
    }

    .mat-radio-label:hover {
      background: mat.get-color-from-palette($background, list-item-hover-light);
    }

    .mat-menu-item:hover:not([disabled]),
    .mc-menu-item:hover {
      background: mat.get-color-from-palette($background, list-item-hover-light);
    }

    .mc-menu-item-selected {
      background: mat.get-color-from-palette($background, list-item-selected-light);
    }

    .mat-menu-item[disabled] .mat-icon-no-color {
      color: mat.get-color-from-palette($foreground, disabled);
    }
  }
}
