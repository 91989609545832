.mc-portal-output {
  .mc-portal-output-body .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }

  .mc-portal-output-app-logo {
    position: absolute;
    top: $spacing-normal;
    left: $spacing-normal;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .mat-card .mc-switch-board .mc-switch-board-bar .mat-list-base .mat-list-item .mat-icon {
    color: inherit;
  }

  .mat-card .mc-switch-board .mc-switch-board-bar .mat-list-base .mat-list-item {
    &.mc-list-item-selected {
      background: rgba(0, 0, 0, .1);
    }
  }

  .mat-card .mc-switch-board .mc-switch-board-bar .mat-list-base a.mat-list-item {
    &:hover {
      background: rgba(0, 0, 0, .1);
    }
  }
}
