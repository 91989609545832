@use '@angular/material' as mat;
@mixin mc-mat-icons-theme ($theme, $typography) {
  .mat-icon {
    // Recreate the three material color themes so that muted and vivid variations are created
    @include _mc-mat-icon($theme, 'primary');
    @include _mc-mat-icon($theme, 'accent');
    @include _mc-mat-icon($theme, 'warn');
    @include _mc-mat-icon($theme, 'brand');
    @include _mc-mat-icon($theme, 'secondary');
    @include _mc-mat-icon($theme, 'secondary-invert');
    @include _mc-mat-icon($theme, 'notice');
    @include _mc-mat-icon($theme, 'caution');
    @include _mc-mat-icon($theme, 'chart-1');
    @include _mc-mat-icon($theme, 'chart-2');
    @include _mc-mat-icon($theme, 'chart-3');
    @include _mc-mat-icon($theme, 'chart-4');
    @include _mc-mat-icon($theme, 'chart-5');
    @include _mc-mat-icon($theme, 'chart-6');
    @include _mc-mat-icon($theme, 'info');
    @include _mc-mat-icon($theme, 'info-2');
    @include _mc-mat-icon($theme, 'link');
    @include _mc-mat-icon($theme, 'checklist-completed');
    @include _mc-mat-icon($theme, 'checklist-in-progress');
    @include _mc-mat-icon($theme, 'checklist-todo');
    @include _mc-mat-icon($theme, 'checklist-na');
    @include _mc-mat-icon($theme, 'build-cancel');
    @include _mc-mat-icon($theme, 'build-fail');
    @include _mc-mat-icon($theme, 'build-success');
    @include _mc-mat-icon($theme, 'build-unknown');
    @include _mc-mat-icon($theme, 'report-broken-bookmarks');
    @include _mc-mat-icon($theme, 'report-broken-links');
    @include _mc-mat-icon($theme, 'report-broken-snippet-links');
    @include _mc-mat-icon($theme, 'report-undefined-condition-tags');
    @include _mc-mat-icon($theme, 'report-undefined-file-tags');
    @include _mc-mat-icon($theme, 'report-undefined-glossary-terms');
    @include _mc-mat-icon($theme, 'report-undefined-variables');
    @include _mc-mat-icon($theme, 'report-images');
    @include _mc-mat-icon($theme, 'report-segments');
    @include _mc-mat-icon($theme, 'report-snippets');
    @include _mc-mat-icon($theme, 'report-topics');
    @include _mc-mat-icon($theme, 'report-words');
    @include _mc-mat-icon($theme, 'task-low-priority');
    @include _mc-mat-icon($theme, 'task-medium-priority');
    @include _mc-mat-icon($theme, 'task-high-priority');
    @include _mc-mat-icon($theme, 'usage-storage');
    @include _mc-mat-icon($theme, 'usage-source-files');
    @include _mc-mat-icon($theme, 'usage-builds');
    @include _mc-mat-icon($theme, 'usage-tasks');
    @include _mc-mat-icon($theme, 'usage-misc');
    @include _mc-mat-icon($theme, 'usage-authors');
    @include _mc-mat-icon($theme, 'usage-smes');
  }
}

@mixin _mc-mat-icon ($theme, $icon-name) {
  $palette: map-get($theme, $icon-name);

  &.mat-#{$icon-name} {
    color: mat.get-color-from-palette($palette, default);
  }

  &.mat-#{$icon-name}-muted {
    color: mat.get-color-from-palette($palette, lighter);
  }

  &.mat-#{$icon-name}-vivid {
    color: mat.get-color-from-palette($palette, darker);
  }
}
