*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: $root-font-size;
}

body {
  margin: $spacing-none;
}

p {
  margin: $spacing-none;
}

ul,
ol {
  margin: $spacing-none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: $spacing-none;
  font-size: inherit;
  font-weight: inherit;
  line-height: $line-height-normal;
}

b,
strong {
  font-weight: $font-weight-heavy;
}

sup {
  font-size: $sup-font-size;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

/*
 * Overriding normalize.less from Bootstrap
 */
small,
.small {
  font-size: $font-size-small;
}

sup {
  position: unset;
  top: unset;
  vertical-align: super;
  line-height: 1;
}

/*
 * Overriding from scaffolding.less. It should be possible to remove this after the app is no longer hybrid.
 */
[role='button'] {
  cursor: unset;
}

button:not(:disabled) {
  cursor: pointer;
}

[hidden] {
  display: none;
}
