.mc-month-calendar-small {
  .cal-month-view .cal-header .cal-cell {
    font-weight: $font-weight-light;
  }

  .cal-month-view .cal-day-cell,
  .cal-month-view .cal-cell-top {
    min-height: 0; // Remove the min-height so that the cells can be small
  }

  .cal-month-view .cal-day-cell {
    height: $mc-month-calendar-small-day-cell-size;
  }

  // .cal-month-view .cal-day-cell:not(:last-child) {
  //   border-right: $border-none;
  // }

  .cal-month-view .cal-cell-top {
    display: flex;
    justify-content: center;
  }

  .cal-month-view .cal-day-number {
    margin: $mc-month-calendar-small-day-number-offset $spacing-none $spacing-none;
    width: $mc-month-calendar-small-day-number-size;
    height: $mc-month-calendar-small-day-number-size;
    line-height: $mc-month-calendar-small-day-number-size;
    border-radius: $border-radius-circle;
    text-align: center;
  }

  .cal-month-view .cal-day-number,
  .cal-month-view .cal-day-cell.cal-today .cal-day-number {
    font-size: $font-size-normal;
  }

  .cal-month-view .cal-events {
    display: flex;
    justify-content: center;
  }
}
