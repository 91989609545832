/*
 * Creates the styles for a centered mat-icon-button for the size specified
 */
@mixin mc-icon-button-size($font-size, $button-size) {
  &.mat-icon-button {
    width: $button-size;
    height: $button-size;
    line-height: $button-size;
    font-size: $font-size;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mat-icon {
      width: 1em;
      height: 1em;
      line-height: 1em;
    }
  }
}

@mixin mc-mini-fab-size($font-size, $button-size) {
  &.mat-mini-fab {
    width: $button-size;
    height: $button-size;
    line-height: $button-size;
    font-size: $font-size;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mat-icon {
      width: 1em;
      height: 1em;
      line-height: 1em;
    }
  }
}

/*
 * Creates the styles for a mat-button for the size specified
 */
@mixin mc-button-size($font-size, $button-height) {
  &.mat-button {
    line-height: $button-height;
    font-size: $font-size;
  }
}

/*
 * Creates the styles for a mat-stroked-button for the size specified
 */
@mixin mc-stroked-button-size($font-size, $button-height) {
  &.mat-stroked-button {
    line-height: calc(#{$button-height} - 2px); // Account for the border
    font-size: $font-size;
  }
}

/*
 * Creates the styles for a mat-flat-button for the size specified
 */
@mixin mc-flat-button-size($font-size, $button-height) {
  &.mat-flat-button {
    line-height: $button-height;
    font-size: $font-size;
  }
}
