.mc-material {
  .mat-list-base,
  .mat-list-base[dense] {
    padding-top: $spacing-none; // Remove the top padding
  }

  .mat-list-base .mat-subheader:first-child {
    margin-top: $spacing-none; // Remove the negative margin since the list no longer has a top padding
  }

  .mat-list-base .mat-list-item .mat-list-item-content,
  .mat-list-base .mat-list-option .mat-list-item-content {
    padding-left: $list-item-horizontal-spacing;
    padding-right: $list-item-horizontal-spacing;
  }

  .mat-nav-list {
    .mat-subheader {
      padding-left: $list-item-horizontal-spacing;
      padding-right: $list-item-horizontal-spacing;
    }

    .mat-list-item {
      .mat-list-icon {
        // Offset the left padding so that the icons can line up with other items
        margin-left: -$mat-list-icon-padding-px;
        padding-right: 1rem;
      }
    }
  }

  // text-decoration is normally none for these but the selector needs to be more specific here to override the normal <a> styles
  .mat-list-item,
  .mat-menu-item,
  .mat-tab-link {
    text-decoration: none;
  }

  // Reset the box-sizing value to the browser default so that the checkbox renders correctly
  .mat-pseudo-checkbox::after {
    box-sizing: content-box;
  }
}
