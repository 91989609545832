.mc-popup-content {
  display: block;
  width: 100%;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding: $spacing-small;
  border-radius: $border-radius-x-large;
}

.mc-popup-lazy-content {
  display: block;
  width: 100%;
  height: 100%;
}

/**
 * mc-popup-toolbar
 * Styles for a popup with a mat-toolbar as its content and an arrow pointing at the popup's trigger.
 */
.mc-popup-toolbar {
  .mc-popup,
  .mc-popup-content {
    border-radius: $popup-toolbar-border-radius;
  }

  .mc-popup-content {
    border-style: solid;
    border-width: $border-width;
    padding: $spacing-none; // The mat-toolbar provides the spacing so remove it from the popup itself
  }

  // The popup's arrow that points at its trigger
  .mc-popup:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: ($popup-toolbar-arrow-nub-size * 2);
    transform: translateY(-50%) rotate(45deg);
    height: $popup-toolbar-arrow-nub-size;
    width: $popup-toolbar-arrow-nub-size;
    background: inherit;
    border-style: solid;
    border-width: $border-width $border-none $border-none $border-width;
  }

  .mat-toolbar {
    // Decrease the size of the horizontal padding on the mat-toolbar
    padding-left: $popup-toolbar-horizontal-spacing;
    padding-right: $popup-toolbar-horizontal-spacing;
  }
}
