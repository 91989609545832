.mc-markdown {
  // The line-height needs to be tall enough for inline code to not overlap other content
  line-height: 1.7;

  /*
   * top/bottom margins
   */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  pre > code,
  table,
  blockquote {
    margin-bottom: $spacing-normal;
  }

  li {
    margin-bottom: $spacing-xx-small;

    &:first-child {
      margin-top: $spacing-xx-small;
    }

    > {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ul,
      ol,
      code,
      table,
      blockquote {
        margin-bottom: $spacing-xx-small;
      }
    }
  }

  /*
   * font-size
   */
  h1 {
    font-size: $font-size-xx-large;
  }

  h2 {
    font-size: $font-size-x-large;
  }

  h3 {
    font-size: $font-size-large;
  }

  h4 {
    font-size: $font-size-normal;
  }

  h5 {
    font-size: $font-size-normal;
  }

  h6 {
    font-size: $font-size-normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $font-weight-heavy;
  }

  /*
   * code
   */
  code {
    font-family: $monospace-font-family;
    padding: .3rem .5rem;
    border-radius: $border-radius-normal;
    font-size: 85%;
  }

  pre > code {
    display: block;
    padding: $spacing-small $spacing-normal;
    border-style: solid;
    border-width: $border-width;
    border-radius: $border-radius-normal;
    overflow: auto;
    white-space: pre;
  }

  /*
   * table
   */
  table {
    width: 100%;
    border-collapse: collapse;
  }

  td,
  th {
    border-style: solid;
    border-width: $border-width;
    padding: $spacing-small;
    text-align: left;
  }

  /*
   * blockquote
   */
  blockquote {
    margin-top: $spacing-none;
    margin-left: $spacing-none;
    margin-right: $spacing-none;
    padding: $spacing-xxx-small;
    border-radius: $border-radius-normal;
    border-left-style: solid;
    border-left-width: 1rem;

    > *:last-child {
      margin-bottom: $spacing-none;
    }
  }
}
