@mixin nested-tree-node-indentation ($indentation, $max, $level: 1) {
  .mc-nested-tree-node-items {
    .mc-tree-node-item {
      padding-left: ($list-item-horizontal-spacing + $level * $indentation);
    }

    @if $level < $max {
      @include nested-tree-node-indentation($indentation, $max, $level + 1);
    }
  }
}

@mixin flat-tree-node-indentation ($indentation) {
  .mc-tree-node-item {
    --mc-flat-tree-node-level: 0;
    padding-left: calc(#{$list-item-horizontal-spacing} + var(--mc-flat-tree-node-level) * #{$indentation});
  }
}

@mixin flat-tree-rail-indentation ($indentation, $anchor-width) {
  --mc-flat-tree-rail-level: 0;
  left: calc(#{$list-item-horizontal-spacing} + #{$anchor-width * .5} - #{$tree-rail-width * .5} + var(--mc-flat-tree-rail-level) * #{$indentation});
}
