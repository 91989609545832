.mc-material {
  .mat-card {
    padding: $card-spacing;
  }

  .mat-card-header {
    margin-bottom: $spacing-xx-small;
  }

  .mat-card-content,
  .mat-card-subtitle,
  .mat-card-title {
    margin-bottom: $card-spacing;
  }

  .mat-card-footer {
    margin: $spacing-none;
  }

  .mat-card-actions {
    margin: $card-spacing $spacing-none $spacing-none;
    padding: $spacing-none;
  }

  .mat-card-actions .mat-button,
  .mat-card-actions .mat-raised-button {
    margin: $spacing-none;
  }

  .mat-card > .mat-card-actions:last-child {
    margin-bottom: $spacing-none;
  }
}
