$editor-bordered-tag-padding: $spacing-xxxx-small;
$editor-dashed-bordered-tag-padding: .2em; // Scale based on the parent's font-size
$editor-external-spacing: $spacing-normal;
$editor-internal-spacing: $spacing-x-small;
$editor-block-margin-bottom: $spacing-xxx-small;
$editor-enclosed-element-padding: $spacing-xxxx-small;

$editor-gutter-width: 20rem;
$editor-node-name-font-size: .8em;

// selected nodes
$editor-selected-node-outline-width: $border-width-thick;

// checkboxes and radio buttons
$editor-checkbox-text-spacing: .8rem;

// headings
$editor-h1-font-size: $font-size-xxx-large;
$editor-h2-font-size: $font-size-xx-large;
$editor-h3-font-size: $font-size-x-large;
$editor-h4-font-size: $font-size-large;
$editor-h5-font-size: $font-size-normal;
$editor-h6-font-size: $font-size-small;

// code/pre
$editor-code-margin-bottom: $editor-block-margin-bottom;
$editor-code-padding: $editor-enclosed-element-padding $spacing-xxx-small;

// paragraphs
$editor-paragraph-margin-bottom: $editor-block-margin-bottom;

// tables
$editor-table-cell-spacing: $spacing-xxx-small;

// lists
$editor-list-item-spacing: $spacing-large;
$editor-dl-item-spacing: $spacing-normal;
$editor-list-item-margin-bottom: $editor-block-margin-bottom;

// hr
$editor-hr-spacing: $spacing-x-small;

// dropdowns
$editor-dropdown-guide-line-spacing: .5rem;
$editor-dropdown-guide-line-width: $border-width-rem;
$editor-dropdown-guide-arrow-width: .4rem;
$editor-dropdown-body-left-padding: 1rem;
$editor-dropdown-head-overhang: .4rem;

// annotations
$editor-annotation-spacing: $editor-enclosed-element-padding;
$editor-annotation-border-radius: $border-radius-normal;

// snippets
$editor-snippet-block-margin-bottom: $editor-block-margin-bottom;
$editor-snippet-spacing: $editor-enclosed-element-padding;
$editor-snippet-inline-node-outline-offset: 1px;
$editor-snippet-inline-selected-node-outline-width: 3px; // Make this wider so that it is more visible with the outline around the snippet's content

// multiple choice
$editor-multiple-choice-margin-bottom: $editor-block-margin-bottom;
$editor-multiple-choice-submit-button-padding: (
  $spacing-small * .5) $spacing-small;
$editor-multiple-choice-feedback-spacing: $editor-dashed-bordered-tag-padding;
$editor-multiple-choice-feedback-title-font-size: .8em; // Scale based on the parent's font-size
$editor-multiple-choice-feedback-padding-top: $editor-dashed-bordered-tag-padding + $editor-multiple-choice-feedback-title-font-size + $editor-dashed-bordered-tag-padding;

// conditions
$editor-condition-horizontal-spacing: $editor-dashed-bordered-tag-padding;
$editor-condition-image-spacing: $editor-dashed-bordered-tag-padding;
$editor-condition-indicator-height: 1.3rem;
$editor-condition-indicator-width: 1.3rem;

// bracket elements
$editor-bracket-width: $border-width-thick;
$editor-bracket-spacing: $spacing-xxx-small;
$editor-inline-bracket-outer-spacing: .1em;

// name box elements
$editor-name-box-margin: 1px;
$editor-block-name-box-margin-bottom: $editor-block-margin-bottom;
$editor-name-box-font-size: $editor-node-name-font-size;

// star elements
$editor-star-font-size: 50%;
$editor-star-spacing: .3em;

// bookmark elements
$editor-bookmark-font-size: $font-size-normal;

// target elements
$editor-target-spacing: .2em;
