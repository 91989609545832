@use '@angular/material' as mat;
@mixin mc-mat-inputs-theme ($theme, $typography) {
  @include _mc-mat-input($theme, 'primary');
}

@mixin _mc-mat-input ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);
  $warn: map-get($theme, warn);

  .mat-#{$palette-name} {
    .mat-input-element {
      caret-color: mat.get-color-from-palette($palette, default);
    }
  }

  .mat-#{$palette-name}-muted {
    .mat-input-element {
      caret-color: mat.get-color-from-palette($palette, lighter);
    }
  }

  .mat-#{$palette-name}-vivid {
    .mat-input-element {
      caret-color: mat.get-color-from-palette($palette, darker);
    }
  }

  .mat-form-field-invalid {
    &.mat-#{$palette-name},
    &.mat-#{$palette-name}-muted,
    &.mat-#{$palette-name}-vivid {
      .mat-input-element {
        caret-color: mat.get-color-from-palette($warn);
      }
    }
  }
}
