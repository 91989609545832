.error-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z-index(error-pane);
  padding: $spacing-normal;
  text-align: center;

  .error-message {
    max-width: 100%;
    word-wrap: break-word;
  }

  .error-button {
    margin-top: $spacing-normal;
  }
}

.mc-error-pane-flex-child {
  position: static;
}
