html.app-fill-viewport {
  height: 100%;

  body {
    height: 100%;
  }
}

.page-navbar {
  flex-shrink: 0;

  .page-navbar-head {
    flex-shrink: 0;
    width: $page-head-width;
  }
}

.page {
  display: flex;
  flex-grow: 1;
  min-height: 0; // To keep it from growing larger than its parent
  height: 100%;
  margin-left: $app-nav-width;
  padding: $page-spacing;
}

.page-full {
  margin-left: $spacing-none;
}

.mc-page-full-footer {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.page-centered {
  align-items: center;
  justify-content: center;
}

.page-cards {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-width: 0; // To keep it from growing larger than its parent
}

.page-card + .page-card,
.page-card + .page-cards,
.page-cards + .page-card,
.page-card-column + .page-card-column {
  margin-left: $page-spacing;
}

.page-card-minor {
  flex-shrink: 0;
  width: $page-minor-card-width;
}

.page-card-major {
  flex-grow: 1;
  max-width: $page-major-card-max-width;
}

.page-card.page-dashboard {
  flex-grow: 1;
  max-width: $page-dashboard-max-width;
  box-shadow: none;
}

.mc-page-fill .page-card-major {
  max-width: 100%;
  flex-basis: 100%; // Make the major card fill the space but not be wider than the parent
}

.page-card.page-gutter {
  flex-shrink: 0;
  width: $page-gutter-width;
  box-shadow: none;

  > * {
    flex-grow: initial; // Reset the flex-grow property for page-gutter children
    overflow-y: auto;
  }

  .mc-page-gutter-fill {
    flex-grow: 1;
  }

  // Use a combination of margins and paddings to make the spacing equal to $page-gutter-spacing by splitting the space between the page-gutter and the page-gutter panel header/body.
  // This ensures the panel's have space between their content and outer edge when hovering over them (since hovering over them adds a bg color)
  $page-gutter-padding: $page-gutter-margin;

  margin-left: $page-gutter-margin;
  margin-right: -$page-gutter-margin;

  .mat-expansion-panel {
    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }
  }

  .mat-expansion-panel-header {
    padding: $spacing-none $page-gutter-padding;
  }

  .mat-expansion-panel-body {
    padding: $spacing-none $page-gutter-padding $page-gutter-spacing;
  }
}
