/**
 * .mc-form-buttons
 * Displays a row of buttons right-aligned with space between the buttons
 */
.mc-form-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > button:not(:first-child) {
    margin-left: $button-between-spacing;
  }

  .mc-form-buttons-left {
    display: flex;
    flex-grow: 1;
  }
}

.mc-forms-buttons-space-between {
  justify-content: space-between;
}

.mc-form-text {
  margin-bottom: $spacing-form-controls;
  font-weight: $font-weight-light;
}

.mc-form-value {
  margin-bottom: $spacing-form-controls;
}

.mc-form-title {
  margin-bottom: $spacing-form-controls;
}

.mc-form-heading {
  margin-bottom: $spacing-form-controls;
  font-weight: $font-weight-heavy;
}

.mc-form-label {
  font-size: $form-label-font-size;
  margin-bottom: $form-label-margin-bottom;
}

.mat-option {
  &.mc-select-option-link {
    padding: $spacing-none;

    a {
      width: 100%;
      padding: $spacing-none $mat-option-horizontal-padding;
    }
  }
}

.mc-form-note {
  margin-bottom: $spacing-form-controls;
  font-weight: $font-weight-light;
  font-size: $font-size-small;
  font-style: italic;
}

.mc-form-spacer {
  height: $spacing-form-controls;
}

.mc-form-divider.mat-divider {
  margin: $spacing-large $spacing-none;
}

.mc-form-section {
  margin-bottom: $spacing-form-controls;
}

/**
 * .mc-radio-button-field
 * A CSS component for displaying a radio button with a form field as the radio button's label.
 *
 * <div class="mc-radio-button-field>
 *   <mat-radio-button class="mat-primary-vivid"></mat-radio-button>
 *   <mat-form-field color="primary-vivid">...</mat-form-field>
 * </div>
 */
.mc-radio-button-field {
  display: flex;
  align-items: center;

  > .mat-radio-button {
    margin-right: $spacing-xxx-small; // Give a little space between the radio-button and the form-field so that the radio-button's ripple does not intersect the form-field
    margin-bottom: $spacing-xx-small; // Offset from the center to line up with the form-field's input text
  }
}

/**
 * .mc-select-align-right
 * Add to a <mat-select> to align field's text to the right
 */
.mc-select-align-right {
  .mat-select-value {
    padding-right: $spacing-xxx-small;
    text-align: right;
  }
}

/**
 * .mc-form-field-button-suffix
 * A CSS component for displaying a rectangular material button as a suffix in a form field.
 *
 * <mat-form-field color="primary-vivid" class="mc-form-field-button-suffix">
 *   <input matInput type="text" placeholder="Label">
 *   <button matSuffix mat-raised-button type="button" color="primary-vivid">Save</button>
 * </mat-form-field>
 */
.mc-form-field-button-suffix {
  .mat-form-field-suffix {
    align-self: flex-end;
    margin-left: $spacing-xx-small;
    padding-bottom: $mat-form-field-infix-padding; // Aligns the button with the form-field's input text

    > .mat-raised-button,
    > .mat-stroked-button {
      line-height: $button-size-small;
      font-size: $font-size-small;
    }
  }
}
