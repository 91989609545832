/**
 * mc-spring-board
 * Fills the height of its parent.
 * Always shows the header and footer while the body between scrolls.
 */
.mc-spring-board {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mc-spring-board-body,
.mc-spring-board-form-body {
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mc-spring-board-form-body {
  padding: $spacing-none $spacing-normal;
}

.mc-spring-board-header {
  flex-shrink: 0;
}

.mc-spring-board-footer,
.mc-spring-board-form-footer {
  flex-shrink: 0;
  margin-top: $spacing-normal;
}

.mc-spring-board-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    flex-shrink: 0; // Do not let buttons shrink
    margin-left: $button-between-spacing;
  }
}

/**
 * mc-horizontal-spring-board
 * A spring board that goes from left to right.
 */
.mc-horizontal-spring-board {
  flex-direction: row;

  // Make the header scroll vertically if necessary
  >.mc-spring-board-header {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}