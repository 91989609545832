@use '@angular/material' as mat;
@mixin mc-body-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  body {
    @include mat.typography-level($typography, body);
    color: mat.get-color-from-palette($foreground, base);
    background: mat.get-color-from-palette($background, body);
  }
}
