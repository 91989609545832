.mc-material {
  .mat-form-field-label {
    line-height: $input-line-height-normal;
  }

  // A mat-form-field does not work with multiline errors. The below styles add support for multi line errors
  // Normally a legacy mat-form-field is 57.69px tall. To prevent the mat-form-field size from fluctuating between valid and invalid states a min-height of 64px is being used.
  // The fluctuation normally does not occur but happens because the underline and subscript message elements are now being statically positioned to support multi line messages.
  $mat-form-field-appearance-legacy-min-height: 64px;
  $mat-form-field-appearance-standard-min-height: 76px;

  form { // Limit the change to forms since that is where validation messages display. This way we don't affect the layout of other places that use mat-form-field like mat-paginator
    .mat-form-field {
      &.mat-form-field-appearance-legacy {
        min-height: $mat-form-field-appearance-legacy-min-height;
      }

      &.mat-form-field-appearance-standard {
        min-height: $mat-form-field-appearance-standard-min-height;
      }

      // Change the underline and subscript messages to be statically positioned so that they take up space in the dom flow
      .mat-form-field-underline,
      .mat-form-field-subscript-wrapper {
        position: static;
      }

      // Remove the padding from the form field wrapper. It is not needed because mat-form-field has a min-height and the subscript wrapper is now positioned statically
      &,
      &.ng-valid,
      &.ng-invalid,
      &.ng-untouched,
      &.mat-form-field-invalid {
        .mat-form-field-wrapper {
          padding-bottom: $spacing-none;
        }
      }
    }
  }
}
