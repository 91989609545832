@mixin mc-list-bg {
  &.mat-list-base,
  &.mat-tree {
    .mat-list-item,
    .mc-tree-node-item {
      &:not(.mc-list-item-disabled) {
        cursor: pointer;
      }
    }
  }
}

@mixin mc-list-font-size ($font-size, $selected-font-size) {
  &.mat-list-base,
  &.mat-list-base[dense],
  &.mat-tree {
    .mat-list-item,
    .mc-tree-node-item {
      font-size: $font-size;

      &:not(.mc-list-item-disabled) {
        cursor: pointer;
      }
    }

    .mc-list-item-selected {
      font-size: $selected-font-size;
    }
  }
}

@mixin mc-list-font-weight ($font-weight, $selected-font-weight) {
  &.mat-list-base,
  &.mat-tree {
    // Reset the material style to inherit the font-weight which is set below
    .mat-list-item .mat-list-text > * {
      font-weight: inherit;
    }

    .mat-list-item,
    .mc-tree-node-item {
      font-weight: $font-weight;

      &:not(.mc-list-item-disabled) {
        cursor: pointer;
      }
    }

    .mc-list-item-selected {
      font-weight: $selected-font-weight;
    }
  }
}
