@use '@angular/material' as mat;
@mixin mc-lists-theme ($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $link: map-get($theme, link);

  .mc-property-list {
    a.mc-property-value,
    .mc-property-value.mc-link,
    .mc-property-value.mc-link-button {
      color: mat.get-color-from-palette($link);
    }
  }

  .mc-property-list-muted {
    .mc-property-value {
      color: mat.get-color-from-palette($foreground, property-list-value);
    }
  }

  .mc-avatar-list {
    &.mc-avatar-list-fade {
      &:after {
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 60%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }

  /**
   * List Background Light
   * Applies a light background to the list items based on if an item is selected or hovered
   */
  .mc-list-bg-light,
  .mc-list-selectable {
    @include _mc-list-bg($theme, list-item-selected-light, list-item-hover-light);
  }

  .mc-list-hoverable {
    @include _mc-hoverable-bg($theme, list-item-selected-light, list-item-hover-light);
  }

  /**
   * List Background Dark
   * Applies a dark background to the list items based on if an item is selected or hovered
   */
  .mc-list-bg-dark {
    @include _mc-list-bg($theme, list-item-selected-dark, list-item-hover-dark);
  }

  .mc-list-activatable {
    @include _mc-list-active-bg($theme, list-item-selected-light);
  }

  /**
   * Disabled List Item
   * Applies the disabled color to the item's text
   */
  .mat-list-base,
  .mat-tree {
    .mc-list-item-disabled {
      color: mat.get-color-from-palette($foreground, disabled);
    }
  }

  /*
   * mc-list-links
   */
  .mc-links-list {
    &.mat-list-base {
      .mat-list-item {
        color: mat.get-color-from-palette($link);
      }
    }
  }

  /**
   * mc-list-item-details
   */
  .mc-list-item-details {
    color: mat.get-color-from-palette($foreground, text-muted);
  }

  /**
   * Deleted List Item
   * Applies the dark warn color to the item's text
   */
  .mat-list-base,
  .mat-tree {
    .mc-list-item-deleted {
      color: mat.get-color-from-palette(map-get($theme, warn), darker);
    }
  }

  /**
   * mc-list-item-details
   */
  .mc-list-item-details {
    color: mat.get-color-from-palette($foreground, text-muted);
  }
}

@mixin _mc-hoverable-bg ($theme, $selected-color-name, $hover-color-name) {
  $background: map-get($theme, background);

  &.mat-list-base,
  &.mat-tree {
    .mat-list-item,
    .mc-tree-node-item {
      &:not(.mc-list-item-disabled) {
        &:hover {
          background: mat.get-color-from-palette($background, $hover-color-name);
        }
      }
    }

    .mc-list-option {
      &:not(.mc-list-item-disabled) {
        &:hover {
          .mat-list-item {
            background: mat.get-color-from-palette($background, $hover-color-name);
          }
        }
      }
    }
  }
}

@mixin _mc-list-bg ($theme, $selected-color-name, $hover-color-name) {
  $background: map-get($theme, background);

  &.mat-list-base,
  &.mat-tree {
    .mc-list-item-selected {
      background: mat.get-color-from-palette($background, $selected-color-name);
    }

    .mat-list-item,
    .mc-tree-node-item {
      &:not(.mc-list-item-disabled) {
        &:focus,
        &:hover {
          background: mat.get-color-from-palette($background, $hover-color-name);
        }
      }
    }

    .mc-list-option {
      &:not(.mc-list-item-disabled) {
        &:focus,
        &:hover {
          .mat-list-item {
            background: mat.get-color-from-palette($background, $hover-color-name);
          }
        }
      }
    }
  }
}

@mixin _mc-list-active-bg ($theme, $selected-color-name) {
  $background: map-get($theme, background);

  &.mat-list-base,
  &.mat-tree {
    .mc-list-item-active .mat-list-item {
      background: mat.get-color-from-palette($background, $selected-color-name);
    }
  }
}
