/* stylelint-disable no-descending-specificity */
/* stylelint-disable keyframes-name-pattern */
/* stylelint-disable selector-class-pattern */
.mc-node-view {
  position: relative;

  .mc-node-view-change-remove-svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.mc-pm-document .ProseMirror {
  flex-grow: 1; // Fill all the space
  min-width: 0; // Do not grow wider than the parent
  white-space: pre-wrap; // Keeps chrome from inserting &nbsp; for spaces
  outline: none; // Remove the focus outline
}

.mc-rich-text,
.mc-pm-document .ProseMirror {

  /*
   * prosemirror gap cursor
   */
  .ProseMirror-gapcursor {
    display: none; // Hidden by default
    position: absolute;
    pointer-events: none;
  }

  .ProseMirror-gapcursor:after {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    width: calc(#{$line-height-normal-em} + 2px); // The system caret is just a little taller than the line-height so make the gap cursor just a little wider
    border-top-style: solid;
    border-top-width: $border-width;
    animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
  }

  @keyframes ProseMirror-cursor-blink {
    to {
      visibility: hidden;
    }
  }

  &.ProseMirror-focused .ProseMirror-gapcursor {
    display: block;
  }

  /*
   * ProseMirror-hideselection
   */
  &.ProseMirror-hideselection {
    *::selection {
      // When a node is selected we draw an outline around it so we don't want the browser's text selection to show
      background: transparent;
    }
  }

  /*
   * Selected Node Views
   */
  .ProseMirror-selectednode {
    outline-style: solid;
    outline-width: $editor-selected-node-outline-width;
  }

  // Increase the size of the outline for inline snippets so that it is more visible next to the snippet content's outline
  .ProseMirror-selectednode.mc-dynamic-view-type-snippet-node-view-inline,
  .ProseMirror-selectednode.mc-dynamic-view-type-snippet-node-view-inline-outer {
    outline-width: $editor-snippet-inline-selected-node-outline-width;
  }

  /*
   * image upload plugin
   */
  .mc-editor-image-upload-placeholder,
  .mc-editor-image-upload-error-placeholder {
    display: inline-flex;
    padding: $spacing-none $editor-bordered-tag-padding;
    border-style: solid;
    border-width: $border-width;
  }

  .mc-editor-image-upload-error-icon-close {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  /*
   * mat-checkbox && mat-radio-button
   */
  .mat-checkbox .mat-checkbox-ripple,
  .mat-radio-button .mat-radio-ripple {
    // Hide the ripple because its too large within the editor
    display: none;
  }

  /*
   * headings
   */
  h1 {
    font-size: $editor-h1-font-size;
  }

  h2 {
    font-size: $editor-h2-font-size;
  }

  h3 {
    font-size: $editor-h3-font-size;
  }

  h4 {
    font-size: $editor-h4-font-size;
  }

  h5 {
    font-size: $editor-h5-font-size;
  }

  h6 {
    font-size: $editor-h6-font-size;
  }

  /*
   * mc-central-container, p
   */
  .mc-central-container,
  p {
    margin-bottom: $editor-paragraph-margin-bottom;
  }

  /*
   * a
   */
  a {

    &:focus,
    &:hover {
      text-decoration: none; // Links aren't clickable in the editor so don't underline them when hovered/focused
    }
  }

  /*
   * hr
   */
  hr {
    margin: $editor-hr-spacing $spacing-none;
    border-top-style: solid;
    border-top-width: $border-width;
  }

  /*
   * lists
   */
  dl {
    margin-bottom: $spacing-none;
  }

  dd {
    margin-left: $editor-dl-item-spacing;
  }

  dd,
  dt {
    dl {
      margin-left: $editor-dl-item-spacing;
    }
  }

  ul,
  ol {
    padding-left: $editor-list-item-spacing;
  }

  li,
  dt,
  dd {
    margin-bottom: $editor-list-item-margin-bottom;
  }

  /*
   * tables
   */
  table {
    border-collapse: collapse;
    border-style: solid;
    border-width: $border-width;

    td,
    th {
      padding: $editor-table-cell-spacing;
      word-wrap: break-word;
      vertical-align: top;
    }

    thead,
    tfoot {
      border-style: solid;
      border-width: $border-width;
    }

    td+td,
    th+th {
      border-left-style: solid;
      border-left-width: $border-width;
    }

    tr+tr td,
    tr+tr th {
      border-top-style: solid;
      border-top-width: $border-width;
    }

    caption {
      text-align: center;
    }
  }

  /*
   * Table Layout
   * The table layout in the editor needs to stay within the editor's width.
   * Enforce this on tables that define their own width by overriding their styles with !important.
   */
  table {
    table-layout: fixed; // A fixed layout is used to make the table the width of the editor and for faster rendering
    overflow: hidden;
    width: 100% !important; // Keeps the table from growing wider than the editor

    td,
    th,
    col {
      width: auto !important; // Makes the columns fill the available space
    }
  }

  /*
   * code/pre
   */
  code,
  pre {
    margin-bottom: $editor-code-margin-bottom;
    padding: $editor-code-padding;
    border-radius: $border-radius-large;
    border-style: solid;
    border-width: $border-width;
    font-family: $monospace-font-family;
    white-space: pre-wrap;
  }

  pre>code {
    border-width: $border-none;
  }

  /*
   * fieldset
   */
  fieldset {
    margin: $spacing-none;
    padding: $spacing-none;
  }

  /*
   * body-nodes
   */
  .mc-pm-body-node {

    // Remove the top margin from the first element in the body (aka the first element in the doc)
    >*:first-child {
      margin-top: $spacing-none;
    }

    // Remove the bottom margin from the last element in the body (aka the last element in the doc)
    >*:last-child {
      margin-bottom: $spacing-none;
    }
  }

  /*
   * annotations
   */
  .mc-madcap-annotation {
    padding: $editor-annotation-spacing;
    border-radius: $editor-annotation-border-radius;
  }

  /*
   * changes
   */
  .mc-madcap-change-remove {
    text-decoration: line-through;
  }

  /*
   * snippet-blocks
   */
  .mc-snippet-node-view-block {
    margin-bottom: $editor-snippet-block-margin-bottom;
  }

  /*
   * page-breaks
   */
  .mc-madcap-page-break {
    margin-bottom: $editor-hr-spacing;
    padding: $editor-hr-spacing;
    -webkit-user-modify: read-only; // Fixes a bug in Chrome where the element is not selectable

    &:before {
      content: 'Page Break';
      font-size: $editor-node-name-font-size;
    }
  }

  /*
   * dropdowns
   */
  .mc-madcap-dropdown-head {
    display: inline-block;
    position: relative;
    margin-left: $editor-dropdown-guide-line-spacing + $editor-dropdown-body-left-padding + $editor-dropdown-guide-line-width - $editor-dropdown-head-overhang;

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - ($editor-dropdown-guide-arrow-width / 2));
      // Move the arrow left of the head to line up with the body's guide line.
      // Start at the same position as the body content by adding back the head's overhang.
      // Then subtract the body's left padding and the arrow's width to get the correct position.
      left: $editor-dropdown-head-overhang - ($editor-dropdown-body-left-padding + $editor-dropdown-guide-arrow-width);
      border-left-style: solid;
      border-left-width: $editor-dropdown-guide-arrow-width;
      border-right-style: solid;
      border-right-width: $editor-dropdown-guide-arrow-width;
      border-top-style: solid;
      border-top-width: $editor-dropdown-guide-arrow-width;
    }
  }

  .mc-madcap-dropdown-hotspot {
    display: inline-block; // To show the selection outline
  }

  .mc-madcap-dropdown-body {
    margin-left: $editor-dropdown-guide-line-spacing;
    border-left-style: solid;
    border-left-width: $editor-dropdown-guide-line-width;
    padding-left: $editor-dropdown-body-left-padding;
    padding-top: $editor-dropdown-guide-line-spacing;
  }

  /*
   * multiple choice
   */
  .mc-madcap-multiple-choice {
    margin-bottom: $editor-multiple-choice-margin-bottom;
  }

  .mc-madcap-submit-question-button {
    display: inline-block;
    text-align: center;
    border-style: solid;
    border-width: $border-width;
    border-radius: $border-radius-normal;
    padding: $editor-multiple-choice-submit-button-padding;

    // Remove bottom margins from children (like paragraphs) to make the spacing look correct
    >* {
      margin-bottom: $spacing-none;
    }
  }

  .mc-madcap-correct-feedback,
  .mc-madcap-incorrect-feedback {
    position: relative;
    padding-top: $editor-multiple-choice-feedback-padding-top;
    margin-bottom: $editor-multiple-choice-margin-bottom;
    border-style: dashed;
    border-width: $border-width;
    padding-left: $editor-multiple-choice-feedback-spacing;
    padding-right: $editor-multiple-choice-feedback-spacing;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      font-size: $editor-multiple-choice-feedback-title-font-size;
      padding-left: $editor-multiple-choice-feedback-spacing;
      padding-top: $editor-multiple-choice-feedback-spacing;
    }
  }

  .mc-madcap-correct-feedback {
    &:before {
      content: 'CORRECT FEEDBACK';
    }
  }

  .mc-madcap-incorrect-feedback {
    &:before {
      content: 'INCORRECT FEEDBACK';
    }
  }

  /*
   * Variable for 'name box elements' selector
   */
  $name-box-elements: '\
  .mc-pm-form,\
  .mc-pm-button,\
  .mc-pm-iframe,\
  .mc-pm-input,\
  .mc-pm-script,\
  .mc-pm-svg,\
  .mc-pm-template,\
  .mc-madcap-multimedia,\
  .mc-madcap-shortcut,\
  .mc-madcap-qr-code,\
  .mc-madcap-equation,\
  .mc-madcap-body-proxy,\
  .mc-madcap-breadcrumbs-proxy,\
  .mc-madcap-central-account-proxy,\
  .mc-madcap-topic-toolbar-proxy,\
  .mc-madcap-mini-toc-proxy,\
  .mc-madcap-toc-proxy,\
  .mc-madcap-index-proxy,\
  .mc-madcap-list-of-proxy,\
  .mc-madcap-concepts-proxy,\
  .mc-madcap-glossary-proxy,\
  .mc-madcap-relationships-proxy,\
  .mc-madcap-endnotes-proxy,\
  .mc-madcap-search-bar-proxy,\
  .mc-madcap-menu-proxy,\
  .mc-madcap-search-results-proxy,\
  .mc-madcap-e-learning-toolbar-proxy,\
  .mc-madcap-test-results-proxy,\
  .mc-madcap-faq-proxy,\
  .mc-madcap-knowledge-proxy,\
  .mc-madcap-promotion-proxy,\
  .mc-madcap-slideshow,\
  .mc-madcap-model-3d,\
  .mc-madcap-footnotes-block,\
  .mc-madcap-footnote-block,\
  .mc-madcap-endnotes-block,\
  .mc-madcap-endnote-block,\
  .mc-madcap-concept-link,\
  .mc-madcap-keyword-link,\
  .mc-madcap-related-topics,\
  // .mc-madcap-form-fields,\
  .mc-madcap-page-header,\
  .mc-madcap-page-footer,\
  .mc-mml-math\
  ';

  /*
   * conditions
   */
  .mc-madcap-conditional-text,
  [madcap\:conditions] {
    border-style: dashed;
    border-width: $border-width;
  }

  .mc-madcap-conditional-text,
  [madcap\:conditions]:not(

    /* name box elements */
    #{$name-box-elements},
    /* list items */
    ol,
    ul,
    mc-multiple-choice-item-node-view,
    /* others */
    .mc-madcap-submit-question-button,
    .mc-madcap-page-break,
    .mc-madcap-concept,
    .mc-madcap-keyword) {
    padding-left: $editor-condition-horizontal-spacing;
    padding-right: $editor-condition-horizontal-spacing;
  }

  mc-image-node-view[madcap\:conditions] {
    padding: $editor-condition-image-spacing;
  }

  hr[madcap\:conditions] {
    height: ($editor-hr-spacing * 0.5);
  }

  .mc-madcap-condition-bg-decoration-marker:not(mc-dynamic-view):before,
  .mc-madcap-condition-bg-decoration-marker>.mc-node-view>.mc-madcap-variable:before,
  .mc-madcap-condition-bg-decoration-marker>mc-image-node-view:before,
  .mc-madcap-condition-bg-decoration-marker-a:after {
    content: ' ';
    display: inline-flex;
    align-items: center;
    white-space: pre;
    border-style: solid;
    border-width: $border-width-thin;
    height: $editor-condition-indicator-height;
    width: $editor-condition-indicator-width;
    margin-right: $editor-condition-horizontal-spacing;
  }

  .mc-madcap-condition-bg-decoration-marker>mc-image-node-view {
    overflow: hidden; // used to hide the conditional indicator of small images
  }

  .mc-madcap-condition-bg-decoration-marker>mc-image-node-view:before {
    // Place the image conditional indicator in the upper right corner.
    // But if conditional indicator dimension is more than 1/2 of mc-image-node-view dimension (width or height),
    // then hide the conditional indicator by moving it to invisible area.
    position: absolute;
    top: calc($editor-condition-indicator-height * 0.5 - max(0px, ($editor-condition-indicator-height * 2 - 100%)*999));
    left: calc(100% - $editor-condition-indicator-width * 1.5 + max(0px, ($editor-condition-indicator-width * 2 - 100%)*999));
  }

  /*
   * dir
   */
  .mc-pm-dir .mc-pm-li {
    list-style-type: none;
  }

  /*
   * bracketed elements
   */
  // inline elements
  .mc-pm-abbr,
  .mc-pm-acronym,
  .mc-pm-bdi,
  .mc-pm-bdo,
  .mc-pm-big,
  .mc-pm-cite,
  .mc-pm-del,
  .mc-pm-dfn,
  .mc-pm-ins,
  .mc-pm-kbd,
  .mc-pm-label,
  .mc-pm-mark,
  .mc-pm-q,
  .mc-pm-rp,
  .mc-pm-ruby,
  .mc-pm-small,
  .mc-pm-span,
  .mc-pm-strike,
  .mc-pm-sub,
  .mc-pm-sup,
  .mc-pm-time,
  .mc-madcap-conditional-text,
  .mc-madcap-footnote,
  .mc-madcap-footnote-block,
  .mc-madcap-footnotes-block,
  .mc-madcap-toggler,
  .mc-madcap-popup-body,
  .mc-madcap-popup-head,
  .mc-madcap-expanding-body,
  .mc-madcap-expanding-head,
  .mc-madcap-glossary-term,
  .mc-madcap-variable,
  // block elements
  .mc-pm-address,
  .mc-pm-article,
  .mc-pm-aside,
  .mc-pm-base,
  .mc-pm-center,
  .mc-pm-comment,
  .mc-pm-details,
  .mc-pm-div,
  .mc-pm-dl,
  .mc-pm-fieldset,
  .mc-pm-footer,
  .mc-pm-header,
  .mc-pm-main,
  .mc-pm-map,
  .mc-pm-marquee,
  .mc-pm-menu,
  .mc-pm-meter,
  .mc-pm-nav,
  .mc-pm-progress,
  .mc-pm-samp,
  .mc-pm-section,
  .mc-pm-select,
  .mc-pm-textarea,
  .mc-pm-var,
  .mc-madcap-micro-content,
  .mc-madcap-code-snippet-caption,
  .mc-madcap-code-snippet-body {
    @include mc-editor-brackets;
  }

  /*
   * bracketed inline elements
   */
  .mc-pm-abbr,
  .mc-pm-acronym,
  .mc-pm-bdi,
  .mc-pm-bdo,
  .mc-pm-big,
  .mc-pm-cite,
  .mc-pm-del,
  .mc-pm-dfn,
  .mc-pm-ins,
  .mc-pm-kbd,
  .mc-pm-label,
  .mc-pm-mark,
  .mc-pm-q,
  .mc-pm-rp,
  .mc-pm-ruby,
  .mc-pm-small,
  .mc-pm-span,
  .mc-pm-strike,
  .mc-pm-sub,
  .mc-pm-sup,
  .mc-pm-time,
  .mc-madcap-conditional-text,
  .mc-madcap-footnote,
  .mc-madcap-footnote-block,
  .mc-madcap-footnotes-block,
  .mc-madcap-toggler,
  .mc-madcap-popup-body,
  .mc-madcap-popup-head,
  .mc-madcap-expanding-body,
  .mc-madcap-expanding-head,
  .mc-madcap-glossary-term,
  .mc-madcap-variable {
    @include mc-editor-inline-brackets;
  }

  /*
   * name box elements
   */
  #{$name-box-elements} {
    @include mc-editor-name-box;
  }

  /*
   * name box block elements
   */
  .mc-pm-form,
  .mc-madcap-body-proxy,
  .mc-madcap-breadcrumbs-proxy,
  .mc-madcap-central-account-proxy,
  .mc-madcap-topic-toolbar-proxy,
  .mc-madcap-mini-toc-proxy,
  .mc-madcap-toc-proxy,
  .mc-madcap-index-proxy,
  .mc-madcap-list-of-proxy,
  .mc-madcap-concepts-proxy,
  .mc-madcap-glossary-proxy,
  .mc-madcap-relationships-proxy,
  .mc-madcap-endnotes-proxy,
  .mc-madcap-search-bar-proxy,
  .mc-madcap-menu-proxy,
  .mc-madcap-search-results-proxy,
  .mc-madcap-e-learning-toolbar-proxy,
  .mc-madcap-test-results-proxy,
  .mc-madcap-faq-proxy,
  .mc-madcap-knowledge-proxy,
  .mc-madcap-promotion-proxy,
  .mc-madcap-slideshow,
  .mc-madcap-page-header,
  .mc-madcap-page-footer {
    @include mc-editor-block-name-box;
  }

  /*
   * star elements
   */
  .icon-star {
    @include mc-editor-star;
  }

  /*
   * bookmark elements
   */
  .icon-bookmark {
    @include mc-editor-bookmark;
  }

  /*
   * multimedia elements
   */
  .mc-madcap-multimedia,
  .mc-madcap-model-3d {
    max-width: 100%; // Make sure the multimedia doesn't overflow the editor
    overflow: hidden; // Do not let the content overflow the multimedia element when its really small
  }

  /*
   * target elements
   */
  .mc-madcap-target-name:before {
    margin-right: $editor-target-spacing; // Give some space between the icon and the content
  }

  /*
   * gutter decorations
   */
  .mc-editor-gutter-target {
    // Make the gutter item's target appear on top of the other elements in the doc
    position: relative;
    z-index: 1;

    &.mc-editor-gutter-inline-target {
      border-radius: $editor-annotation-border-radius;
    }

    &.mc-editor-gutter-annotation-target {
      padding: $editor-annotation-spacing $spacing-none;

      &.mc-madcap-annotation {
        padding: $editor-annotation-spacing;
      }
    }
  }
}

.mc-pm-document .mc-editor-image-upload-error-tooltip {
  position: absolute;
  margin-bottom: $spacing-xx-small;
  transform: translateX(-50%);
  white-space: pre-line;
}

.mc-editor-gutter-canvas {
  position: absolute;
  pointer-events: none;

  .mc-editor-gutter-line {
    stroke-width: 1px;
    fill: none;
  }
}

// Needed to override normalize.less. This can be removed after the hybrid AJS files are gone
svg.mc-editor-gutter-canvas {
  overflow: visible;
}
