.mc-material {
  .mc-toolbar-header,
  .page-card-header {
    flex-shrink: 0;
    flex-grow: 0;

    .mat-toolbar-row,
    .mat-toolbar-single-row,
    &.mat-toolbar-row,
    &.mat-toolbar-single-row {
      height: $toolbar-height-header;
      padding-left: $toolbar-spacing-header;
      padding-right: $toolbar-spacing-header;
    }

    /*
    * Adjust the first and last buttons in the toolbar to account for their internal padding
    */
    .mat-toolbar,
    &.mat-toolbar {
      /*
      * First Button
      */
      > .mc-disabled-button-tooltip:first-child .mat-button:not(.mc-toolbar-item-full.mc-toolbar-item-full-start),
      > .mat-button:first-child:not(.mc-toolbar-item-full.mc-toolbar-item-full-start) {
        margin-left: -$button-horizontal-spacing; // Adjust the spacing on the left of the toolbar to account for the button's spacing
      }

      > .mc-disabled-button-tooltip:first-child .mat-icon-button,
      > .mat-icon-button:first-child {
        margin-left: -$icon-button-horizontal-spacing; // Adjust the spacing on the right of the toolbar to account for the button's spacing
      }

      /*
      * Last Button
      */
      > .mc-disabled-button-tooltip:last-child .mat-button:not(.mc-toolbar-item-full.mc-toolbar-item-full-end),
      > .mat-button:last-child:not(.mc-toolbar-item-full.mc-toolbar-item-full-end) {
        margin-right: -$button-horizontal-spacing; // Adjust the spacing on the left of the toolbar to account for the button's spacing
      }

      > .mc-disabled-button-tooltip:last-child .mat-icon-button,
      > .mat-icon-button:last-child {
        margin-right: -$icon-button-horizontal-spacing; // Adjust the spacing on the right of the toolbar to account for the button's spacing
      }
    }
  }
}
