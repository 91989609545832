$app-nav-width: 20rem !default;
$app-nav-spacing: $spacing-normal !default;

$page-toolbar-left-padding: 1.6rem !default;
$page-head-width: $app-nav-width - $page-toolbar-left-padding !default;

$page-gutter-width: 25.8rem !default;
$page-dashboard-max-width: 146.4rem !default;
$page-minor-card-width: 30rem !default;
$page-major-card-max-width: 108rem !default;
$page-spacing: $spacing-normal !default;

$page-gutter-spacing: $page-spacing !default;
$page-gutter-margin: $page-gutter-spacing * .5 !default;

$page-fill-editor-major-card-max-width: 130rem !default;
$page-fill-editor-minor-card-max-width: 50rem !default;
