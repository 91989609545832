.mc-material {
  .mc-toolbar-text {
    // inline-flex + align-items for centering the content
    display: inline-flex;
    align-items: center;
    // height for making the tooltip line up with button tooltips
    height: $toolbar-text-height;
    // truncate text
    overflow: hidden;

    // truncate text
    .mc-toolbar-text-truncate {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  /**
   * mc-toolbar-item-full
   */
  .mc-toolbar-item-full {
    height: 100%;

    > .mat-button,
    > .mat-flat-button,
    &.mat-button,
    &.mat-flat-button {
      height: 100%;
      border-radius: $border-radius-none;
      font-size: inherit;
    }
  }

  // Links do not auto-center their content like buttons so do it with flexbox
  a.mc-toolbar-item-full {
    &.mat-button,
    &.mat-flat-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mc-toolbar-item-full.mc-toolbar-item-full-start {
    margin-left: -$toolbar-spacing-header; // Move the item to the edge of the toolbar
  }

  .mc-toolbar-item-full.mc-toolbar-item-full-end {
    margin-right: -$toolbar-spacing-header; // Move the item to the edge of the toolbar
  }

  /**
  * mc-toolbar-inline
  */
  .mc-toolbar-inline {
    &.mat-toolbar-row,
    &.mat-toolbar-single-row {
      display: inline-flex;
      width: auto;
    }
  }

  .mc-toolbar-small {
    &.mat-toolbar-row,
    &.mat-toolbar-single-row {
      height: $toolbar-height-small;
      font-size: $font-size-small;
    }

    .mc-toolbar-text {
      height: $toolbar-text-height-small;
    }
  }

  .mc-toolbar-normal {
    &.mat-toolbar-row,
    &.mat-toolbar-single-row {
      height: $toolbar-height-normal;
      font-size: $font-size-normal;
    }

    .mc-toolbar-text {
      height: $toolbar-text-height-normal;
    }
  }

  .mc-toolbar-large {
    &.mat-toolbar-row,
    &.mat-toolbar-single-row {
      height: $toolbar-height-large;
    }
  }

  .mat-divider.mc-toolbar-divider-small {
    height: $toolbar-height-small;
    margin-right: $spacing-x-small;
    margin-left: $spacing-x-small;
  }

  .mat-divider.mc-toolbar-divider {
    margin-right: $spacing-small;
    margin-left: $spacing-small;
  }

  .mc-toolbar-spacer-x-small,
  .mc-toolbar-spacer-small,
  .mc-toolbar-spacer,
  .mc-toolbar-spacer-large,
  .mc-toolbar-spacer-x-large,
  .mc-toolbar-spacer-xx-large,
  .mc-toolbar-spacer-divider,
  .mc-toolbar-spacer-tab {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .mc-toolbar-spacer-x-small {
    flex-basis: $spacing-xxx-small;
    width: $spacing-xxx-small;
  }

  .mc-toolbar-spacer-small {
    flex-basis: $spacing-xx-small;
    width: $spacing-xx-small;
  }

  .mc-toolbar-spacer,
  .mc-toolbar-spacer-divider {
    flex-basis: $spacing-x-small;
    width: $spacing-x-small;
  }

  .mc-toolbar-spacer-large {
    flex-basis: $spacing-small;
    width: $spacing-small;
  }

  .mc-toolbar-spacer-x-large {
    flex-basis: $spacing-normal;
    width: $spacing-normal;
  }

  .mc-toolbar-spacer-xx-large {
    flex-basis: $spacing-large;
    width: $spacing-large;
  }

  .mc-toolbar-spacer-tab {
    flex-basis: $toolbar-tab-spacing;
    width: $toolbar-tab-spacing;
  }
}
