@use 'sass:math';

/**
 * Removes the unit of a CSS length string.
 * @param {Number} $number - Number to remove the unit from
 * @return {Number} - Unitless number
 *
 * Taken from https://css-tricks.com/snippets/sass/strip-unit-function/
 */
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}

@function calc-relative-length($font-size-px, $font-size-em) {
  @return $font-size-px * strip-unit($font-size-em);
}

@function rem-to-px($size-rem) {
  @return 10px * strip-unit($size-rem);
}
