.mc-d-flex {
  display: flex;
}

.mc-d-inline-flex {
  display: inline-flex;
}

.mc-flex-row {
  flex-direction: row;
}

.mc-flex-column {
  flex-direction: column;
}

.mc-flex-wrap {
  flex-wrap: wrap;
}

.mc-flex-nowrap {
  flex-wrap: nowrap;
}

.mc-justify-content-start {
  justify-content: flex-start;
}

.mc-justify-content-end {
  justify-content: flex-end;
}

.mc-justify-content-center {
  justify-content: center;
}

.mc-justify-content-between {
  justify-content: space-between;
}

.mc-justify-content-around {
  justify-content: space-around;
}

.mc-align-items-start {
  align-items: flex-start;
}

.mc-align-items-end {
  align-items: flex-end;
}

.mc-align-items-center {
  align-items: center;
}

.mc-align-items-baseline {
  align-items: baseline;
}

.mc-align-items-stretch {
  align-items: stretch;
}

.mc-align-content-start {
  align-content: flex-start;
}

.mc-align-content-end {
  align-content: flex-end;
}

.mc-align-content-center {
  align-content: center;
}

.mc-align-content-between {
  align-content: space-between;
}

.mc-align-content-around {
  align-content: space-around;
}

.mc-align-content-stretch {
  align-content: stretch;
}

.mc-align-self-start {
  align-self: flex-start;
}

.mc-align-self-end {
  align-self: flex-end;
}

.mc-align-self-center {
  align-self: center;
}

.mc-flex-shrink-0 {
  flex-shrink: 0;
}

.mc-flex-grow-1 {
  flex-grow: 1;
}
