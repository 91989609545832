.mc-material {
  .mat-input-element {
    line-height: $input-line-height-normal;
  }

  // fixes the height of the time input.
  .mat-input-element[type='date'],
  .mat-input-element[type='datetime'],
  .mat-input-element[type='datetime-local'],
  .mat-input-element[type='month'],
  .mat-input-element[type='week'],
  .mat-input-element[type='time'] {
    &::after {
      content: '';
    }
  }
}