@use '@angular/material' as mat;
@mixin mc-mat-select-theme ($theme, $typography) {
  .mat-select-panel {
    @include _mc-mat-select($theme, 'primary');
  }
}

@mixin _mc-mat-select ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  &.mat-#{$palette-name} {
    .mat-option.mat-selected:not(.mat-option-disabled) {
      color: mat.get-color-from-palette($palette, default);
    }
  }

  &.mat-#{$palette-name}-muted {
    .mat-option.mat-selected:not(.mat-option-disabled) {
      color: mat.get-color-from-palette($palette, lighter);
    }
  }

  &.mat-#{$palette-name}-vivid {
    .mat-option.mat-selected:not(.mat-option-disabled) {
      color: mat.get-color-from-palette($palette, darker);
    }
  }
}
