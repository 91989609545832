// Apply the base typography
@use '@angular/material' as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($mc-base-typography);`
@include mat.all-legacy-component-typographies($mc-base-typography);
@include mat.legacy-core();

// Create the theme styles for each theme
@each $theme in $themes {
  $theme-core: map-get($theme, core);

  .#{get-theme-class-name($theme)} {
    @include mat.all-legacy-component-themes($theme-core);
    @include mc-theme($theme-core, map-get($theme, typography));
  }
}
