@use '@angular/material' as mat;
@mixin mc-page-drawers-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $whats-new-color: mat.get-color-from-palette(map-get($theme, warn));
  $system-message-color: mat.get-color-from-palette(map-get($theme, caution));

  .page-drawer {
    background: mat.get-color-from-palette($background, background-invert);
    color: mat.get-color-from-palette($primary);

    .mat-expansion-panel {
      background: transparent;

      .mat-nav-list,
      .mat-action-list {
        .mat-list-item {
          font-size: $font-size-small;
          font-weight: $font-weight-light;
        }
      }
    }

    .mat-expansion-panel-header-title,
    .mat-nav-list .mat-list-item,
    .mat-action-list .mat-list-item,
    .mat-expansion-indicator::after {
      color: mat.get-color-from-palette($primary);
    }

    .mat-nav-list .mat-list-item {
      &.mc-system-message-link {
        color: $system-message-color;
      }

      &.mc-whats-new-link {
        color: $whats-new-color;
      }
    }

    .mat-list-base .mat-subheader {
      color: mat.get-color-from-palette($foreground, text-invert);
    }

    mc-project-avatar,
    mc-team-avatar,
    mc-license-user-avatar {
      &.mc-avatar-link {
        color: mat.get-color-from-palette($foreground, text-invert);
      }
    }

    .mat-divider {
      border-top-color: mat.get-color-from-palette($primary);
    }

    // Normally unchecked radio buttons have the same color checkbox as the drawer so always make the outer circle primary so its visible
    .mat-radio-button {
      .mat-radio-outer-circle {
        border-color: mat.get-color-from-palette($primary, default);
      }
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']) {
      &:hover {
        background: mat.get-color-from-palette($background, background-invert-hover);
      }
    }
  }
}
