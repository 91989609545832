/*
 * A mixin to theme material buttons with our own palettes.
 */
@use '@angular/material' as mat;

@mixin mc-mat-button-toggles-theme ($theme, $typography) {
  .mat-button-toggle {
    @include _mc-mat-button-toggle($theme, 'primary');
    font-size: mat.font-size($typography, button);
    font-weight: mat.font-weight($typography, button);
  }
}

@mixin _mc-mat-button-toggle ($theme, $button-name) {
  $foreground: map-get($theme, foreground);
  $divider-color: mat.get-color-from-palette($foreground, divider);
  $palette: map-get($theme, $button-name);

  .mat-button-toggle-button {
    width: 4rem;
    height: 4rem;
  }

  &.mat-#{$button-name} {
    color: mat.get-color-from-palette($palette, default);
    background: transparent;

    .mat-button-toggle-focus-overlay {
      background-color: mat.get-color-from-palette($palette, default);
    }
  }

  &.mat-button-toggle-checked {
    color: mat.get-color-from-palette($palette, default);
  }

  &.mat-button-toggle-disabled {
    color: mat.get-color-from-palette($foreground, disabled-button);
    background-color: transparent;

    .mat-button-toggle-focus-overlay {
      background-color: transparent;
    }
  }

  &.mat-button-toggle-standalone.mat-#{$button-name} {
    border: $spacing-none;
    box-shadow: none;
  }

  .mat-button-toggle-label-content {
    line-height: 25px;
    padding: $spacing-none;
  }
}
