.mc-notification-list {
  position: relative;

  .mc-notification-list-footer {
    position: relative;
    padding: $spacing-large;
    text-align: center;
  }

  .mc-notification-list-loading-progress-bar,
  .mc-notification-list-loading-error {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .mc-notification-list-loading-error {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
}
