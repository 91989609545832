@use '@angular/material' as mat;

@mixin mc-report-count-widgets-theme ($theme, $typography) {
  $report-broken-bookmarks: map-get($theme, report-broken-bookmarks);
  $report-broken-links: map-get($theme, report-broken-links);
  $report-broken-snippet-links: map-get($theme, report-broken-snippet-links);
  $report-undefined-condition-tags: map-get($theme, report-undefined-condition-tags);
  $report-undefined-file-tags: map-get($theme, report-undefined-file-tags);
  $report-undefined-glossary-terms: map-get($theme, report-undefined-glossary-terms);
  $report-undefined-variables: map-get($theme, report-undefined-variables);

  .mc-report-count-widget {
    .mc-widget {
      &.mc-report-count-widget-broken-bookmarks {
        background: mat.get-color-from-palette($report-broken-bookmarks, default);
        color: mat.get-color-from-palette($report-broken-bookmarks, default-contrast);

        mc-project-avatar,
        .mat-toolbar {
          color: mat.get-color-from-palette($report-broken-bookmarks, default-contrast);
        }
      }

      &.mc-report-count-widget-broken-links {
        background: mat.get-color-from-palette($report-broken-links, default);
        color: mat.get-color-from-palette($report-broken-links, default-contrast);

        mc-project-avatar,
        .mat-toolbar {
          color: mat.get-color-from-palette($report-broken-links, default-contrast);
        }
      }

      &.mc-report-count-widget-broken-snippet-links {
        background: mat.get-color-from-palette($report-broken-snippet-links, default);
        color: mat.get-color-from-palette($report-broken-snippet-links, default-contrast);

        mc-project-avatar,
        .mat-toolbar {
          color: mat.get-color-from-palette($report-broken-snippet-links, default-contrast);
        }
      }

      &.mc-report-count-widget-undefined-condition-tags {
        background: mat.get-color-from-palette($report-undefined-condition-tags, default);
        color: mat.get-color-from-palette($report-undefined-condition-tags, default-contrast);

        mc-project-avatar,
        .mat-toolbar {
          color: mat.get-color-from-palette($report-undefined-condition-tags, default-contrast);
        }
      }

      &.mc-report-count-widget-undefined-file-tags {
        background: mat.get-color-from-palette($report-undefined-file-tags, default);
        color: mat.get-color-from-palette($report-undefined-file-tags, default-contrast);

        mc-project-avatar,
        .mat-toolbar {
          color: mat.get-color-from-palette($report-undefined-file-tags, default-contrast);
        }
      }

      &.mc-report-count-widget-undefined-glossary-terms {
        background: mat.get-color-from-palette($report-undefined-glossary-terms, lighter);
        color: mat.get-color-from-palette($report-undefined-glossary-terms, lighter-contrast);

        mc-project-avatar,
        .mat-toolbar {
          color: mat.get-color-from-palette($report-undefined-glossary-terms, lighter-contrast);
        }
      }

      &.mc-report-count-widget-undefined-variables {
        background: mat.get-color-from-palette($report-undefined-variables, default);
        color: mat.get-color-from-palette($report-undefined-variables, default-contrast);

        mc-project-avatar,
        .mat-toolbar {
          color: mat.get-color-from-palette($report-undefined-variables, default-contrast);
        }
      }

      // We don't want to change the color of links because it looks bad with the background colors
      a {
        color: inherit;
      }
    }
  }
}
