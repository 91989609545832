@mixin mc-arrow-tabs-nub-color($arrow-tab-color) {
  .mc-arrow-tabs {
    .mat-tab-header .mat-tab-label,
    .mat-tab-links .mat-tab-link {
      &.mat-tab-label-active:after {
        background-color: $arrow-tab-color;
      }
    }
  }
}
