@use '@angular/material' as mat;
@mixin mc-navbars-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);

  .navbar-container {
    &.mat-drawer-container {
      background: mat.get-color-from-palette($background, navbar);
      color: mat.get-color-from-palette($foreground, navbar);
    }
  }

  .navbar {
    // Gives a background to a dropdown's button
    .button-dropdown {
      background: mat.get-color-from-palette($background, navbar-light);
    }

    .navbar-heading {
      background: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($foreground, text);
    }

    .mc-navbar-drawer-toggle:disabled {
      color: inherit;
    }

    // Change the arrow-tabs to use the body bg color for the nub color
    @include mc-arrow-tabs-nub-color(mat.get-color-from-palette($background, body));
  }
}
