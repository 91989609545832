.mc-arrow-tabs {
  @include mc-arrow-tabs-nub;

  .mat-tab-label,
  .mat-tab-link {
    min-width: 0; // Let arrow tabs be any width

    &.mat-tab-label-active:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  // Hide the ink bar since the arrow nub is used instead
  .mat-ink-bar {
    display: none;
  }
}

// Displays arrows tabs with a bottom border
.mc-arrow-tabs-border {

  // The border under the tab labels
  .mat-tab-list {
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: -1; // To place this divider line under the arrow
      border-bottom: solid $border-width;
    }
  }

  // The border on the arrow nub
  .mat-tab-label,
  .mat-tab-link {
    &.mat-tab-label-active:after {
      border-top: solid $border-width;
      border-left: solid $border-width;
      z-index: 1; // To place the arrow over the divider line along the bottom of the tabs
    }
  }
}

.mc-header-less-tabs>.mat-tab-header {
  display: none;
}

/**
 * Spring board tabs
 */
.mc-spring-board-body.mat-tab-group,
.mc-spring-board-form-body.mat-tab-group {
  >.mat-tab-body-wrapper {
    // Fill all the space available. Otherwise mat-tab-body-wrapper is the height of the active mat-tab-body. If that mat-tab-body is shorter than another tab then the slide animation will cut off the other tab until the animation is complete.
    flex-grow: 1;
  }

  +.mc-spring-board-footer,
  +.mc-spring-board-form-footer {
    margin-top: $spacing-none;
    padding: $spacing-normal;
  }
}