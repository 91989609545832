.mc-material {
  .mat-menu-item {
    padding-left: $list-item-horizontal-spacing;
    padding-right: $list-item-horizontal-spacing;

    // Aligns icons correctly with text
    .mat-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  // Aligns icons correctly with text
  button.mat-menu-item {
    display: flex;
    align-items: center;
  }

  /**
   * Improves the vertical alignment of icons in mat-menu
   */
  .mat-menu-item {
    // Center the content of the menu item element
    display: flex;
    align-items: center;

    // Center the content of the icon element. This is required to center the font-icon glyph.
    .mat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
