.mc-badge-empty {
  /*
   * Specific adjustments for different icons since they do not fill the same amount of space in a mat-icon
   */
  &.mat-icon {
    &.mat-badge-overlap {
      // mat-badge-after
      &.mat-badge-after {
        // icon-mail
        &.icon-mail {
          &.mat-badge-small {
            .mat-badge-content {
              top: 0;
              right: -.1rem;
            }
          }
        }

        // icon-bell
        &.icon-bell {
          &.mat-badge-small {
            .mat-badge-content {
              top: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
}
