@use '@angular/material' as mat;
@mixin mc-pages-theme ($theme, $typography) {
  $background: map-get($theme, background);

  .page-dashboard {
    background: none;
  }

  .page-gutter {
    background: none;

    .mat-expansion-panel {
      background: mat.get-color-from-palette($background, page-gutter);
    }
  }
}
