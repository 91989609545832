@use '@angular/material' as mat;
@mixin mc-scrollbars-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mc-scrollbar {
    &::-webkit-scrollbar-track {
      background: mat.get-color-from-palette($background, scrollbar);
    }

    &::-webkit-scrollbar-thumb {
      background: mat.get-color-from-palette($foreground, scrollbar);
    }
  }
}
