.mc-pill {
  display: inline-flex;
  align-items: center;
  max-height: 100%;
  padding: $spacing-none .2em;
  border-radius: $border-radius-normal;
}

.mc-pill-uppercase {
  text-transform: uppercase;
  padding-top: .2em;
  padding-bottom: .2em;
  line-height: 1;
}

.mc-pill-x-small {
  font-size: $font-size-x-small;
}

.mc-pill-small {
  font-size: $font-size-small;
}

.mc-pill-normal {
  font-size: $font-size-normal;
}

.mc-pill-large {
  font-size: $font-size-large;
}

.mc-pill-x-large {
  font-size: $font-size-x-large;
}
