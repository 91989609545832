$font-icon-path: '~/assets/fonts/project-titan' !default;
$font-size-image-icon-xxxx-small: 1.6rem !default;
$font-size-image-icon-xxx-small: 1.8rem !default;
$font-size-image-icon-xx-small: 2.9rem !default;
$font-size-image-icon-x-small: 3.8rem !default;
$font-size-image-icon-normal: 8rem !default;
$font-size-image-icon-large: 11rem !default;
$font-size-image-icon-x-large: 16rem !default;
$font-size-image-icon-xx-large: 20rem !default;
$font-size-image-icon-xxx-large: 27rem !default;
