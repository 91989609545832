.mc-hidden {
  display: none !important;
}

.mc-visibly-hidden {
  visibility: hidden;
}

.mc-overflow-hidden {
  overflow: hidden;
}

.mc-visibly-hidden-out-of-flow {
  position: absolute !important;
  visibility: hidden;
}

.mc-fill-remaining-flex-space {
  flex: 1 1 auto;
}

.mc-position-relative {
  position: relative;
}

.mc-width-100 {
  width: 100%;
}

.mc-height-100 {
  height: 100%;
}

.mc-min-width-0 {
  min-width: 0;
}

.mc-min-height-0 {
  min-height: 0;
}
