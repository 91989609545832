// Font Weight
$font-weight-light: 300 !default;
$font-weight-medium: 400 !default;
$font-weight-heavy: 700 !default;
$font-weight-normal: $font-weight-medium !default;

// Font Size
$root-font-size: 62.5% !default;
$font-size-xxx-small: .5rem !default;
$font-size-xx-small: .7rem !default;
$font-size-x-small: 1rem !default;
$font-size-small: 1.2rem !default;
$font-size-normal: 1.4rem !default;
$font-size-large: 1.6rem !default;
$font-size-x-large: 2.2rem !default;
$font-size-xx-large: 2.5rem !default;
$font-size-xxx-large: 2.8rem !default;
$font-size-xxxx-large: 4.9rem !default;
$font-size-xxxxx-large: 5.6rem !default;

// Font Size in pixel values. Only to be used in calculations
$font-size-normal-px: 14px !default;
$font-size-large-px: 16px !default;

$sup-font-size: .65em !default;

$line-height-normal: 1.215 !default;
$input-line-height-normal: 1.25 !default;

$line-height-normal-em: 1.215em !default;
$computed-line-height-normal: $font-size-normal * $line-height-normal;

$text-space-width: .28em; // Roughly the width of a space

// Global Defaults (applied to the body tag)
$default-font-weight: $font-weight-medium !default;
$default-font-size: $font-size-normal !default;
$default-line-height: $line-height-normal !default;
$default-font-family: 'Roboto, "Helvetica Neue", sans-serif' !default;
$monospace-font-family: 'ui-monospace', 'SFMono-Regular', 'SF Mono', 'Menlo', 'Consolas', 'Liberation Mono', 'monospace' !default;
