@use '@angular/material' as mat;

@mixin mc-dimension-pickers-theme ($theme, $typography) {
  $basic: map-get($theme, basic);
  $primary: map-get($theme, primary);

  .mc-dimension-picker {
    .mc-dimension-picker-container {
      &.mat-menu-item {
        // Override the background color when the picker is hovered/focused so that it doesn't change
        &:hover:not([disabled]),
        &.cdk-program-focused:not([disabled]),
        &.cdk-keyboard-focused:not([disabled]) {
          background: transparent;
        }
      }
    }

    .mc-dimension-picker-highlighted {
      .mc-dimension-picker-square {
        fill: mat.get-color-from-palette($primary);
        stroke: mat.get-color-from-palette($primary, darker);
      }
    }

    .mc-dimension-picker-unhighlighted {
      .mc-dimension-picker-square {
        fill: mat.get-color-from-palette($basic, lighter);
        stroke: mat.get-color-from-palette($basic);
      }
    }
  }
}
