@use '@angular/material' as mat;
@mixin mc-avatars-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $info-2: map-get($theme, info-2);

  .mc-avatar-container {
    background: mat.get-color-from-palette($background, avatar);
    color: mat.get-color-from-palette($foreground, avatar);
    border-color: mat.get-color-from-palette($foreground, avatar);
    outline: $outline-none;
  }

  button,
  a {
    &.mc-avatar-container {
      &:not(:disabled) {
        &:focus,
        &:hover {
          border-color: mat.get-color-from-palette($foreground, link);
        }
      }
    }
  }

  .mc-avatar-has-image {
    .mc-avatar-container {
      background: mat.get-color-from-palette($foreground, avatar);
    }
  }

  .mc-avatar-has-svg {
    .mc-avatar-container {
      // Remove the background from mc-avatar-container since this avatar applies its own color
      background: none;

      &:not(:disabled) {
        &:focus,
        &:hover {
          .mc-avatar-svg-active-fill {
            fill: mat.get-color-from-palette($foreground, link);
          }

          .mc-avatar-svg-active-stroke {
            stroke: mat.get-color-from-palette($foreground, link);
          }
        }
      }
    }
  }

  .mc-avatar-has-icon {
    .mc-avatar-container:not(:disabled) {
      &:focus,
      &:hover {
        color: mat.get-color-from-palette($foreground, link);
      }
    }
  }

  .mc-text-avatar {
    color: mat.get-color-from-palette($foreground, avatar);
  }

  .mc-stroke-avatar {
    stroke: mat.get-color-from-palette($foreground, avatar);
  }

  .mc-fill-avatar {
    fill: mat.get-color-from-palette($background, avatar);
  }

  .mc-text-avatar-invert {
    color: mat.get-color-from-palette($foreground, avatar-invert);
  }

  .mc-stroke-avatar-invert {
    stroke: mat.get-color-from-palette($foreground, avatar-invert);
  }

  .mc-fill-avatar-invert {
    fill: mat.get-color-from-palette($background, avatar-invert);
  }

  .mc-text-avatar-inherit {
    color: inherit;
  }

  .mc-avatar-empty {
    .mc-avatar-container {
      background-color: mat.get-color-from-palette($background, avatar-empty);
    }
  }

  .mc-avatar-name-disabled {
    color: mat.get-color-from-palette($foreground, text);
  }

  .mc-avatar-indicator-icon {
    background: mat.get-color-from-palette($background, component);
    border-color: mat.get-color-from-palette($background, component);
  }

  /**
   * mc-avatar-deleted
   * The styles applied to an avatar when the entity it represents is deleted.
   */
  .mc-avatar-deleted:not(.mc-avatar-has-image) { // Because deleted avatars with an image are partially transparent we don't want to change the bg color since it would affect the look of the transparent image
    .mc-avatar-container {
      background: mat.get-color-from-palette($background, avatar-deleted);
      color: mat.get-color-from-palette($foreground, avatar-deleted);
    }
  }

  .mc-avatar-deleted {
    .mc-avatar-image {
      opacity: .5;
    }
  }
}
