$list-item-vertical-spacing: $spacing-small !default;
$list-item-horizontal-spacing: $spacing-normal !default;
$list-item-dense-vertical-spacing: $spacing-xx-small !default;
$list-item-height: 4.8rem !default;
$list-item-content-height: ($list-item-height - $list-item-vertical-spacing * 2);

$list-item-vertical-spacing-compact: $spacing-xx-small !default;
$list-item-horizontal-spacing-compact: $spacing-normal !default;
$list-item-height-compact: 3.2rem !default;
$list-item-content-height-compact: ($list-item-height-compact - $list-item-vertical-spacing-compact * 2);

$list-item-avatars-content-min-height: $size-avatar-small-rem;
$list-item-avatars-vertical-spacing: ($list-item-height - $list-item-avatars-content-min-height) * 0.5 !default;

$list-item-2-lines-height: 6.4rem !default;

$list-item-icon-font-size: 2.4rem !default;
$list-item-details-font-size: $font-size-small !default;

$avatar-list-item-spacing: $spacing-xxx-small !default;
