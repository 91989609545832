/**
 * mc-select-trigger-dynamic-height
 * Makes the height of a .mat-select-trigger element in a <mat-select> element grow to fit its content.
 * This solution uses the styling for .mat-select-trigger from an older version of Material.
 * Example:
 * <mat-select class="mc-select-trigger-dynamic-height">
 */
.mc-select-trigger-dynamic-height {
  .mat-select-trigger {
    display: inline-table;

    .mat-select-value,
    .mat-select-arrow-wrapper {
      display: table-cell;
    }

    .mat-select-arrow-wrapper {
      vertical-align: middle;
    }
  }
}

/**
 * Multiline options in mat-select
 */
.mat-select-panel {
  .mc-multiline-option.mat-option {
    height: auto;
    line-height: $line-height-normal;
    padding-bottom: $list-item-vertical-spacing;
    padding-top: $list-item-vertical-spacing;

    .mat-option-text {
      @include mc-break-text;
      display: inline-block;
      white-space: normal;
    }
  }
}
