@mixin mc-arrow-tabs-nub ($arrow-tab-nub-size: $arrow-tab-nub-size) {

  .mat-tab-label,
  .mat-tab-link {
    &.mat-tab-label-active:after {
      content: '';
      bottom: (-$arrow-tab-nub-size * 0.5);
      height: $arrow-tab-nub-size;
      width: $arrow-tab-nub-size;
    }
  }
}