@use '@angular/material' as mat;

@mixin mc-boxes-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  /*
   * background-color
   */
  .mc-bg-none {
    background: none;
  }

  .mc-bg-avatar {
    background-color: mat.get-color-from-palette($background, avatar) !important;
  }

  .mc-bg-card {
    background-color: mat.get-color-from-palette($background, card) !important;
  }

  .mc-bg-component {
    background-color: mat.get-color-from-palette($background, component) !important;
  }

  .mc-bg-component-board {
    background-color: mat.get-color-from-palette($background, component-board) !important;
  }

  .mc-bg-invert {
    background-color: mat.get-color-from-palette($background, background-invert) !important;
  }

  .mc-bg-toolbar {
    background-color: mat.get-color-from-palette($background, toolbar) !important;
  }

  .mc-bg-active-button,
  .mc-bg-active-button-focus:focus,
  .mc-bg-active-button-hover:hover {
    background-color: mat.get-color-from-palette($background, button-active) !important;
  }

  /*
   * border-color
   */
  .mc-border-divider {
    border-color: mat.get-color-from-palette($foreground, divider) !important;
  }

  .mc-border-text-invert {
    border-color: mat.get-color-from-palette($foreground, text-invert) !important;
  }

  .mc-border-toolbar {
    border-color: mat.get-color-from-palette($background, toolbar) !important;
  }

  @include _mc-box($theme, 'primary');
  @include _mc-box($theme, 'accent');
  @include _mc-box($theme, 'warn');
  @include _mc-box($theme, 'brand');
  @include _mc-box($theme, 'secondary');
  @include _mc-box($theme, 'secondary-invert');
  @include _mc-box($theme, 'notice');
  @include _mc-box($theme, 'caution');
  @include _mc-box($theme, 'checklist-completed');
  @include _mc-box($theme, 'checklist-in-progress');
  @include _mc-box($theme, 'checklist-todo');
  @include _mc-box($theme, 'checklist-not-started');
  @include _mc-box($theme, 'info');
  @include _mc-box($theme, 'info-2');
  @include _mc-box($theme, 'link');
  @include _mc-box($theme, 'basic');
  @include _mc-box($theme, 'build-cancel');
  @include _mc-box($theme, 'build-fail');
  @include _mc-box($theme, 'build-success');
  @include _mc-box($theme, 'build-unknown');
  @include _mc-box($theme, 'mad-translations');
  @include _mc-box($theme, 'report-broken-bookmarks');
  @include _mc-box($theme, 'report-broken-links');
  @include _mc-box($theme, 'report-broken-snippet-links');
  @include _mc-box($theme, 'report-undefined-condition-tags');
  @include _mc-box($theme, 'report-undefined-file-tags');
  @include _mc-box($theme, 'report-undefined-glossary-terms');
  @include _mc-box($theme, 'report-undefined-variables');
  @include _mc-box($theme, 'report-images');
  @include _mc-box($theme, 'report-segments');
  @include _mc-box($theme, 'report-snippets');
  @include _mc-box($theme, 'report-topics');
  @include _mc-box($theme, 'report-words');
  @include _mc-box($theme, 'task-analytics-complete');
  @include _mc-box($theme, 'task-low-priority');
  @include _mc-box($theme, 'task-medium-priority');
  @include _mc-box($theme, 'task-high-priority');
  @include _mc-box($theme, 'usage-storage');
  @include _mc-box($theme, 'usage-source-files');
  @include _mc-box($theme, 'usage-builds');
  @include _mc-box($theme, 'usage-tasks');
  @include _mc-box($theme, 'usage-misc');
  @include _mc-box($theme, 'usage-authors');
  @include _mc-box($theme, 'usage-smes');
}

@mixin _mc-box ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  /*
   * background-color
   */
  .mc-bg-#{$palette-name} {
    background-color: mat.get-color-from-palette($palette, default) !important;
    color: mat.get-color-from-palette($palette, default-contrast) !important;
  }

  .mc-bg-#{$palette-name}-muted {
    background-color: mat.get-color-from-palette($palette, lighter) !important;
    color: mat.get-color-from-palette($palette, lighter-contrast) !important;
  }

  .mc-bg-#{$palette-name}-vivid {
    background-color: mat.get-color-from-palette($palette, darker) !important;
    color: mat.get-color-from-palette($palette, darker-contrast) !important;
  }

  /*
   * border-color
   */
  .mc-border-#{$palette-name} {
    border-color: mat.get-color-from-palette($palette, default) !important;
  }

  .mc-border-#{$palette-name}-muted {
    border-color: mat.get-color-from-palette($palette, lighter) !important;
  }

  .mc-border-#{$palette-name}-vivid {
    border-color: mat.get-color-from-palette($palette, darker) !important;
  }

  /*
   * fill
   */
  .mc-fill-#{$palette-name} {
    fill: mat.get-color-from-palette($palette, default) !important;
  }

  .mc-fill-#{$palette-name}-muted {
    fill: mat.get-color-from-palette($palette, lighter) !important;
  }

  .mc-fill-#{$palette-name}-vivid {
    fill: mat.get-color-from-palette($palette, darker) !important;
  }
}
