@media (max-width: $no-app-nav-max-breakpoint) {
  .page {
    margin-left: $spacing-none;
  }
}

@media (max-width: $page-no-padding-breakpoint) {
  .page {
    padding-left: $spacing-none;
    padding-right: $spacing-none;
    padding-bottom: $spacing-none;
  }

  .page-card {
    border-radius: $border-radius-none;
    box-shadow: none;
  }
}
